/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import { formatDate } from "../../common/DateFormatter";
import {
  deleteProduct,
  getProducts,
  toggleProductActivation,
} from "../../../store/actions/product";
import { deleteNearBy, getNearByList } from "../../../store/actions/nearBy";
import { URL } from "../../../constants/URL";
import { toggleContentGating } from "../../../store/actions/learningResource";
import { CheckBox } from "@mui/icons-material";

const CustomDataGrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

const NearByTable = ({ data, selectedRows, setSelectedRows }) => {
  // const { nearBy } = useSelector((state) => state.nearBy);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleSelectionChange = (selectionModel) => {
  //   // Store the IDs of selected rows
  //   setSelectedRows(selectionModel);
  // };

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const rows = data?.map((item) => {
    const fullname =
      item?.fullname?.EN || item?.fullname?.LU || item?.fullname?.RY;
    return {
      id: item?.id,
      fullname: fullname,
      updatedat:
        formatDate(item?.createdAt).date +
        " | " +
        formatDate(item?.createdAt).time +
        " by " +
        (item?.updatedBy && item?.updatedBy?.fullname
          ? item?.updatedBy?.fullname
          : "Admin"),
    };
  });

  const columns = [
    {
      field: "fullname",
      headerName: "FullName",
      flex: 3,
    },
    //   {
    //     field: "updatedat",
    //     headerName: "Last Updated",
    //     flex: 1,
    //   },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );

        const [open, setOpen] = useState(false);

        //Will Test this later//

        const HandleGate = (params) => {
          const nearby = data?.find((obj) => obj.id === params.id);
          dispatch(toggleContentGating(URL.NEARBYHELP, nearby))
            .then(() => {
              dispatch(getNearByList(URL.NEARBYHELP));
              if (nearby.isAuthRequired) {
                toast.success("Successfully ungated!");
              } else {
                toast.success("Successfully gated!");
              }
            })
            .catch(() => {
              toast.error("Failed to gate/ungate nearby");
            });
        };

        const handleDelete = (params) => {
          dispatch(deleteNearBy(URL.NEARBYHELP, params.id))
            .then(() => {
              // Refresh the article list
              dispatch(getNearByList(URL.NEARBYHELP)).then(() => {
                toast.success("Successfully deleted!");
              });
            })
            .catch((error) => {
              toast.error("Failed to delete NearBy!");
            });
        };

        const nearBy = data?.find((obj) => obj.id === params.id);

        let gated;
        if (nearBy && nearBy.isAuthRequired === true) {
          gated = (
            <Tooltip title="Ungate">
              <LockPersonOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => HandleGate(params)}
              />
            </Tooltip>
          );
        } else {
          gated = (
            <Tooltip title="Gate">
              <LockOpenOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => HandleGate(params)}
              />
            </Tooltip>
          );
        }

        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => navigate(`/nearby/${params.id}/edit`)}
              />
            </Tooltip>
            {gated || null}

            {/* {isActivate ? activation : null} */}
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => setOpen(true)}
              />
            </Tooltip>
            <DeleteConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onDelete={() => handleDelete(params)}
            />
          </>
        );
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 100,
      // renderCell: (params) => {
      //   console.log("params:", params);
      //   // Your action cell rendering logic...
      // },
    },
    // {
    //   field: "selection",
    //   headerName: (
    //     <input
    //       type="checkbox"
    //       onChange={handleSelectAllRows}
    //       checked={selectedRows.length === data.length}
    //       style={{ transform: "scale(1.5)" }}
    //     />
    //   ),
    //   width: 100,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <input
    //         type="checkbox"
    //         onChange={(event) => handleRowCheckboxChange(event, params.id)}
    //         checked={selectedRows.includes(params.id)}
    //         style={{ transform: "scale(1.5)" }}
    //       />
    //     );
    //   },
    // },
  ];

  const rows2 = data?.map((item) => {
    const fullname =
      item?.fullname?.EN || item?.fullname?.LU || item?.fullname?.RY;
    return {
      id: item?.id,
      fullname: fullname,
      updatedat: item.updatedAt
        ? formatDate(item?.updatedAt)?.date +
          " | " +
          formatDate(item?.updatedAt)?.time +
          " by " +
          (item?.updatedBy && item?.updatedBy?.fullname
            ? item?.updatedBy?.fullname
            : "Admin")
        : null,
    };
  });

  function CustomNoRowsOverlay() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div>
          <p>No results found!</p>
          <p>Try changing the search criteria and try again…</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{ height: data && data?.length < 5 ? 600 : 1200, width: "100%" }}
      >
        {data || data?.length > 1 ? (
          <CustomDataGrid
            rows={rows2}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            // options={{
            //   showSelectAllCheckbox: true,
            // }}
            checkboxSelection
            checkboxSelectionVisibleOnly={true}
            onSelectionModelChange={handleSelectionChange}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        ) : (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: true,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default NearByTable;
