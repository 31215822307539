import React from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

function WarningCard({
  open,
  popUpMessage,
  handleClosePopup,
  recommendedSize,
}) {
  return (
    <Dialog open={open} onClose={handleClosePopup}>
      <DialogContent>
        {/* Warning Title and Message */}
        <Typography variant="h6" gutterBottom>
          Warning
        </Typography>
        <Typography>{popUpMessage}</Typography>

        {/* Image Size Title and Message */}
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Image Size
        </Typography>
        <Typography>{`For the best viewing experience, please upload an image within the specified size range ${recommendedSize}`}</Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClosePopup} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WarningCard;
