import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import SideList from './sideList';
import { DashboardNavbar } from './dashboard-navbar';
import { useDispatch } from 'react-redux';
import {
  getCategories,
  getEventCategories,
} from '../../store/actions/category';
// import { clearCurrentRescource } from '../../store/actions/currentArticle';
import { getSpecies } from '../../store/actions/species';
import { getScoreData } from '../../store/actions/scoreCard';

export default function Dasboard() {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getSpecies());
    dispatch(getEventCategories());
    dispatch(getCategories());
    dispatch(getScoreData());
    if (id === undefined) {
      // dispatch(clearCurrentRescource());
    }
  }, [dispatch, id]);

  return (
    <Box>
      <CssBaseline />
      <DashboardNavbar onSidebarOpen={() => {}} />
      <SideList />
      <Box
        component="main"
        sx={{ marginTop: '5em', marginLeft: '20em', marginRight: '1em' }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
