import { createSelector } from 'reselect';

// Product selector
export const productTitleSelector = (state) => state.currentProduct.title;
export const productLanguageSelector = (state) => state.currentProduct.language;
export const productAboutSelector = (state) => state.currentProduct.about;
export const productCoverImageSelector = (state) =>
  state.currentProduct.coverImage;
export const productApplicationSelector = (state) =>
  state.currentProduct.application;
export const productDosageSelector = (state) => state.currentProduct.dosage;
export const getAttachments = (state) => state.currentProduct.attachments;

export const localizedProductTitleSelector = createSelector(
  [productTitleSelector, productLanguageSelector],
  (title, language) => {
    return title ? title[language] : null;
  }
);

export const localizedCoverImageSelector = createSelector(
  [productCoverImageSelector, productLanguageSelector],
  (coverImage, language) => {
    return coverImage ? coverImage[language] : null;
  }
);
export const localizedProductAboutSelector = createSelector(
  [productAboutSelector, productLanguageSelector],
  (about, language) => {
    return about ? about[language] : null;
  }
);

export const localizedProductApplicationSelector = createSelector(
  [productApplicationSelector, productLanguageSelector],
  (application, language) => {
    return application ? application[language] : null;
  }
);

export const localizedProductDosageSelector = createSelector(
  [productDosageSelector, productLanguageSelector],
  (dosage, language) => {
    return dosage ? dosage[language] : null;
  }
);

const getSpecies = (state) => state.species.species;

export const getFilteredSpecies = createSelector(
  [getSpecies, productLanguageSelector],
  (species, language) => species.filter((item) => item.title[language])
);

export const getFilteredAttachments = createSelector(
  [getAttachments, productLanguageSelector],
  (attachments, language) => (attachments ? attachments[language] : null)
);
