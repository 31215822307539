import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Card, CardContent, Modal } from "@mui/material";

import CoverImage from "./CoverImage";
import Title from "./Title";
import ArticleTags from "./ArticleTags";
import Content from "./Content";
import {
  languageSelector,
  localizedAuthor,
  localizedContentSelector,
  localizedCoverImage,
  localizedTitle,
} from "../../store/reducers/selector";
import Author from "./Author";
import ArticleRecommendation from "./ArticleRecommendation";
import VideoTags from "./VideoTags";
import Thumbnail from "./Thumbnail";
import VideoRecommendation from "./VideoRecommendation";

const style = {
  width: "375px", // iPhone 6/7/8 width
  height: "600px", // iPhone 6/7/8 height
  backgroundColor: "#f5f5f5", // light gray background
  borderRadius: "40px", // round corners
  boxShadow: "0 10px 20px rgba(0,0,0,0.3)", // subtle drop shadow
  overflow: "hidden", // hide content outside the div
  overflowY: "scroll", // enable vertical scrollbar
  position: "absolute", // use absolute position to center the box
  top: "50%", // position the top edge at the center of the viewport
  left: "50%", // position the left edge at the center of the viewport
  transform: "translate(-50%, -50%)", // move the box up and left by half its width and height
};

const Preview = ({ handleClose, open }) => {
  const article = useSelector((state) => state.article);
  const localizedContent = useSelector(localizedContentSelector);
  const title = useSelector(localizedTitle);
  const authorData = useSelector(localizedAuthor);
  const language = useSelector(languageSelector);
  const coverImage = useSelector(localizedCoverImage);
  const { learningResource } = useSelector((state) => state.learningResource);
  const { score } = useSelector((state) => state.score);
  const resourceType = useSelector((state) => state.resourceType.type);
  const findPointsForResourceType = (resourceType, score) => {
    const matchingObject = score.find((obj) => obj.pointsType === resourceType);

    if (matchingObject) {
      return matchingObject.points;
    }
    switch (resourceType) {
      case "Article":
        return 20;
      case "Video":
        return 30;
      case "Training":
        return 40;
      default:
        return 0;
    }
  };
  const points = findPointsForResourceType(resourceType, score);

  const categories = useSelector((state) => state?.categories?.categories);
  const myArrayFiltered = categories
    .filter((el) => {
      return article.categoryIds.some((f) => {
        return f.id === el.id;
      });
    })
    .map((el) => el.title.EN || el.title.LU);

  const recommended = learningResource.filter((el) => {
    return article.recommendationsIds.some((f) => {
      return f.id === el.id;
    });
  });

  const myAuthorArrayFiltered = authorData?.filter((el) => {
    return article.authorIds.some((f) => {
      return f.id === el.id;
    });
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {article.learningResourceType === "Article" ||
      article.learningResourceType === "Gallery" ? (
        <Box sx={{ ...style }}>
          <CoverImage coverImage={coverImage} />
          <Card
            sx={{ marginTop: "-20px", borderRadius: 5, marginBottom: "-25px" }}
          >
            <CardContent>
              <Title title={title} />
              <ArticleTags
                category={myArrayFiltered}
                readtime={article.readtime}
                score={points}
              />
              <Content content={localizedContent} />
              <Author authors={myAuthorArrayFiltered} language={language} />
            </CardContent>
            <ArticleRecommendation
              type={article.learningResourceType}
              recommended={recommended}
            />
          </Card>
        </Box>
      ) : (
        <Box sx={{ ...style }}>
          <Thumbnail thumbnail={article.thumbnail} />
          <Card sx={{ marginTop: "-20px", borderRadius: 5 }}>
            <CardContent>
              <Title title={title} />
              <VideoTags category={myArrayFiltered} score={points} />
              <Content content={localizedContent} />
              <VideoRecommendation
                type={article.learningResourceType}
                recommended={recommended}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </Modal>
  );
};

export default Preview;
