export const formatDate = (date) => {
  const options = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false, // use 24-hour clock format
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
    new Date(date)
  );
  const [dateStr, timeStr] = formattedDate.split(', ');
  let formattedTime = timeStr.replace(' ', '');
  if (formattedTime.startsWith('24:')) {
    formattedTime = formattedTime.replace('24:', '00:');
  }
  return { date: dateStr.replace(/\//g, '/'), time: formattedTime };
};
