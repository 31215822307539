import { trackPromise } from 'react-promise-tracker';
import api from '../../services/api';
import {
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  EDIT_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  TOGGLE_PRODUCT_ACTIVATION_FAILURE,
  TOGGLE_PRODUCT_ACTIVATION_SUCCESS,
} from './type';

export const getProducts = (queryParams) => async (dispatch) => {
  try {
    const res = await trackPromise(
      api.post('/product-management/list', queryParams)
    );

    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_PRODUCT_FAIL, payload: err.message });
  }
};

export const toggleProductActivation =
  (id = null, resource) =>
    async (dispatch) => {
      const temp =
        resource.status === 'Published'
          ? 'Draft'
          : 'Published' && resource.status !== 'Published'
            ? 'Published'
            : null;
      try {
        const res = await trackPromise(
          api.patch(`/product-management/${id}`, {
            status: temp,
          })
        );
        dispatch({
          type: TOGGLE_PRODUCT_ACTIVATION_SUCCESS,
          payload: res.data.data,
        });
        return Promise.resolve();
      } catch (err) {
        dispatch({
          type: TOGGLE_PRODUCT_ACTIVATION_FAILURE,
          payload: err.message,
        });
        return Promise.reject();
      }
    };

export const deleteProduct = (id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.delete(`/product-management/${id}`));
    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: DELETE_PRODUCT_FAILURE, payload: err.message });
  }
  return Promise.reject();
};

export const createProduct = (record) => {
  const { language, ...event } = record;
  return async (dispatch) => {
    try {
      const res = await trackPromise(api.post('/product-management', event));
      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: res.data.data,
      });

      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: CREATE_PRODUCT_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};

export const editProduct = (id, record) => {
  const { language, ...event } = record;
  return async (dispatch) => {
    try {
      const res = await trackPromise(
        api.patch(`/product-management/${id}`, event)
      );
      dispatch({
        type: EDIT_PRODUCT_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: EDIT_PRODUCT_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};
