import React from 'react';
import { Avatar, Typography } from '@mui/material';
import BlobService from '../../services/blob-service';

const Author = ({ authors , language }) => {
  if (!authors || authors.length === 0) {
    return null;
  }
  return (
    <div>
      <Typography variant="h6">Authors</Typography>
      {authors.map((author, index) => {
        const initials = getInitials(author?.fullname?.[language]);
        return (
          <div key={index} style={{ display: 'flex', marginTop: 10 }}>
            <Avatar
              alt={author?.fullname?.[language]}
              sx={{
                width: 48,
                height: 48,
                boxShadow: '0 0 0 3px rgba(0, 0, 255, 0.3)', // Blue highlight
              }}
              src={BlobService.getImageUri(author.profilePic)}
            >
              {initials}
            </Avatar>
            <div style={{ marginLeft: 10, marginTop: 10 }}>
              {author?.fullname?.[language]}
            </div>
          </div>
        );
      })}
    </div>
  );
};

// Function to extract initials from fullname
const getInitials = (fullname) => {
  const names = fullname ? fullname?.split(' ') : undefined;
  if (names?.length === 1) {
    // If fullname consists of a single word, return the first two characters as initials
    return names[0]?.substring(0, 2)?.toUpperCase();
  }
  return names?.map((name) => name?.charAt(0))?.join('');
};

export default Author;
