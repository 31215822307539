import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "../../common/Modal";
import axios from "axios";

function HandleGateCheckBox() {
  const [checked, setChecked] = useState(false);
  const [labelText, setLabelText] = useState("Gate All");
  const [openModal, setOpenModal] = useState(false);

  const handleChange = async (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    if (isChecked) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleProceed = () => {
    if (labelText.includes("Gate")) {
      setLabelText("Ungate All");
    } else {
      setLabelText("Gate All");
    }
    setOpenModal(false);
    setChecked(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setChecked(false);
  };
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={labelText}
      />
      <Modal
        message={`Selecting this check will ${labelText} records, are you sure you want to proceed"`}
        open={openModal}
        onProceed={handleProceed}
        handleCloseModal={handleCloseModal}
      ></Modal>
    </>
  );
}

export default HandleGateCheckBox;
