import {
  GET_SCORE_CARD_DATA_SUCCESS,
  SET_SCORE_CARD_DATA_FAIL,
  SET_SCORE_CARD_DATA_SUCCESS,
} from '../actions/type';

const initialState = {
  score: [],
  loading: false,
  error: null,
};

function score(state = initialState, action) {
  switch (action.type) {
    case GET_SCORE_CARD_DATA_SUCCESS:
      return {
        ...state,
        score: [...action.payload],
        loading: false,
      };
    case SET_SCORE_CARD_DATA_SUCCESS:
      return { score: [...state.events, action.payload] };
    case SET_SCORE_CARD_DATA_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}

export default score;
