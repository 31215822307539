import React, { useState } from "react";
import { Button } from "@mui/material";
import Preview from "./preview/Preview";
import { useDispatch, useSelector } from "react-redux";
import {
  eventCoverImageSelector,
  eventEndDateSelector,
  eventStartDateSelector,
  getFilteredCategories,
  localizedAddressSelector,
  localizedAudienceSelector,
  localizedEventDetailsSelector,
  localizedTitleSelector,
} from "../../store/reducers/selector";
import { toast } from "react-toastify";
import { validateEvent } from "../validations/validateEvent";
import {
  getEventsData,
  createEvent,
  editEvent,
} from "../../store/actions/event";
import { useNavigate, useParams } from "react-router-dom";
import usePrompt from "../hook/usePrompt";

const EventButtons = ({ dateError }) => {
  const navigate = useNavigate();
  const event = useSelector((state) => state.currentEvent);
  const { id } = useParams();
  const dispatch = useDispatch();
  const eventTitle = useSelector(localizedTitleSelector);

  const [previewOpen, setPreviewOpen] = useState(false);
  const eventCategory = useSelector(getFilteredCategories);
  const eventAud = useSelector(localizedAudienceSelector);
  const eventDetail = useSelector(localizedEventDetailsSelector);
  const eventStart = useSelector(eventStartDateSelector);
  const eventEnd = useSelector(eventEndDateSelector);
  const eventCoverImage = useSelector(eventCoverImageSelector);
  const [isPublished, setIsPublished] = useState(true);
  const eventAddress = useSelector(localizedAddressSelector);
  const eventLang = useSelector((state) => state.currentEvent.eventLanguage);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };
  const handleSaveResource = async (status) => {
    const validationError = validateEvent(
      event,
      eventTitle,
      eventAddress,
      eventCategory,
      eventAud,
      eventDetail,
      eventStart,
      eventEnd,
      dateError,
      eventLang
    );
    if (validationError) {
      toast.error(validationError);
      return;
    }
    event.status = status;

    try {
      if (id) {
        await dispatch(editEvent(id, event)).then(() => {
          dispatch(getEventsData());
        });
        toast.success(`Event edited successfully!`);
      } else {
        await dispatch(createEvent(event)).then(() => {
          dispatch(getEventsData());
        });
        toast.success(`Event successfully ${status.toLowerCase()}!`);
      }
      navigate(`/events`);
    } catch {
      toast.error(`Failed to ${id ? "edit" : "create"} Event!`);
    }
  };

  const handleCreateArticle = () => {
    setIsPublished(false);
    handleSaveResource("Draft");
  };

  const handlePublishArticle = () => {
    setIsPublished(false);
    handleSaveResource("Published");
  };
  const isBlocking = () => {
    return (eventTitle || eventCoverImage || eventAddress) && isPublished;
  };

  usePrompt("Are you sure you want to leave?", isBlocking());

  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
    >
      <Button
        size="small"
        variant="contained"
        onClick={handlePreviewOpen}
        style={{ margin: 10 }}
      >
        Preview
      </Button>
      <Preview open={previewOpen} handleClose={handlePreviewClose} />
      <Button
        size="small"
        variant="contained"
        onClick={handleCreateArticle}
        style={{ margin: 10 }}
      >
        Draft
      </Button>
      <Button
        size="small"
        variant="contained"
        onClick={handlePublishArticle}
        style={{ margin: 10 }}
      >
        Publish
      </Button>
    </div>
  );
};

export default EventButtons;
