import {
  CREATE_ORGANZIOR_FAILURE,
  CREATE_ORGANZIOR_REQUEST,
  CREATE_ORGANZIOR_SUCCESS,
  GET_ORGANZIOR_FAILURE,
  GET_ORGANZIOR_REQUEST,
  GET_ORGANZIOR_SUCCESS,
} from "./type";

import api from "../../services/api";

export const getOrganizor = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ORGANZIOR_REQUEST });
    try {
      const response = await api.get("/event-organizer");
      dispatch({ type: GET_ORGANZIOR_SUCCESS, payload: response.data.data });
    } catch (error) {
      dispatch({ type: GET_ORGANZIOR_FAILURE, payload: error.message });
    }
  };
};

export const createOrganizor = (organizer) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_ORGANZIOR_REQUEST });
    try {
      const response = await api.post("/event-organizer", organizer);
      dispatch({ type: CREATE_ORGANZIOR_SUCCESS, payload: response.data.data });
    } catch (error) {
      dispatch({ type: CREATE_ORGANZIOR_FAILURE, payload: error.message });
    }
  };
};
