import { trackPromise } from 'react-promise-tracker';
import api from '../../services/api';

import {
  CREATE_POLICY_FAIL,
  CREATE_POLICY_SUCCESS,
  GET_POLICY_FAIL,
  GET_POLICY_SUCCESS,
  RESET_CONTENT_AND_LANGUAGE,
  SET_CONTENT_LANGUAGE,
  SET_TINYMCE_CONTENT,
} from './type';

export const getPolicy = (legalsType) => async (dispatch) => {
  try {
    const res = await trackPromise(api.get(`/privacy-policy/${legalsType}`));
    dispatch({
      type: GET_POLICY_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_POLICY_FAIL, payload: err.message });
  }
};

export const AddPolicy = (record) => {
  return async (dispatch) => {
    try {
      const res = await trackPromise(api.post('/privacy-policy', record));
      dispatch({
        type: CREATE_POLICY_SUCCESS,
        payload: res.data.data,
      });

      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: CREATE_POLICY_FAIL,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_CONTENT_LANGUAGE,
    payload: lang,
  });
};

export const setContent = (content) => async (dispatch) => {
  dispatch({
    type: SET_TINYMCE_CONTENT,
    payload: content,
  });
};

export const resetContentAndLanguage = () => {
  return {
    type: RESET_CONTENT_AND_LANGUAGE,
  };
};
