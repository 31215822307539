export const validateResource = (
  article,
  error,
  localizedVideo,
  localizedThumbail,
  localizedContent
) => {
  if (article.title[article.language] === "") {
    return "Title can't be blank";
  }
  if (error) {
    return "Title already exists";
  }
  if (
    (article.learningResourceType === "Article" ||
      article.learningResourceType === "Gallery") &&
    (localizedContent === "" || localizedContent === undefined)
  ) {
    return "Please enter some text in the editor.";
  }
  if (article.readtime === 0 && article.learningResourceType === "Article") {
    return "Reading time is a required field";
  }
  // if (article.points === 0) {
  //   return 'Score is a required field';
  // }
  if (article.categoryIds.length < 1) {
    return "Category is a required field";
  }
  if (article.learningResourceType === "Article" && article.authorIds < 1) {
    return "Select author";
  }
  if (
    (article.learningResourceType === "Video" ||
      article.learningResourceType === "Training") &&
    localizedVideo === ""
  ) {
    return "Video is a required field";
  }
  if (
    (article.learningResourceType === "Video" ||
      article.learningResourceType === "Training") &&
    !localizedThumbail
  ) {
    return "Thumbnail is a required field";
  }
  return null;
};
