import { createSelector } from "reselect";

export const contentSelector = (state) => state.article.content;
export const titleSelector = (state) => state.article.title;
export const videoSelector = (state) => state.article.video;
export const languageSelector = (state) => state.article.language;
export const readTimeSelector = (state) => state.article.readtime;
export const pointsSelector = (state) => state.article.points;
export const thumbnailSelector = (state) => state?.article?.thumbnail;
export const getTicks = (state) => state.article.ticks;
export const coverImageSelector = (state) => state.article.coverImage;
export const authorData = (state) => state?.authorData?.authorData;
export const recommendationSelector = (state) =>
  state.article.recommendationsIds;

const getCategories = (state) => state.categories.categories;

export const localizedTitle = createSelector(
  [titleSelector, languageSelector],
  (title, language) => {
    return title ? title[language] : null;
  }
);
export const localizedCoverImage = createSelector(
  [coverImageSelector, languageSelector],
  (coverImage, language) => {
    return coverImage ? coverImage[language] : null;
  }
);

export const localizedAuthor = createSelector(
  [authorData, languageSelector],
  (authorData, language) =>
    authorData.filter((item) => item?.fullname?.[language])
);
export const getFilterRecommendation = createSelector(
  [recommendationSelector, languageSelector],
  (recommendationsIds, language) =>
    recommendationsIds.filter((item) => item?.title?.[language])
);
export const getFilterCategories = createSelector(
  [getCategories, languageSelector],
  (categories, language) => categories.filter((item) => item?.title?.[language])
);
export const localizedContentSelector = createSelector(
  [contentSelector, languageSelector],
  (content, language) => {
    return content ? content[language] : null;
  }
);

export const localizedVideoSelector = createSelector(
  [videoSelector, languageSelector],
  (video, language) => {
    return video[language];
  }
);

export const localizedThumbnailSelector = createSelector(
  [thumbnailSelector, languageSelector],
  (thumbnail, language) => {
    return thumbnail ? thumbnail[language] : null;
  }
);

export const getLocalizedTicks = createSelector(
  [languageSelector, getTicks],
  (language, ticks) => {
    return ticks.map((tick) => {
      if (!tick) {
        return null;
      }

      return {
        ...tick,
        commonName: tick.commonName ? tick.commonName[language] : null,
        scientificName: tick.scientificName
          ? tick.scientificName[language]
          : null,
      };
    });
  }
);
// event Selectors
export const eventTitleSelector = (state) => state.currentEvent.title;
export const eventCoverImageSelector = (state) => state.currentEvent.coverImage;
export const eventAddressSelector = (state) => state.currentEvent.address;
export const eventDetailSelector = (state) => state.currentEvent.eventDetails;
export const eventAudienceSelector = (state) =>
  state.currentEvent.targetAudience;
export const eventlanguageSelector = (state) => state.currentEvent.language;
export const eventStartDateSelector = (state) =>
  state.currentEvent.eventStartDateTime;
export const eventEndDateSelector = (state) =>
  state.currentEvent.eventEndDateTime;

export const localizedTitleSelector = createSelector(
  [eventTitleSelector, eventlanguageSelector],
  (title, language) => {
    return title ? title[language] : null;
  }
);

export const localizedCoverImageSelector = createSelector(
  [eventCoverImageSelector, eventlanguageSelector],
  (coverImage, language) => {
    return coverImage ? coverImage[language] : null;
  }
);
export const localizedAddressSelector = createSelector(
  [eventAddressSelector, eventlanguageSelector],
  (address, language) => {
    return address ? address[language] : null;
  }
);

export const localizedEventDetailsSelector = createSelector(
  [eventDetailSelector, eventlanguageSelector],
  (eventDetails, language) => {
    return eventDetails ? eventDetails[language] : null;
  }
);

export const localizedAudienceSelector = createSelector(
  [eventAudienceSelector, eventlanguageSelector],
  (targetAudience, language) => {
    return targetAudience ? targetAudience[language] : null;
  }
);

// Categories
const getEventCategories = (state) => state.categories.eventCategories;

export const getFilteredCategories = createSelector(
  [getEventCategories, eventlanguageSelector],
  (eventCategories, language) =>
    eventCategories.filter((item) => item.title[language])
);

//organizor
export const getEventOrganizor = (state) => state.organizorData.organizorData;
export const getFilteredOrganizor = createSelector(
  [getEventOrganizor, eventlanguageSelector],
  (organizorData, language) =>
    organizorData.filter((item) => item.fullname[language])
);

// filter
export const getAdmin = (state) => state.admin.admins;

//NearBy
export const nearByNameSelector = (state) => state.nearBy.fullname;
export const nearByAddressSelector = (state) => state.nearBy.addressLine1;
export const nearByPhoneSelector = (state) => state.nearBy.phone;
export const nearByDistrictSelector = (state) => state.nearBy.district;
export const nearByInchargeSelector = (state) => state.nearBy.fullTimeIncharge;
export const nearByLanguageSelector = (state) => state.nearBy.language;

//HelpingForm
export const formNameSelector = (state) => state.formData.fullName;
export const formReasonSelector = (state) => state.formData.reason;
export const formEmailAddressSelector = (state) => state.formData.emailAddress;
export const formErrorMessageSelector = (state) => state.formData.errorMessage;

//Delete Account Request
export const DeleteAccountErrorMessageSelector = (state) =>
  state.deleteAccount.errorMessage;

export const localizedNearByNameSelector = createSelector(
  [nearByNameSelector, nearByLanguageSelector],
  (fullname, language) => {
    return fullname ? fullname[language] : null;
  }
);

export const localizedNearByAddressSelector = createSelector(
  [nearByAddressSelector, nearByLanguageSelector],
  (address, language) => {
    return address ? address[language] : null;
  }
);

export const localizedNearByPhoneSelector = createSelector(
  [nearByPhoneSelector],
  (phone) => {
    return phone ? phone : "";
  }
);

export const localizedNearByDistrictSelector = createSelector(
  [nearByDistrictSelector, nearByLanguageSelector],
  (district, language) => {
    return district ? district[language] : null;
  }
);

export const localizedNearByInchargeSelector = createSelector(
  [nearByInchargeSelector, nearByLanguageSelector],
  (incharge, language) => {
    return incharge ? incharge[language] : null;
  }
);

export const localizedFormEmailSelector = createSelector(
  [formEmailAddressSelector],
  (emailAddress) => {
    return emailAddress ? emailAddress : "";
  }
);

export const localizedFormNameSelector = createSelector(
  [formNameSelector],
  (fullname) => {
    return fullname ? fullname : "";
  }
);

export const localizedFormReasonSelector = createSelector(
  [formReasonSelector],
  (reason) => {
    return reason ? reason : "";
  }
);

export const localizedFormErrorMessageSelector = createSelector(
  [formErrorMessageSelector],
  (errorMessage) => {
    return errorMessage ? errorMessage : "";
  }
);

export const localizedDeleteAccountErrorMessageSelector = createSelector(
  [DeleteAccountErrorMessageSelector],
  (errorMessage) => {
    return errorMessage ? errorMessage : "";
  }
);
