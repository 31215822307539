import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

export default function FilteredUpdatedSelector({
  admin,
  filteredData,
  setFilteredData,
}) {
  const updatedBy = filteredData.updatedByIds || [];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedIds = value.map(
      (selectedValue) =>
        admin.find((person) => person.fullname === selectedValue).id
    );

    setFilteredData((prevData) => ({
      ...prevData,
      updatedByIds: selectedIds,
    }));
  };

  return (
    <div style={{ margin: '1em' }}>
      <Typography variant="h6" style={{ marginBottom: '7px' }}>
        Updated By
      </Typography>

      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-checkbox-label">Updated By</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={updatedBy.map(
            (selectedId) =>
              admin.find((person) => person.id === selectedId).fullname
          )}
          onChange={handleChange}
          label="Updated By"
          renderValue={(selected) => selected.join(', ')}
        >
          {admin.map((person) => (
            <MenuItem key={person.id} value={person.fullname}>
              <Checkbox checked={updatedBy.includes(person.id)} />
              <ListItemText primary={person.fullname} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
