import { localizedObject } from "../../component/common/GeneralHelper";
import api from "../../services/api";
import {
  CREATE_PRODUCT_SPECIES_FAILURE,
  CREATE_PRODUCT_SPECIES_SUCCESS,
  GET_PRODUCT_SPECIES_FAILURE,
  GET_PRODUCT_SPECIES_SUCCESS,
} from "./type";

export const getSpecies = () => async (dispatch) => {
  try {
    const response = await api.get("/species");
    dispatch({
      type: GET_PRODUCT_SPECIES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_PRODUCT_SPECIES_FAILURE, payload: error.message });
  }
};

export const createSpecies = (title, lang) => async (dispatch) => {
  const titleData = localizedObject(lang, title);

  try {
    const response = await api.post("/species", {
      title: titleData,
    });
    dispatch({
      type: CREATE_PRODUCT_SPECIES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: CREATE_PRODUCT_SPECIES_FAILURE, payload: error.message });
  }
};
