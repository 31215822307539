import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, IconButton, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { setVedioContent } from "../../store/actions/currentArticle";
import BlobService from "../../services/blob-service";
import Thumbnail from "./Thumbnail";
import SupportingContent from "./SupportingContent";

const VideoUpload = ({ requiredAspectRatio, TOLERANCE, recommendedSize }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.article.language);
  const selectedVideo = useSelector((state) => state.article.video[lang]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "video/mp4" && file.name.endsWith(".mp4")) {
        const filename = BlobService.generateFileName(file);
        setLoading(true);
        BlobService.uploadVideo(filename, file, setProgress).then(() => {
          setError(null);
          dispatch(setVedioContent(filename));
          setLoading(false);
        });
      } else {
        setError("Please select a valid MP4 file.");
      }
    }
  };

  const deleteFile = () => {
    // BlobService.deleteVideo(selectedVideo).then(() => {
    dispatch(setVedioContent(""));
    // });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.type === "video/mp4" && file.name.endsWith(".mp4")) {
        const filename = BlobService.generateFileName(file);
        setLoading(true);
        BlobService.uploadVideo(filename, file, setProgress).then(() => {
          setError(null);
          dispatch(setVedioContent(filename));
          setLoading(false);
        });
      } else {
        setError("Please select a valid MP4 file.");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", height: 100, border: "1px solid grey" }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", padding: "16px" }}
          >
            Uploading
          </Typography>
          <Typography variant="body1" sx={{ marginLeft: "1em" }}>
            {progress}%
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              width: "calc(100% - 32px)",
              margin: "0 auto",
              marginTop: "8px",
            }}
          />
        </Box>
      ) : selectedVideo ? (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            sx={{ border: 1, borderColor: "grey.500" }}
          >
            {selectedVideo && (
              <Badge badgeContent="X" color="secondary" onClick={deleteFile}>
                <video
                  controls
                  src={BlobService.getVideoUri(selectedVideo)}
                  style={{ height: "15em" }}
                />
              </Badge>
            )}
          </Box>
          <Thumbnail
            requiredAspectRatio={requiredAspectRatio}
            TOLERANCE={TOLERANCE}
            recommendedSize={recommendedSize}
          />
          <SupportingContent />
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            sx={{ border: 1, borderColor: "grey.500" }}
          >
            <Typography style={{ marginTop: 3 }}>Max File TBD</Typography>
            <IconButton
              color="primary"
              aria-label="upload vedio"
              component="label"
            >
              <input
                hidden
                accept="video/mp4"
                type="file"
                onChange={handleFileSelect}
              />
              <FileUploadIcon style={{ fontSize: "4em", color: "#D9D9D9" }} />
            </IconButton>
            <Box
              display="flex"
              marginTop="1rem"
              marginBottom="1rem"
              padding="3rem"
              border="dashed 1px #ccc"
              alignItems={"center"}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <Typography>
                drag and drop the video here or upload manually. <br />
              </Typography>
            </Box>
            {error && <Box color="red">{error}</Box>}
          </Box>
        </>
      )}
    </>
  );
};

export default VideoUpload;
