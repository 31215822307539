// external import
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { trackPromise } from 'react-promise-tracker';

// internal import
import FormValidation from '../common/FormValidations';
import { login } from '../../store/actions/auth';
import Spinner from '../layout/Spinner';
import { clearMessage } from '../../store/actions/message';

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const handleForgetPasswordClick = () => {
    dispatch(clearMessage());
    navigate('/forget');
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: FormValidation.LoginSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      trackPromise(dispatch(login(values.email, values.password)))
        .then(() => {
          navigate('/article');
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return (
    <div>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%',
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 1, mt: 5 }}>
              <>
                <Typography color="textPrimary" variant="h4">
                  Sign in
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Welcome to Elanco! Give your account details below to log in…
                </Typography>
              </>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            {/* <FormControlLabel
                control={<Checkbox name='checkedB' color='primary' />}
                label='Remember me'
              /> */}
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loading && (
                  <span>
                    <CircularProgress />
                  </span>
                )}
                <span>Login</span>
              </Button>

              {message && (
                <div className="form-group" style={{ paddingTop: '1em' }}>
                  <Alert severity="error">{message}</Alert>
                </div>
              )}

              <Typography sx={{ mt: 2 }}>
                <Link href="#" onClick={handleForgetPasswordClick}>
                  Forgot password ?
                </Link>
              </Typography>
            </Box>
          </form>
        </Container>
      </Box>
      <Spinner />
    </div>
  );
}

export default Login;
