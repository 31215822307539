import React from "react";
import { TextField } from "@mui/material";
import { WithHeader } from "../common/WithHeader";

const TextFields = (props) => {
  const { handleTextField, defaultValue, placeholder } = props;

  return (
    <div>
      <TextField
        placeholder={placeholder}
        multiline
        rows={2}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderRadius: 0, // set borderRadius to 0
          },
        }}
        onChange={(event) => {
          handleTextField(event); // Pass the new value to the handler
        }}
        value={defaultValue}
        variant="outlined"
      />
    </div>
  );
};

export default WithHeader(TextFields, "title");
