import {
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  EDIT_EVENT_SUCCESS,
  GET_EVENTS_DATA_FAIL,
  GET_EVENTS_DATA_SUCCESS,
  TOGGLE_EVENT_ACTIVATION_FAILURE,
  TOGGLE_EVENT_ACTIVATION_SUCCESS,
} from '../actions/type';

const initialState = {
  events: [],
  loading: false,
  error: null,
};

function eventReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS_DATA_SUCCESS:
      return {
        ...state,
        events: [...action.payload],
        loading: false,
      };
    case GET_EVENTS_DATA_FAIL:
      return { ...state, error: action.payload, loading: false };
    case TOGGLE_EVENT_ACTIVATION_SUCCESS:
      return {
        ...state,
        events: state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        ),
      };
    case TOGGLE_EVENT_ACTIVATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_EVENT_SUCCESS:
      const updatedEvent = state.events.filter(
        (resource) => resource.id !== action.payload
      );
      return {
        ...state,
        events: updatedEvent,
        loading: false,
        error: null,
      };
    case DELETE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_EVENT_SUCCESS:
      return { events: [...state.events, action.payload] };
    case CREATE_EVENT_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case EDIT_EVENT_SUCCESS:
      return { events: [...state.events, action.payload] };
    case EDIT_EVENT_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}

export default eventReducer;
