export const BULK_KEYS = {
  FULL_NAME_EN: "fullName_EN",
  FULL_NAME_LU: "fullName_LU",
  FULL_NAME_RY: "fullName_RY",
  ADDRESS_EN: "address_EN",
  ADDRESS_LU: "address_LU",
  ADDRESS_RY: "address_RY",
  FULL_TIME_INCHARGE_EN: "fullTimeIncharge_EN",
  FULL_TIME_INCHARGE_LU: "fullTimeIncharge_LU",
  FULL_TIME_INCHARGE_RY: "fullTimeIncharge_RY",
  DISTRICT_EN: "district_EN",
  DISTRICT_LU: "district_LU",
  DISTRICT_RY: "district_RY",
};
