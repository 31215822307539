import React, { useEffect } from "react";
import ProductHeader from "./ProductHeader";
import CoverImage from "../../common/CoverImage";
import About from "./About";
import {
  clearCurrentProduct,
  retrieveProductbyId,
  setCoverImage,
  setHtmlImage,
} from "../../../store/actions/currentProduct";
import { useDispatch, useSelector } from "react-redux";
import Application from "./Application";
import Dosage from "./Dosage";
import Species from "./Species";
import { useParams } from "react-router-dom";
import MultiAttachment from "./MultiAttachment";
import ProductButton from "./ProductButton";

const recommendedSizeMessage =
  "Best results with a 16:9 image, like 640x360 or 960x540.";

const Product = () => {
  const dispatch = useDispatch();
  const languageSelector = useSelector(
    (state) => state.currentProduct.language
  );
  const selectedCoverImage = useSelector(
    (state) => state.currentProduct.coverImage[languageSelector]
  );
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(retrieveProductbyId(id));
    } else {
      dispatch(clearCurrentProduct());
    }
  }, [dispatch, id]);

  const requiredAspectRatio = 16 / 9;

  return (
    <>
      <ProductHeader />
      <CoverImage
        setCoverImage={setCoverImage}
        selectedCoverImage={selectedCoverImage}
        recommendedSize={recommendedSizeMessage}
        requiredAspectRatio={requiredAspectRatio}
      />
      <Species />
      <About setHtmlImage={setHtmlImage} />
      <Application setHtmlImage={setHtmlImage} />
      <Dosage setHtmlImage={setHtmlImage} />
      <MultiAttachment />
      <ProductButton />
    </>
  );
};

export default Product;
