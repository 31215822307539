export const validateForm = (emailAddress, fullName, reason) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!fullName) {
    return "Fullname can't be blank";
  }
  if (!emailAddress) {
    return "Email can't be blank";
  }
  if (!emailRegex.test(emailAddress)) {
    return "Email format is not correct";
  }

  // Test the email against the regular expression
  if (!reason) {
    return "Reason can't be blank";
  }
  return null;
};
