/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

import "../../App.css";
import {
  deleteRecord,
  toggleContentActivation,
  toggleContentGating,
  deleteLearningResource,
} from "../../store/actions/learningResource";
import { formatDate } from "./DateFormatter";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { URL } from "../../constants/URL";

const CustomDataGrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

const LearningResourceTable = (props) => {
  const {
    data,
    resourceType,
    isGating,
    deleteURL,
    listService,
    listURL,
    isActivate,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { learningResource } = useSelector((state) => state.learningResource);
  const currentResourceType = useSelector((state) => state.resourceType);
  const resource = learningResource.filter(
    (temp) => temp.learningResourceType === resourceType
  );

  const columns = [
    { field: "title", headerName: "Title", width: 250 },
    {
      field: "categories",
      headerName: "Category",
      flex: 1,
    },
    { field: "status", headerName: "Status", flex: 0.6 },
    {
      field: "updatedat",
      headerName: "Last Updated",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );

        const [open, setOpen] = useState(false);

        const handleDelete = (params) => {
          dispatch(deleteRecord(params.id))
            .then(() => {
              // Refresh the article list
              dispatch(listService(listURL, currentResourceType)).then(() => {
                toast.success("Successfully deleted!");
              });
            })
            .catch((error) => {
              toast.error("Failed to delete resource!");
            });
        };
        const HandleGate = (params) => {
          const res = resource.find((obj) => obj.id === params.id);
          dispatch(toggleContentGating(URL.RESOURCE, res))
            .then(() => {
              if (res.isAuthRequired) {
                toast.success("Successfully ungated!");
              } else {
                toast.success("Successfully gated!");
              }
            })
            .catch(() => {
              toast.error("Failed to gate/ungate resource!");
            });
        };

        const HandleActivation = (params) => {
          const res = resource.find((obj) => {
            return obj.id === params.id;
          });
          dispatch(toggleContentActivation(params.id, res))
            .then(() => {
              if (res.status === "Published") {
                toast.success("Successfully deactivated!");
              } else {
                toast.success("Successfully activated!");
              }
            })
            .catch(() => {
              toast.error("Failed to gate/ungate resource!");
            });
        };

        const article = resource.find((obj) => obj.id === params.id);

        let gated;
        if (article && article.isAuthRequired === true) {
          gated = (
            <Tooltip title="Ungate">
              <LockPersonOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => HandleGate(params)}
              />
            </Tooltip>
          );
        } else {
          gated = (
            <Tooltip title="Gate">
              <LockOpenOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => HandleGate(params)}
              />
            </Tooltip>
          );
        }

        let activation;
        if (article && article.status === "Published") {
          activation = (
            <Tooltip title="deactivate">
              <VisibilityOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        } else {
          activation = (
            <Tooltip title="activate">
              <VisibilityOffOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        }

        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() =>
                  navigate(`/${resourceType.toLowerCase()}/${params.id}/edit`)
                }
              />
            </Tooltip>
            {isGating ? gated : null}

            {isActivate ? activation : null}
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => setOpen(true)}
              />
            </Tooltip>
            <DeleteConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onDelete={() => handleDelete(params)}
            />
          </>
        );
      },
    },
  ];

  const rows = resource.map((item) => {
    let title = "";
    if (resourceType === "Tick") {
      title = item?.title;
    } else {
      title = item?.title?.EN || item?.title?.LU || item?.title?.RY;
    }

    return {
      id: item.id,
      title: title?.substring(0, 25) + (title?.length > 25 ? "..." : ""),
      categories: item?.categories
        ?.map(
          (category) =>
            category?.title?.EN || category?.title?.LU || category?.title?.RY
        )
        .join(","),
      status: item.status,
      updatedat: item.updatedAt
        ? `${formatDate(item.updatedAt).date} | ${
            formatDate(item.updatedAt).time
          } by ${
            item.updatedBy && item.updatedBy.fullname
              ? item.updatedBy.fullname
              : "Admin"
          }`
        : `${formatDate(item.createdAt).date} | ${
            formatDate(item.createdAt).time
          } by ${
            item.updatedBy && item.updatedBy.fullname
              ? item.updatedBy.fullname
              : "Admin"
          }`,
    };
  });

  const rows2 = data.map((item) => {
    let title = "";
    if (resourceType === "Tick") {
      title = item?.title;
    } else {
      title = item?.title?.EN || item?.title?.LU || item?.title?.RY;
    }

    return {
      id: item.id,
      title: title?.substring(0, 25) + (title?.length > 25 ? "..." : ""),
      categories: item.categories
        ?.map(
          (category) =>
            category?.title?.LU || category?.title?.EN || category?.title?.RY
        )
        .join(","),
      status: item.status,
      updatedat: item.updatedAt
        ? `${formatDate(item.updatedAt).date} | ${
            formatDate(item.updatedAt).time
          } by ${
            item.updatedBy && item.updatedBy.fullname
              ? item.updatedBy.fullname
              : "Admin"
          }`
        : `${formatDate(item.createdAt).date} | ${
            formatDate(item.createdAt).time
          } by ${
            item.updatedBy && item.updatedBy.fullname
              ? item.updatedBy.fullname
              : "Admin"
          }`,
    };
  });

  function CustomNoRowsOverlay() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div>
          <p>No results found!</p>
          <p>Try changing the search criteria and try again…</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ height: data.length < 5 ? 600 : 1200, width: "100%" }}>
        {data || data?.length > 1 ? (
          <CustomDataGrid
            rows={rows2}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            checkboxSelectionVisibleOnly={false}
            pagination
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
                pagination: { paginationModel: { pageSize: 20 } },
              },
            }}
          />
        ) : (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default LearningResourceTable;
