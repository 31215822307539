import React from "react";
import { WithHeader } from "../../../common/WithHeader";
// import { localizedAddressSelector } from '../../store/reducers/selector';
import { useDispatch, useSelector } from "react-redux";
import TextFields from "../../../common/TextFields";
import { setPhone } from "../../../../store/actions/nearByActions";
import { NEAR_BY_FIELDS } from "../../../../constants/GeneralConstant";
import { localizedNearByPhoneSelector } from "../../../../store/reducers/selector";

const Phone = () => {
  const dispatch = useDispatch();

  const phone = useSelector(localizedNearByPhoneSelector);

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numeric characters
    event.target.value = numericValue;
    dispatch(setPhone(event.target.value));
  };

  return (
    <div>
      <TextFields
        placeholder={"Enter phone of nearby here"}
        defaultValue={phone}
        name={"nearby"}
        handleTextField={handlePhoneNumberChange}
        title={NEAR_BY_FIELDS.PHONE}
      ></TextFields>
    </div>
  );
};

export default Phone;
