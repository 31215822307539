import React from 'react';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LanguageIcon from '@mui/icons-material/Language';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Typography } from '@mui/material';
import EventDetailContent from './EventDetailContent';

dayjs.extend(utc);
const EventInfo = ({
  eventAddress,
  eventAud,
  eventDetail,
  eventStartTime,
  eventEndTime,
  organizor,
  currentEventLanguage,
}) => {
  let startTime;
  let formattedStartDate;
  let sdate;
  let edate;
  let formattedEndDate;
  let endTime;
  let languageValue;

  if (currentEventLanguage === 'EN') {
    languageValue = 'English';
  } else if (currentEventLanguage === 'LU') {
    languageValue = 'Luganda';
  } else {
    languageValue = 'N/A';
  }

  if (eventStartTime !== '') {
    sdate = dayjs
      .utc(eventStartTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'UTC')
      .local();
    formattedStartDate = sdate.locale('en').format('MMMM D, YYYY');
    startTime = sdate.format('h:mm A');
  }

  if (eventEndTime !== '') {
    edate = dayjs.utc(eventEndTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'UTC').local();
    formattedEndDate = edate.locale('en').format('MMMM D, YYYY');
    endTime = edate.format('h:mm A');
  }

  return (
    <>
      <div style={{ marginLeft: '0.5em', marginTop: '0.5em' }}>
        <div
          style={{ display: 'flex', alignItems: 'center', padding: '0.7em' }}
        >
          <MapOutlinedIcon style={{ marginRight: '0.5em' }} />
          {`${eventAddress ? eventAddress : 'N/A'}`} <br />
        </div>
        <div style={{ display: 'flex', padding: '0.7em' }}>
          <Diversity3OutlinedIcon style={{ marginRight: '0.5em' }} />
          {`${eventAud ? eventAud : 'N/A'}`} <br />
        </div>
        <div style={{ display: 'flex', padding: '0.7em' }}>
          <CalendarTodayIcon style={{ marginRight: '0.5em' }} />
          {formattedStartDate ? formattedStartDate : 'N/A'} -{' '}
          {formattedEndDate ? formattedEndDate : 'N/A'}
        </div>
        <div style={{ display: 'flex', padding: '0.7em' }}>
          <AccessTimeIcon style={{ marginRight: '0.5em' }} />
          {startTime ? startTime : 'N/A'} - {endTime ? endTime : 'N/A'}
        </div>
        <div style={{ display: 'flex', padding: '0.7em' }}>
          <LanguageIcon style={{ marginRight: '0.5em' }} />
          {languageValue}
        </div>
      </div>
      <div style={{ marginLeft: '1em', paddingTop: '1em' }}>
        <h4>About this event:</h4>
        <EventDetailContent content={eventDetail} />
      </div>
      <div style={{ marginLeft: '1em', paddingTop: '1em' }}>
        <h4>Organizer</h4>
        <Typography sx={{ padding: '0.3em' }}>
          {' '}
          {`${organizor.fullname ? organizor.fullname : 'N/A'}`}
        </Typography>
        <Typography sx={{ padding: '0.3em' }}>
          {' '}
          {`${organizor.details ? organizor.details : 'N/A'}`}{' '}
        </Typography>
      </div>
    </>
  );
};

export default EventInfo;
