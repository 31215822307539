/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminData } from '../../store/actions/admin';
import { getAdmin } from '../../store/reducers/selector';
import { ListingRecord } from '../../store/actions/learningResource';
import { clearFilter } from '../../store/actions/filter';
import { toast } from 'react-toastify';
import { getEventsData } from '../../store/actions/event';
import { getProducts } from '../../store/actions/product';
import FilterBoxModel from './FilterBoxModel';
import { URL } from '../../constants/URL';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
  borderRadius: '5px',
};
const status = ['Draft', 'Published', 'Archived'];
const activation = ['gated', 'ungated'];

const FilterBox = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const admin = useSelector(getAdmin);
  const [createDateError, setCreateDateError] = useState('');
  const [updateDateError, setUpdateDateError] = useState('');
  const resourceType = useSelector((state) => state.resourceType);
  const [filteredData, setFilteredData] = useState({ type: resourceType.type });

  useEffect(() => {
    if (resourceType) {
      setFilteredData({ type: resourceType.type });
    }
  }, [resourceType]);

  const HandleClearAll = () => {
    setFilteredData({ type: resourceType.type });
    // dispatch(setFilteredQueryParams(''));
    if (filteredData.type === 'Events') {
      dispatch(getEventsData());
    } else if (filteredData.type === 'Products') {
      dispatch(getProducts());
    } else {
      dispatch(ListingRecord(URL.LEARNING_RESOURCE_LISTING, resourceType));
    }
    dispatch(clearFilter());
    handleClose();
  };

  const handleChipClick = (value) => {
    setFilteredData((prevData) => {
      const selectedStatus = prevData.status || [];
      let updatedStatus;
      if (selectedStatus.includes(value)) {
        updatedStatus = selectedStatus.filter((selected) => selected !== value);
      } else {
        updatedStatus = [...selectedStatus, value];
      }
      return {
        ...prevData,
        status: updatedStatus,
      };
    });
  };

  const handleActivationChipClick = (value) => {
    setFilteredData((prevData) => {
      const selectedStatus = prevData.isGated || [];
      let updatedStatus;
      if (selectedStatus.includes(value)) {
        updatedStatus = selectedStatus.filter((selected) => selected !== value);
      } else {
        updatedStatus = [...selectedStatus, value];
      }
      return {
        ...prevData,
        isGated: updatedStatus,
      };
    });
  };

  const handleApply = () => {
    const filteredDataCopy = { ...filteredData };
    if (
      Array.isArray(filteredDataCopy.isGated) &&
      filteredDataCopy.isGated.length === 2
    ) {
      delete filteredDataCopy.isGated; // Remove the isGated key from the object
    } else if (
      Array.isArray(filteredDataCopy.isGated) &&
      filteredDataCopy.isGated.length === 1
    ) {
      if (filteredDataCopy.isGated[0] === 'gated') {
        filteredDataCopy.isGated = true;
      } else if (filteredDataCopy.isGated[0] === 'ungated') {
        filteredDataCopy.isGated = false;
      }
    }

    for (const key in filteredDataCopy) {
      if (
        filteredDataCopy[key] === null ||
        filteredDataCopy[key] === undefined ||
        (Array.isArray(filteredDataCopy[key]) &&
          filteredDataCopy[key].length === 0)
      ) {
        delete filteredDataCopy[key]; // Remove the key from the object
      }
    }

    if (createDateError || updateDateError) {
      const errorMessage = createDateError ? createDateError : updateDateError;
      toast.error(errorMessage);
    } else {
      if (filteredDataCopy.type === 'Events') {
        delete filteredDataCopy.type; // Remove 'filteredType' key from the object
        dispatch(getEventsData(filteredDataCopy)); // Dispatch retrieveEvent action
      } else if (filteredDataCopy.type === 'Products') {
        delete filteredDataCopy.type; // Remove 'filteredType' key from the object
        dispatch(getProducts(filteredDataCopy)); // Dispatch retrieveEvent action
      } else {
        // dispatch(setFilteredQueryParams(filteredDataCopy));
        dispatch(
          ListingRecord(URL.LEARNING_RESOURCE_LISTING, filteredDataCopy)
        );
      }
      handleClose();
    }
  };

  useEffect(() => {
    dispatch(getAdminData());
  }, [1]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FilterBoxModel
            handleClose={handleClose}
            status={status}
            activation={activation}
            handleChipClick={handleChipClick}
            filteredData={filteredData}
            handleActivationChipClick={handleActivationChipClick}
            setFilteredData={setFilteredData}
            setCreateDateError={setCreateDateError}
            setUpdateDateError={setUpdateDateError}
            admin={admin}
            handleApply={handleApply}
            HandleClearAll={HandleClearAll}
          />
        </Box>
      </Modal>
    </>
  );
};

export default FilterBox;
