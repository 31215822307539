import { getFormLanguage } from "../../component/common/GeneralHelper";
import { LANGUAGE_KEY, LANGUAGE_TYPE } from "../../constants/GeneralConstant";
import {
  RETRIEVE_EVENT_SUCCESS,
  SET_ADDRESS,
  SET_EVENT_COVER_IMAGE,
  SET_EVENT_DETAIL,
  SET_EVENT_DETAIL_LANGUAGE,
  SET_EVENT_END_DATE,
  SET_EVENT_LANGUAGE,
  SET_EVENT_START_DATE,
  SET_EVENT_TITLE,
  SET_ORGANIZOR,
  SET_TARGET_AUDIENCE,
  TOGGLE_EVENT_CATEGORY,
} from "../actions/type";

const initialState = {
  language: "EN",
  metaData: { language: "EN" },
  eventLanguage: "",
  status: "Draft",
  isAuthRequired: true,
  title: { EN: "", LU: "", RY: "" },
  address: { EN: "", LU: "", RY: "" },
  categoryIds: [],
  eventDetails: { EN: "", LU: "", RY: "" },
  targetAudience: { EN: "", LU: "", RY: "" },
  coverImage: { EN: "" },
  eventStartDateTime: "",
  eventEndDateTime: "",
  organizerId: {
    id: "",
  },
};

export const currentEventeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_EVENT_CATEGORY:
      let currentCategoryIds = [];
      const selectedCategoryId = action.payload;
      const currentIds = state.categoryIds.flatMap((c) => c.id);
      if (currentIds.includes(selectedCategoryId)) {
        const index = currentIds.indexOf(selectedCategoryId);
        currentCategoryIds = [...state.categoryIds];
        if (index > -1) {
          currentCategoryIds.splice(index, 1);
        }
      } else {
        currentCategoryIds = [...state.categoryIds, { id: selectedCategoryId }];
      }
      return {
        ...state,
        categoryIds: currentCategoryIds,
      };
    case SET_EVENT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        metaData: {
          language: action.payload,
        },
      };
    case SET_EVENT_TITLE:
      return {
        ...state,
        title: {
          ...state.title,
          [state.language]: action.payload,
        },
      };
    case SET_ADDRESS:
      return {
        ...state,
        address: {
          ...state.address,
          [state.language]: action.payload,
        },
      };
    case SET_EVENT_DETAIL:
      return {
        ...state,
        eventDetails: {
          ...state.eventDetails,
          [state.language]: action.payload,
        },
      };
    case SET_ORGANIZOR:
      return {
        ...state,
        organizerId: {
          ...state.organizerId,
          id: action.payload,
        },
      };
    case SET_TARGET_AUDIENCE:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          [state.language]: action.payload,
        },
      };
    case SET_EVENT_COVER_IMAGE:
      return {
        ...state,
        coverImage: {
          ...state.coverImage,
          [state.language]: action.payload,
        },
      };
    case SET_EVENT_START_DATE:
      return {
        ...state,
        eventStartDateTime: action.payload,
      };
    case SET_EVENT_END_DATE:
      return {
        ...state,
        eventEndDateTime: action.payload,
      };
    case SET_EVENT_DETAIL_LANGUAGE:
      return {
        ...state,
        eventLanguage: action.payload,
      };
    case RETRIEVE_EVENT_SUCCESS:
      if (!action.payload) {
        return { ...initialState };
      } else {
        // const lang = action.payload.title[LANGUAGE.ENGLISH]
        //   ? LANGUAGE.ENGLISH
        //   : action?.payload?.metaData?.language;
        const lang = getFormLanguage(action.payload.title);
        const newItems2 = action.payload.categories.map((category) => ({
          id: category.id,
        }));
        const organizor = action.payload.organizer.id;
        const languageCode = (() => {
          switch (action.payload.eventLanguage) {
            case LANGUAGE_TYPE.ENGLISH:
              return LANGUAGE_KEY.ENGLISH;
            case LANGUAGE_TYPE.LUGANDA:
              return LANGUAGE_KEY.LUGANDA;
            case LANGUAGE_TYPE.RUNYAKITARA:
              return LANGUAGE_KEY.RUNYAKITARA;
            default:
              return "";
          }
        })();
        return {
          ...state,
          title: action.payload.title,
          language: lang || LANGUAGE_KEY.ENGLISH,
          categoryIds: newItems2,
          coverImage: action.payload.coverImage,
          status: action.payload.status,
          isAuthRequired: action.payload.isAuthRequired,
          eventStartDateTime: action.payload.eventStartDateTime,
          eventEndDateTime: action.payload.eventEndDateTime,
          address: action.payload.address,
          targetAudience: action.payload.targetAudience,
          eventDetails: action.payload.eventDetails,
          organizerId: { id: organizor },
          eventLanguage: languageCode,
        };
      }
    default:
      return state;
  }
};
