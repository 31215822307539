// external import
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Link,
} from '@mui/material';
import FormValidation from '../common/FormValidations';
import { forgetPassword } from '../../store/actions/auth';
import { clearMessage } from '../../store/actions/message';

function ForgetPassword() {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: FormValidation.ForgetSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      dispatch(forgetPassword(values.email, values.password))
        .then(() => {
          navigate('/code', {
            state: { showToast: true, email: values.email },
          });
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  const handleLoginClick = () => {
    dispatch(clearMessage());
    navigate('/login');
  };

  return (
    <div>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%',
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 1, mt: 5 }}>
              <>
                <Typography color="textPrimary" variant="h4">
                  Forget Password
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Don’t worry! Just give us your email and we’ll send a link to
                  reset your password…
                </Typography>
              </>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />

            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loading && (
                  <span>
                    <CircularProgress size={20} />
                  </span>
                )}
                Submit
              </Button>
              <Typography sx={{ mt: 2 }}>
                <Link href="#" onClick={handleLoginClick}>
                  Back to Login
                </Link>
              </Typography>
              {message && (
                <div className="form-group" style={{ paddingTop: '1em' }}>
                  <Alert severity="error">{message}</Alert>
                </div>
              )}
            </Box>
          </form>
        </Container>
      </Box>
    </div>
  );
}

export default ForgetPassword;
