import React from "react";
import { WithHeader } from "../common/WithHeader";
import { localizedEventDetailsSelector } from "../../store/reducers/selector";
import { setEventDetail } from "../../store/actions/currentEvent";
import Content from "../common/Content";
import { useSelector } from "react-redux";
const EventDetail = () => {
  const eventDetails = useSelector(localizedEventDetailsSelector);

  return (
    <Content localizedContent={eventDetails} setContent={setEventDetail} />
  );
};

export default WithHeader(EventDetail, "Event Details *");
