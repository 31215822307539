import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup.string().max(255).required('Password is required'),
});

const ForgetSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
});

const SecurityCodeSchema = Yup.object().shape({
  token: Yup.string()
    .max(6, "It's a 6 digit code")
    .min(6, "It's a 6 digit code")
    .required('Security Code is required'),
});

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter your password.')
    .min(8, 'Your password is too short.')
    .required('Security Code is required'),
  confirm_password: Yup.string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
});

const FormValidation = {
  LoginSchema,
  ForgetSchema,
  SecurityCodeSchema,
  ResetPasswordSchema,
};
export default FormValidation;
