import { CardHeader, Checkbox, FormControlLabel, Stack } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setRecommendation } from "../../store/actions/currentArticle";
import SelectionLimitDialog from "./SelectionLimitDialog";
import { WithHeader } from "./WithHeader";
import { LANGUAGE_KEY } from "../../constants/GeneralConstant";

const Recommendation = ({ resource, lang }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const selectedRecommendations = useSelector(
    (state) => state.article.recommendationsIds
  );
  let selectedRecommendationIds = selectedRecommendations.map((r) => r.id);

  const { learningResource } = useSelector((state) => state.learningResource);
  const data = learningResource.filter(
    (article) => article.learningResourceType === resource && article.id !== id
  );

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleToggle = (id, recommendationTitle) => () => {
    const currentIndex = selected.indexOf(recommendationTitle);
    const isSelected = selectedRecommendationIds.includes(id);

    if (selectedRecommendationIds.length === 5 && !isSelected) {
      setDialogOpen(true);
      return;
    }
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(
        learningResource.find(
          (item) => item?.title?.[lang]
          // item?.title?.[LANGUAGE_KEY.LUGANDA] ||
          // item?.title?.[LANGUAGE_KEY.RUNYAKITARA] === recommendationTitle
        ).title
      );
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    dispatch(setRecommendation(id));
  };

  const getRecommendationRow = (item) => {
    const title = item?.title?.[lang];
    if (!title) return;

    return (
      <Stack
        key={item.id}
        alignItems="center"
        direction="row"
        justifyContent="flex-start"
        marginLeft={2}
        sx={{ height: "4em" }}
      >
        <FormControlLabel
          value={item.id}
          control={
            <Checkbox
              checked={selectedRecommendationIds.includes(item.id)}
              onChange={handleToggle(item.id, title)}
            />
          }
        />
        <CardHeader key={item.id} title={title} sx={{ ml: -3 }} />
      </Stack>
    );
  };

  return (
    <>
      {data.length > 0 && data.map((item) => getRecommendationRow(item))}
      <SelectionLimitDialog
        data="Recommendation"
        count="5"
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
    </>
  );
};

export default WithHeader(Recommendation, "Recommendations");
