// external import
import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@mui/material';

import FormValidation from '../common/FormValidations';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { setpassword } from '../../store/actions/auth';
import { toast } from 'react-toastify';

function ResetPassword() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);

  var email = location.state.data.state.email;
  var token = location.state.token;

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: FormValidation.ResetPasswordSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      dispatch(setpassword(email, token, values.password))
        .then(() => {
          navigate('/login');
          toast.success(
            'Password has been successfully reset. Please use your new password to log in.'
          );
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%',
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 1, mt: 5 }}>
              <>
                <Typography color="textPrimary" variant="h4">
                  Reset Password
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Enter and confirm the new password for your account
                </Typography>
              </>
            </Box>
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />

            <TextField
              error={Boolean(
                formik.touched.confirm_password &&
                  formik.errors.confirm_password
              )}
              fullWidth
              helperText={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              }
              label="Confirm Password"
              margin="normal"
              name="confirm_password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.confirm_password}
              variant="outlined"
            />

            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loading && (
                  <span>
                    <CircularProgress size={20} />
                  </span>
                )}
                <span>Reset Password</span>
              </Button>
              {message && (
                <div className="form-group" style={{ paddingTop: '1em' }}>
                  <Alert severity="error">{message}</Alert>
                </div>
              )}
            </Box>
          </form>
        </Container>
      </Box>
    </div>
  );
}

export default ResetPassword;
