import { React, useEffect, useState } from "react";
import { URL } from "../../constants/URL";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { deleteAccountRequest } from "../../store/actions/DeleteAccountRequest";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { localizedDeleteAccountErrorMessageSelector } from "../../store/reducers/selector";
import { useSelector } from "react-redux";

function DeleteConfirmationPage() {
  const errorMessage = useSelector(localizedDeleteAccountErrorMessageSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayErrorToast, setDisplayErrorToast] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const match = location?.search?.match(/email=([^&]*)/);

  // Extract the captured group from the match
  const emailSubstring = match ? match[1] : null;

  useEffect(() => {
    if (errorMessage || displayErrorToast) {
      toast.error(errorMessage?.data?.message);
      setDisplayErrorToast(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!email || !token) {
      console.error("Email or token is missing");
      return;
    }

    const modifiedURL = `${
      URL.DELETE_ACCOUNT_REQUEST
    }?email=${encodeURIComponent(emailSubstring.toString())}&token=${token}`;
    const fetchData = async () => {
      try {
        await dispatch(deleteAccountRequest(modifiedURL)).then(() => {});
        toast.success("Delete account request submitted successfully");
        navigate("/help/delete-account");
      } catch (error) {
        setDisplayErrorToast(true);
        // toast.error("Delete account request failed");
        navigate("/help/delete-account");
      }
    };
    fetchData(); // Call the async function
  }, [email, token]);

  // You can replace the console.log with an API call here
  // Example: sendToApi(pageName, param1, param2);

  return (
    <div
      style={{
        backgroundColor: "#F0F0F0",
        width: "100%",
        height: "100vh", // Set height to 100vh (viewport height)
        color: "white", // Text color to make text visible on the black background
        textAlign: "center", // Center the text
        paddingTop: "50vh", // Center the text vertically
      }}
    >
      <h3 style={{ color: "black" }}>Requesting for Account Deletion...</h3>
    </div>
  );
}

export default DeleteConfirmationPage;
