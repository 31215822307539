import React, { useState } from 'react';
import './MixpanelChart.css';

const MixpanelChart = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div id="wrap">
      {isLoading && <div className="loading">Loading...</div>}
      <iframe
        id="scaled-frame"
        src="https://mixpanel.com/p/Gc1QQ1ECVKJFy5XwgNJFoS"
        title="mixpanel"
        onLoad={handleLoad}
      ></iframe>
    </div>
  );
};

export default MixpanelChart;
