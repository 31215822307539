import { createSelector } from 'reselect';

export const TinyMceContentSelector = (state) => state.content.policy;
export const TinyMceContentLanguageSelector = (state) => state.content.language;

export const localizedContentSelector = createSelector(
  [TinyMceContentSelector, TinyMceContentLanguageSelector],
  (policy, language) => {
    return policy ? policy[language] : null;
  }
);
