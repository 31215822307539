import React, { useEffect, useState } from "react";
// import Score from '../../common/Score';
import Header from "../../common/Header";
import CategorySelector from "../../common/CategorySelector";
import Recommendation from "../../common/Recommendation";
import VideoUpload from "../../common/VideoUpload";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  retrieveRescourcesbyId,
  clearCurrentRescource,
  setLearningResource,
  setLanguage,
} from "../../../store/actions/currentArticle";
import ResourceButton from "../../common/ResourceButton";
import { URL } from "../../../constants/URL";
import { useSelector } from "react-redux";

const RECOMMENDED_ASPECT_RATIO = 2 / 3;
const TOLERANCE = 0.05;
const recommendedSize =
  "Best results with a 2:3 image, like (400 x 600, 600 x 900)";
const Video = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const lang = useSelector((state) => state.article?.language);
  const article = useSelector((state) => state.article);
  const { learningResource } = useSelector((state) => state.learningResource);
  const resource = learningResource.filter(
    (resource) => resource.learningResourceType === article.learningResourceType
  );

  useEffect(() => {
    if (id) {
      dispatch(retrieveRescourcesbyId(id));
    } else {
      dispatch(clearCurrentRescource());
      dispatch(setLearningResource("Video"));
    }
  }, [dispatch, id]);
  return (
    <div>
      <Header
        setLanguage={setLanguage}
        data={resource}
        error={error}
        setError={setError}
      />
      <VideoUpload
        requiredAspectRatio={RECOMMENDED_ASPECT_RATIO}
        TOLERANCE={TOLERANCE}
        recommendedSize={recommendedSize}
      />
      <CategorySelector />
      {/* <Score /> */}
      <Recommendation resource={"Video"} lang={lang} />
      <ResourceButton
        addResourceUrl={URL.RESOURCE}
        ListingUrl={URL.LEARNING_RESOURCE_LISTING}
        error={error}
      />
    </div>
  );
};

export default Video;
