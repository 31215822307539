import React from 'react';

const EventDetailContent = ({ content }) => {
  if (!content) {
    return <p>No content</p>;
  }
  const contentStyle = {
    maxWidth: '100%',
    padding: '1em',
  };

  const replaceImageTags = (content) => {
    return content.replace(
      /<img/g,
      '<img style="max-width: 100%; height: auto;"'
    );
  };

  const modifiedContent = replaceImageTags(content);

  return (
    <div
      style={contentStyle}
      dangerouslySetInnerHTML={{ __html: modifiedContent }}
    />
  );
};

export default EventDetailContent;
