import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import { FORM_SUBMIT_SUCCESS, FORM_SUBMIT_FAILED } from "./type";

export const submitFormData = (url, record) => {
  // const headers = {
  //   // Add your custom headers here
  //   Authorization: "Bearer YOUR_ACCESS_TOKEN",
  //   // Other headers if needed
  //   ...customHeaders,
  // };

  return async (dispatch) => {
    try {
      const res = await trackPromise(api.post(url, record));

      dispatch({
        type: FORM_SUBMIT_SUCCESS,
        payload: res.data.data,
      });

      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: FORM_SUBMIT_FAILED,
        payload: err.response.data.message,
      });
      return Promise.reject();
    }
  };
};
