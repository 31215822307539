import { getFormLanguage } from "../../component/common/GeneralHelper";
import {
  RETRIEVE_EDIT_RESOURCE_SUCCESS,
  SELECTED_AUTHOR,
  TOGGLE_CATEGORY,
  SET_CONTENT,
  SET_COVER_IMAGE,
  SET_LANGUAGE,
  SET_READING_SCORE,
  SET_READING_TIME,
  SET_THUMBNAIL,
  SET_TITLE,
  SET_VEDIO_CONTENT,
  TOGGLE_RECOMMENDATION,
  SET_RESOURCE_TYPE,
  SET_TICK_IMAGE,
  DELETE_TICK,
  SET_SCIENTIFIC_VALUE,
  SET_LOCAL_VALUE,
  SET_HTML_IMAGE,
} from "../actions/type";
import {
  LANGUAGE_KEY,
  LEARNING_RESOURCE_TYPES,
} from "../../constants/GeneralConstant";
const initialState = {
  learningResourceType: "",
  language: "EN",
  metaData: { language: "EN" },
  status: "",
  title: { EN: "", LU: "", RY: "" },
  readtime: 0,
  points: 0,
  isAuthRequired: false,
  categoryIds: [],
  authorIds: [],
  coverImage: { EN: "", LU: "", RY: "" },
  recommendationsIds: [],
  content: { EN: "", LU: "", RY: "" },
  thumbnail: { EN: "" },
  video: { EN: "", LU: "", RY: "" },
  bodyPart: "",
  vBodyPart: "",
  ticks: [],
  htmlImage: { EN: [], LU: [], RY: [] },
};

export const learningReducer = (state = initialState, action) => {
  let language_key = state.language;
  switch (action.type) {
    case TOGGLE_CATEGORY:
      let currentCategoryIds = [];
      const selectedCategoryId = action.payload;
      const currentIds = state.categoryIds.flatMap((c) => c.id);
      if (currentIds.includes(selectedCategoryId)) {
        const index = currentIds.indexOf(selectedCategoryId);
        currentCategoryIds = [...state.categoryIds];
        if (index > -1) {
          currentCategoryIds.splice(index, 1);
        }
      } else {
        currentCategoryIds = [...state.categoryIds, { id: selectedCategoryId }];
      }
      return {
        ...state,
        categoryIds: currentCategoryIds,
      };
    case TOGGLE_RECOMMENDATION:
      let currentRecommendedIds = [];
      const selectedRecommendedId = action.payload;
      const currentRecommdIds = state.recommendationsIds.flatMap((c) => c.id);
      if (currentRecommdIds.includes(selectedRecommendedId)) {
        const index = currentRecommdIds.indexOf(selectedRecommendedId);
        currentRecommendedIds = [...state.recommendationsIds];
        if (index > -1) {
          currentRecommendedIds.splice(index, 1);
        }
      } else {
        currentRecommendedIds = [
          ...state.recommendationsIds,
          { id: selectedRecommendedId },
        ];
      }
      return {
        ...state,
        recommendationsIds: currentRecommendedIds,
      };
    case SELECTED_AUTHOR:
      let currentAuthorIds = [];
      const selectedAuthorId = action.payload;
      const currentAuthIds = state.authorIds.flatMap((c) => c.id);
      if (currentAuthIds.includes(selectedAuthorId)) {
        const index = currentAuthIds.indexOf(selectedAuthorId);
        currentAuthorIds = [...state.authorIds];
        if (index > -1) {
          currentAuthorIds.splice(index, 1);
        }
      } else {
        currentAuthorIds = [...state.authorIds, { id: selectedAuthorId }];
      }
      return {
        ...state,
        authorIds: currentAuthorIds,
      };
    case SET_READING_TIME:
      return {
        ...state,
        readtime: action.payload,
      };
    case SET_READING_SCORE:
      return {
        ...state,
        points: action.payload,
      };
    case SET_RESOURCE_TYPE:
      return {
        ...state,
        learningResourceType: action.payload,
      };
    case SET_TITLE:
      return {
        ...state,
        title: {
          ...state.title,
          [state.language]: action.payload,
        },
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        metaData: {
          language: action.payload,
        },
      };
    case SET_COVER_IMAGE:
      return {
        ...state,
        coverImage: {
          ...state.coverImage,
          [language_key]: action.payload,
        },
      };
    case SET_HTML_IMAGE:
      return {
        ...state,
        htmlImage: {
          ...state.htmlImage,
          [language_key]: [...state.htmlImage[language_key], action.payload],
        },
      };
    case SET_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          [state.language]: action.payload,
        },
      };
    case SET_THUMBNAIL:
      return {
        ...state,
        thumbnail: {
          ...state.thumbnail,
          [language_key]: action.payload,
        },
      };
    case SET_VEDIO_CONTENT:
      return {
        ...state,
        video: {
          ...state.video,
          [state.language]: action.payload,
        },
      };
    case SET_LOCAL_VALUE:
      const commonName = action.payload;
      const index = action.index;
      const updatedTick = {
        ...state.ticks[index],
        commonName: {
          ...state.ticks[index].commonName,
          [state.language]: commonName,
        },
      };
      const updatedTicks = [
        ...state.ticks.slice(0, index),
        updatedTick,
        ...state.ticks.slice(index + 1),
      ];
      return {
        ...state,
        ticks: updatedTicks,
      };
    case SET_SCIENTIFIC_VALUE:
      const scientific = action.payload;
      const indexrec = action.index;
      const updatedTick2 = {
        ...state.ticks[indexrec],
        scientificName: {
          ...state.ticks[indexrec].scientificName,
          [state.language]: scientific,
        },
      };
      const updatedTicks2 = [
        ...state.ticks.slice(0, indexrec),
        updatedTick2,
        ...state.ticks.slice(indexrec + 1),
      ];
      return {
        ...state,
        ticks: updatedTicks2,
      };
    case DELETE_TICK:
      const indexToDelete = action.payload;
      const updatedTicks3 = [
        ...state.ticks.slice(0, indexToDelete),
        ...state.ticks.slice(indexToDelete + 1),
      ];
      return {
        ...state,
        ticks: updatedTicks3,
      };
    case SET_TICK_IMAGE:
      return {
        ...state,
        ticks: [...state.ticks, { image: action.payload }],
      };
    case RETRIEVE_EDIT_RESOURCE_SUCCESS:
      if (!action.payload) {
        return { ...initialState };
      } else {
        // const lang = action.payload.content["EN"] ? "EN" : "LU";
        let lang = "";
        if (
          action.payload.learningResourceType === LEARNING_RESOURCE_TYPES.TICK
        ) {
          lang = action?.payload?.metaData?.language;
        } else {
          lang = getFormLanguage(action.payload.title);
        }
        // const lang = action?.payload?.metaData?.language;
        const newItems2 = action.payload.categories.map((category) => ({
          id: category.id,
        }));
        const newItems3 = action.payload.recommendations.map(
          (recommendation) => ({
            id: recommendation.id,
          })
        );
        const newItems4 = action.payload.authors.map((author) => ({
          id: author.id,
        }));
        return {
          ...state,
          title: action.payload.title,
          readtime: action.payload.readtime,
          points: 0,
          language: lang || "EN",
          content: action.payload.content,
          categoryIds: newItems2,
          coverImage: action.payload.coverImage,
          authorIds: newItems4,
          status: action.payload.status,
          video: { ...action.payload.video },
          thumbnail: action.payload.thumbnail,
          recommendationsIds: newItems3,
          learningResourceType: action.payload.learningResourceType,
          vBodyPart: action.payload.vBodyPart || "",
          ticks: action.payload.ticks || [],
          bodyPart: action.payload.bodyPart,
          isAuthRequired: action.payload.isAuthRequired,
          htmlImage: action.payload.htmlImage,
        };
      }
    default:
      return state;
  }
};
