import { Chip } from '@mui/material';
import React from 'react';

const ProductPreviewTag = ({ category }) => {
  const maxVisibleTags = 2;
  const remainingCount = category.length - maxVisibleTags;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '1em',
        marginTop: '0.5em',
      }}
    >
      {category.slice(0, maxVisibleTags).map((title) => (
        <Chip
          style={{ color: 'purple', marginRight: '8px' }}
          size="small"
          label={title.length > 10 ? title.slice(0, 10) + '...' : title}
          key={title}
        />
      ))}
      {remainingCount > 0 && (
        <Chip
          size="small"
          style={{ color: 'red' }}
          label={`+${remainingCount}`}
        />
      )}
    </div>
  );
};

export default ProductPreviewTag;
