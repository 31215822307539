import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from "../../../../store/actions/nearByActions";
import { NEAR_BY_FIELDS } from "../../../../constants/GeneralConstant";
import { localizedNearByAddressSelector } from "../../../../store/reducers/selector";
import { WithHeader } from "../../../common/WithHeader";
import TextFields from "../../../common/TextFields";

const Address = () => {
  const dispatch = useDispatch();

  const address = useSelector(localizedNearByAddressSelector);

  const HandleAddress = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setAddress(trimmedText));
  };
  return (
    <div>
      <TextFields
        placeholder={"Enter Address of nearby here"}
        name={"nearby"}
        defaultValue={address || ""}
        handleTextField={HandleAddress}
        title={NEAR_BY_FIELDS.ADDRESS}
      ></TextFields>
    </div>
  );
};

export default Address;
