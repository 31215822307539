import React from "react";
import TextFields from "../../common/TextFields";
import { useDispatch, useSelector } from "react-redux";
import { setEmailAddress } from "../../../store/actions/FormActions";
import { localizedFormEmailSelector } from "../../../store/reducers/selector";
import "../form.css";

const EmailAddress = () => {
  const dispatch = useDispatch();
  const emailAddress = useSelector(localizedFormEmailSelector);

  const HandleEmailAddress = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setEmailAddress(trimmedText));
  };
  return (
    <div className="form-field-col">
      <TextFields
        name={"form"}
        defaultValue={emailAddress}
        placeholder={"Enter Registered Email Address here"}
        handleTextField={HandleEmailAddress}
        title={"Email Address *"}
      ></TextFields>
    </div>
  );
};

export default EmailAddress;
