import React from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilteredStatus from './filter/FilteredStatus';
import FilteredCreationDate from './filter/FilteredCreationDate';
import FilteredUpdateDate from './filter/FilteredUpdateDate';
import FilteredCreateSelector from './filter/FilteredCreateSelector';
import FilteredUpdatedSelector from './filter/FilteredUpdatedSelector';
const FilterBoxModel = ({
  handleClose,
  status,
  activation,
  handleChipClick,
  filteredData,
  handleActivationChipClick,
  setFilteredData,
  setCreateDateError,
  setUpdateDateError,
  HandleClearAll,
  admin,
  handleApply,
}) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" sx={{ textAlign: 'center', flexGrow: 1 }}>
          Filter
        </Typography>
        <IconButton onClick={handleClose}>
          <ClearIcon color="action" fontSize="small" />
        </IconButton>
      </div>
      <FilteredStatus
        values={status}
        activation={activation}
        handleChipClick={handleChipClick}
        filteredData={filteredData}
        handleActivationChipClick={handleActivationChipClick}
      />
      <FilteredCreationDate
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        setCreateDateError={setCreateDateError}
      />
      <FilteredUpdateDate
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        setUpdateDateError={setUpdateDateError}
      />
      <FilteredCreateSelector
        admin={admin}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
      />
      <FilteredUpdatedSelector
        admin={admin}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center', // Align buttons in the center horizontally
          gap: '1em', // Add gap between buttons
          marginTop: '1em', // Add margin from the above content
        }}
      >
        <Button variant="contained" size="small" onClick={HandleClearAll}>
          Clear all
        </Button>
        <Button variant="contained" size="small" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterBoxModel;
