import React from "react";
import { TextField } from "@mui/material";
import { WithHeader } from "../common/WithHeader";
import { localizedAddressSelector } from "../../store/reducers/selector";
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from "../../store/actions/currentEvent";

const Address = () => {
  const dispatch = useDispatch();

  const eventAddress = useSelector(localizedAddressSelector);

  const HandleAddress = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setAddress(trimmedText));
  };

  return (
    <div>
      <TextField
        placeholder="Enter the address of your event here.."
        multiline
        rows={2}
        value={eventAddress || ""}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderRadius: 0, // set borderRadius to 0
          },
        }}
        onChange={HandleAddress}
        // value={localizedContent}
        variant="outlined"
      />
    </div>
  );
};

export default WithHeader(Address, "Address *");
