export const STATUS = {
  DRAFT: "Draft",
  PUBLISHED: "Published",
};

export const LEGALSTYPE = {
  TERM_AND_CONDITION: "TermsAndCondition",
  PRIVACYPOLICY: "PrivacyPolicy",
};

export const RESOURCE_TYPE = {
  ARTICLE: "Article",
  VIDEO: "Video",
};

export const NEAR_BY_FIELDS = {
  ADDRESS: "Address *",
  PHONE: "Phone *",
  INCHARGE: "Incharge *",
  FULL_NAME: "FullName *",
  DISTRICT: "District *",
};

export const LANGUAGE_KEY = {
  ENGLISH: "EN",
  LUGANDA: "LU",
  RUNYAKITARA: "RY",
};

export const LANGUAGE_TYPE = {
  ENGLISH: "English",
  LUGANDA: "LUGANDA",
  RUNYAKITARA: "Runyakitara",
};

export const LEARNING_RESOURCE_TYPES = {
  TICK: "Tick",
  ARTICLE: "Article",
  VIDEO: "Video",
  TRAINING: "Training",
  GALLERY: "Gallery",
};
