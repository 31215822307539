import { Box, Modal } from '@mui/material';
import React from 'react';
import { localizedContentSelector } from '../../store/reducers/content-selector';
import { useSelector } from 'react-redux';

const style = {
  width: '375px', // iPhone 6/7/8 width
  height: '600px', // iPhone 6/7/8 height
  backgroundColor: '#f5f5f5', // light gray background
  borderRadius: '40px', // round corners
  boxShadow: '0 10px 20px rgba(0,0,0,0.3)', // subtle drop shadow
  overflow: 'hidden', // hide content outside the div
  overflowY: 'scroll', // enable vertical scrollbar
  position: 'absolute', // use absolute position to center the box
  top: '50%', // position the top edge at the center of the viewport
  left: '50%', // position the left edge at the center of the viewport
  transform: 'translate(-50%, -50%)', // move the box up and left by half its width and height
};

const ContentPreview = ({ open, handleClose }) => {
  const localizedContent = useSelector(localizedContentSelector);
  const contentStyle = {
    maxWidth: '100%',
    padding: '3em',
  };

  const replaceImageTags = (content) => {
    return content?.replace(
      /<img/g,
      '<img style="max-width: 100%; height: auto;"'
    );
  };

  const modifiedContent = replaceImageTags(localizedContent);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <div>
            {localizedContent ? (
              <Box sx={{ ...style }}>
                <div
                  style={contentStyle}
                  dangerouslySetInnerHTML={{ __html: modifiedContent }}
                />
              </Box>
            ) : (
              <div style={{ margin: 20 }}>No content available</div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ContentPreview;
