import React, { useEffect, useState } from "react";
import { TextField, FormControl, MenuItem, Select, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../store/actions/nearByActions";

import "../../common/Header.css";
import "../../../App.css";
import { localizedProductTitleSelector } from "../../../store/reducers/ProductSelector";
import { CommentBankSharp, LanSharp } from "@mui/icons-material";

const NearByHeader = () => {
  const dispatch = useDispatch();
  const [lang, setLang] = useState("EN");
  const language = useSelector((state) => state.nearBy.language);

  const handleLanguage = (event) => {
    const language = event.target.value;
    dispatch(setLanguage(language));
  };

  useEffect(() => {
    setLang(language);
  }, [language]);
  //   const handleTitleChange = (e) => {
  //     const text = e.target.value;
  //     const trimmedText = text.trimStart(); // Remove only leading spaces
  //     dispatch(setTitle(trimmedText));
  //   };

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Aligns components to the right
            gap: "0.5rem",
            width: "100%",
            marginBottom: 3,
          }}
        >
          {/* <TextField
            id="title"
            size="small"
            className="title"
            required
            value={productTitle}
            variant="outlined"
            onChange={handleTitleChange}
            fullWidth
            // sx={{ flex: '1 1 50%', mr: '0.01rem' }}
            sx={{ mr: '0.01rem' }}
            placeholder={`Enter title for your product here..`}
          /> */}
          <FormControl
            size="small"
            className="selector"
            sx={{ minWidth: "10rem" }}
          >
            <Select
              onChange={handleLanguage}
              value={lang}
              displayEmpty
              name="language"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"LU"}>Luganda</MenuItem>
              <MenuItem value={"RY"}>Runyakitara</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </>
    </>
  );
};

export default NearByHeader;
