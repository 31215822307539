import React from 'react';
import BlobService from '../../services/blob-service';

const Thumbnail = ({ thumbnail }) => {
  const imageUri =
    thumbnail && thumbnail['EN'] && BlobService.getImageUri(thumbnail['EN']);

  return (
    <>
      {imageUri ? (
        <img
          src={imageUri}
          style={{ width: '100%', height: 280 }}
          alt="Selected file"
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: 280,
            backgroundColor: '#e0e0e0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: 20, fontWeight: 'bold' }}>
            No Image Available
          </span>
        </div>
      )}
    </>
  );
};

export default Thumbnail;
