import {
  Avatar,
  Button,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import BlobService from "../../../services/blob-service";
import {
  createAuthor,
  getAuthors,
  updateAuthor,
} from "../../../store/actions/author";
import { setAuthorId } from "../../../store/actions/currentArticle";
import { WithHeader } from "../../common/WithHeader";
import "../../../App.css";
import SelectionLimitDialog from "../../common/SelectionLimitDialog";
import { localizedAuthor } from "../../../store/reducers/selector";
const AuthorDetail = () => {
  const dispatch = useDispatch();
  const [authorName, setAuthorName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [authorNameError, setAuthorNameError] = useState("");
  const [selected, setSelected] = useState([]);
  // const [openDialog, setOpenDialog] = useState(false);
  const selectedAuthors = useSelector((state) => state.article.authorIds);
  // let selectedAuthorsId = selectedAuthors.flatMap((c) => c.id);
  const authorData = useSelector(localizedAuthor);
  let selectedAuthorsId = selectedAuthors
    .flatMap((author) => author.id)
    .filter((id) => authorData.some((author) => author.id === id));
  // const authorData = useSelector(localizedAuthor);
  const languageSelector = useSelector((state) => state.article.language);
  const authorUpdated = useSelector((state) => state.authorData.authorUpdated);

  useEffect(() => {
    dispatch(getAuthors());
  }, [dispatch, authorUpdated]);

  const uploadProfilePic = (authorId, file) => {
    const filename = BlobService.generateFileName(file);
    const fileSize = file.size / 1024 / 1024; // convert to MB
    if (fileSize > 20) {
      toast.error(`Image size exceeds 20 MB limit`);
      return;
    }
    BlobService.uploadImage(filename, file).then(() => {
      dispatch(updateAuthor(authorId, filename));
      dispatch(getAuthors());
    });
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleToggleAuthor = (id, fullname) => () => {
    const currentIndex = selected.indexOf(fullname);
    const selectedCount = selectedAuthors.length;
    const isCategorySelected = selectedAuthorsId.includes(id);

    if (selectedCount === 3 && !isCategorySelected) {
      setDialogOpen(true);
      return;
    }

    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(fullname);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    dispatch(setAuthorId(id));
  };

  const removeProfilePic = (authorId, filename) => {
    BlobService.deleteImage(filename).then(() => {
      dispatch(updateAuthor(authorId, ""));
      dispatch(getAuthors());
    });
  };

  const handleAddAuthor = () => {
    if (!authorName.trim()) {
      setAuthorNameError("Please enter a valid author name.");
      return;
    }
    setAuthorNameError("");
    dispatch(createAuthor(authorName.trim(), languageSelector));
    setAuthorName("");
    setAuthorNameError(""); // Reset the error message
  };

  const getAuthorRow = (author) => {
    const fullName = author?.fullname?.[languageSelector];
    const [firstName, lastName] =
      author?.fullname?.[languageSelector]?.split(" ");
    let initials;

    if (lastName) {
      initials =
        firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    } else {
      initials = firstName.substring(0, 2).toUpperCase();
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          key={author.id}
          alignItems="center"
          direction="row"
          marginLeft={2}
          sx={{ height: "5em" }}
        >
          <FormControlLabel
            value={author.id}
            control={
              <Checkbox
                checked={selectedAuthorsId.includes(author.id)}
                onChange={handleToggleAuthor(author.id, fullName)}
              />
            }
          />
          <Avatar
            alt={fullName}
            sx={{ width: 48, height: 48 }}
            src={BlobService.getImageUri(author.profilePic)}
          >
            {initials}
          </Avatar>
          <CardHeader title={fullName} />
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            color="primary"
            size="medium"
            aria-label="upload picture"
            component="label"
            className="authorUpload"
            // disabled={!author[0]}
          >
            Upload Image
            <input
              id="upload-image"
              hidden
              accept=".jpeg, .png, .jpg"
              type="file"
              onChange={(e) => {
                uploadProfilePic(author.id, e.target.files[0]);
                e.target.value = "";
              }}
            />
          </Button>
          <Button
            color="primary"
            aria-label="remove picture"
            component="label"
            className="authorUpload"
            disabled={!author.profilePic}
            onClick={(e) => removeProfilePic(author.id, author.profilePic)}
          >
            Remove Image
          </Button>
        </Box>
      </div>
    );
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {authorData.length > 0 &&
          authorData?.map((author) => getAuthorRow(author))}
        <TextField
          value={authorName}
          onChange={(event) => {
            setAuthorName(event.target.value);
            setAuthorNameError("");
          }}
          fullWidth
          placeholder="Start typing to add a new author..."
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 0, // set borderRadius to 0
            },
          }}
          InputProps={{
            endAdornment: authorName && (
              <Button
                sx={{ marginRight: "-12px" }}
                variant="contained"
                onClick={handleAddAuthor}
              >
                Add Author Name
              </Button>
            ),
          }}
        />
        {authorNameError && (
          <span style={{ color: "red", marginTop: "5px" }}>
            {authorNameError}
          </span>
        )}
        <SelectionLimitDialog
          data="Authors"
          count="3"
          open={dialogOpen}
          onClose={handleCloseDialog}
        />
      </div>
    </>
  );
};

export default WithHeader(AuthorDetail, "Author Details *");
