import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const WithHeader = (WrappedComponent, headerLabel) => {
  return function WihHeaderComponent({ ...props }) {
    return (
      <Box
        border={1}
        marginTop="1rem"
        borderRadius="0.2em 0.2em 0 0"
        borderColor="lightgrey"
      >
        <div
          style={{
            backgroundColor: "#D9D9D9",
            borderRadius: "0.2em 0.2em 0 0",
            height: "3.2em",
          }}
        >
          <Typography variant="h6" sx={{ p: 2 }}>
            {props?.title
              ? props?.title.split("*").map((text, index, array) => (
                  <React.Fragment key={index}>
                    {text}
                    {index !== array.length - 1 && (
                      <span style={{ color: "#E23939" }}>*</span>
                    )}
                  </React.Fragment>
                ))
              : headerLabel?.split("*").map((text, index, array) => (
                  <React.Fragment key={index}>
                    {text}
                    {index !== array.length - 1 && (
                      <span style={{ color: "#E23939" }}>*</span>
                    )}
                  </React.Fragment>
                ))}
          </Typography>
        </div>
        <WrappedComponent {...props} />
      </Box>
    );
  };
};
