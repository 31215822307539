import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import {
  deleteTick,
  setTickImage,
} from "../../../store/actions/currentArticle";
import BlobService from "../../../services/blob-service";
import { WithHeader } from "../../common/WithHeader";
import { getLocalizedTicks } from "../../../store/reducers/selector";
import TickInfo from "./TickInfo";
import FileUploader from "./FileUploader";
import Resizer from "react-image-file-resizer";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
const MAX_IMAGE_COUNT = 5;

const MultiImageUploader = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [files, setFiles] = useState([]);
  const ticks = useSelector(getLocalizedTicks);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const handleFileSelect = (e) => {
    // const selectedFiles = Array.from(e.target.files).slice(0, MAX_IMAGE_COUNT);
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 5) {
      setError("You can only select a maximum of 5 files.");
      return;
    }
    if (selectedFiles.length + ticks.length <= 5) {
      let isError = false;

      const newFiles = selectedFiles
        .map((file) => {
          if (
            file.size <= MAX_FILE_SIZE &&
            (file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "image/jpg")
          ) {
            return {
              file,
              progress: 0,
              filename: BlobService.generateMultipleFileName(file),
            };
          } else {
            isError = true;
            setError(
              file.size > MAX_FILE_SIZE
                ? "Please select a file within 5 MB."
                : "Invalid file format.Please select a JPG/JPEG/PNG file."
            );
            return null;
          }
        })
        .filter(Boolean);

      if (!isError) {
        setFiles([...files, ...newFiles]);

        const uploadPromises = newFiles.map((newFile, index) =>
          BlobService.uploadImage(
            newFile.filename,
            newFile.file,
            (progress) => {
              setFiles((prevFiles) =>
                prevFiles.map((f, i) =>
                  i === files.length + index ? { ...f, progress } : f
                )
              );
            }
          ).then((response) => {
            const uploadedFile = {
              filename: newFile.filename,
              containerName: response.containerName,
            };
            dispatch(setTickImage(newFile.filename));
            setUploadedFiles([...uploadedFiles, uploadedFile]);
            const imageUrl = newFile.filename;
            setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrl]);
          })
        );

        Promise.all(uploadPromises)
          .then(() => {
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setError("Error uploading files.");
          });
      }
    } else {
      setError("You can only select a maximum of 5 files.");
    }
  };
  const deleteFile = (filename, index) => {
    setError(null);
    // BlobService.deleteImage(filename).then(() => {
    const newFiles = files.filter((file) => file.filename !== filename);
    setFiles(newFiles);
    const newUploadedFiles = uploadedFiles.filter(
      (file) => file.filename !== filename
    );
    setUploadedFiles(newUploadedFiles);
    const newImageUrls = imageUrls.filter((imageUrl) => imageUrl !== filename);
    dispatch(deleteTick(index));
    setImageUrls(newImageUrls);
    // });
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFiles = Array.from(e.dataTransfer.files).slice(
      0,
      MAX_IMAGE_COUNT
    );
    if (selectedFiles.length + ticks.length <= 5) {
      let isError = false;
      const newFiles = selectedFiles
        .map((file) => {
          if (
            file.size <= MAX_FILE_SIZE &&
            (file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "image/jpg")
          ) {
            return {
              file,
              progress: 0,
              filename: BlobService.generateMultipleFileName(file),
            };
          } else {
            isError = true;
            setError(
              file.size > MAX_FILE_SIZE
                ? "File size exceeds the maximum limit of 5 MB."
                : "Invalid file format. Allowed formats are JPG/JPEG/PNG."
            );
            return null;
          }
        })
        .filter(Boolean);

      if (!isError) {
        setFiles([...files, ...newFiles]);

        const uploadPromises = newFiles.map((newFile, index) =>
          BlobService.uploadImage(
            newFile.filename,
            newFile.file,
            (progress) => {
              setFiles((prevFiles) =>
                prevFiles.map((f, i) =>
                  i === files.length + index ? { ...f, progress } : f
                )
              );
            }
          ).then((response) => {
            const uploadedFile = {
              filename: newFile.filename,
              containerName: response.containerName,
            };
            dispatch(setTickImage(newFile.filename));
            setUploadedFiles([...uploadedFiles, uploadedFile]);
            const imageUrl = newFile.filename;
            setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrl]);
          })
        );

        Promise.all(uploadPromises)
          .then(() => {
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setError("Error uploading files.");
          });
      }
    } else {
      setError("You can only select a maximum of 5 files.");
    }
  };

  // const handleFileSelect = (e) => {
  //   const selectedFiles = Array.from(e.target.files);
  //   if (selectedFiles.length > 5) {
  //     setError("You can only select a maximum of 5 files.");
  //     return;
  //   }
  //   if (selectedFiles.length + ticks.length <= 5) {
  //     let isError = false;

  //     const newFiles = selectedFiles
  //       .map((file) => {
  //         if (
  //           file.size <= MAX_FILE_SIZE &&
  //           (file.type === "image/jpeg" ||
  //             file.type === "image/png" ||
  //             file.type === "image/jpg")
  //         ) {
  //           return {
  //             file,
  //             progress: 0,
  //             filename: BlobService.generateMultipleFileName(file),
  //           };
  //         } else {
  //           isError = true;
  //           setError(
  //             file.size > MAX_FILE_SIZE
  //               ? "Please select a file within 5 MB."
  //               : "Invalid file format. Please select a JPG/JPEG/PNG file."
  //           );
  //           return null;
  //         }
  //       })
  //       .filter(Boolean);

  //     if (!isError) {
  //       setFiles([...files, ...newFiles]);

  //       const uploadPromises = newFiles.map((newFile, index) => {
  //         const { file, filename } = newFile;

  //         // Check if resizing is necessary based on image dimensions
  //         const img = new Image();
  //         img.onload = function () {
  //           const width = this.width;
  //           const height = this.height;
  //           const maxWidth = 275; // maximum width
  //           const maxHeight = 183; // maximum height

  //           if (width > maxWidth || height > maxHeight) {
  //             // Resize the image before uploading
  //             Resizer.imageFileResizer(
  //               file,
  //               maxWidth,
  //               maxHeight,
  //               file.type.split("/")[1], // format
  //               100, // quality
  //               0, // rotation
  //               (resizedFile) => {
  //                 // console.log("resizedFile:");
  //                 const reader = new FileReader();

  //                 // Set up a callback for when the file is loaded
  //                 reader.onload = function (event) {
  //                   // Create a new image element
  //                   const img = new Image();

  //                   // Set the src of the image to the data URL
  //                   img.src = event.target.result;

  //                   // Set up a callback for when the image is loaded
  //                   img.onload = function () {
  //                     // Log the width and height of the image
  //                     // console.log("Width:", this.width);
  //                     // console.log("Height:", this.height);
  //                   };
  //                 };

  //                 // Read the file as a data URL
  //                 reader.readAsDataURL(resizedFile);
  //                 BlobService.uploadImage(filename, resizedFile, (progress) => {
  //                   setFiles((prevFiles) =>
  //                     prevFiles.map((f, i) =>
  //                       i === files.length + index ? { ...f, progress } : f
  //                     )
  //                   );
  //                 })
  //                   .then((response) => {
  //                     const uploadedFile = {
  //                       filename: filename,
  //                       containerName: response.containerName,
  //                     };
  //                     dispatch(setTickImage(filename));
  //                     setUploadedFiles([...uploadedFiles, uploadedFile]);
  //                     const imageUrl = filename;
  //                     setImageUrls((prevImageUrls) => [
  //                       ...prevImageUrls,
  //                       imageUrl,
  //                     ]);
  //                   })
  //                   .catch((error) => {
  //                     console.error(error);
  //                     setError("Error uploading files.");
  //                   });
  //               },
  //               "blob" // output type
  //             );
  //           } else {
  //             // Upload the original image without resizing
  //             BlobService.uploadImage(filename, file, (progress) => {
  //               setFiles((prevFiles) =>
  //                 prevFiles.map((f, i) =>
  //                   i === files.length + index ? { ...f, progress } : f
  //                 )
  //               );
  //             })
  //               .then((response) => {
  //                 const uploadedFile = {
  //                   filename: filename,
  //                   containerName: response.containerName,
  //                 };
  //                 dispatch(setTickImage(filename));
  //                 setUploadedFiles([...uploadedFiles, uploadedFile]);
  //                 const imageUrl = filename;
  //                 setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrl]);
  //               })
  //               .catch((error) => {
  //                 console.error(error);
  //                 setError("Error uploading files.");
  //               });
  //           }
  //         };
  //         img.src = URL.createObjectURL(file);
  //       });

  //       Promise.all(uploadPromises)
  //         .then(() => {
  //           setError(null);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //           setError("Error uploading files.");
  //         });
  //     }
  //   } else {
  //     setError("You can only select a maximum of 5 files.");
  //   }
  // };

  // const deleteFile = (filename, index) => {
  //   setError(null);
  //   // BlobService.deleteImage(filename).then(() => {
  //   const newFiles = files.filter((file) => file.filename !== filename);
  //   setFiles(newFiles);
  //   const newUploadedFiles = uploadedFiles.filter(
  //     (file) => file.filename !== filename
  //   );
  //   setUploadedFiles(newUploadedFiles);
  //   const newImageUrls = imageUrls.filter((imageUrl) => imageUrl !== filename);
  //   dispatch(deleteTick(index));
  //   setImageUrls(newImageUrls);
  //   // });
  // };
  // const handleDragOver = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const selectedFiles = Array.from(e.dataTransfer.files).slice(
  //     0,
  //     MAX_IMAGE_COUNT
  //   );
  //   if (selectedFiles.length + ticks.length <= 5) {
  //     let isError = false;
  //     const newFiles = selectedFiles
  //       .map((file) => {
  //         if (
  //           file.size <= MAX_FILE_SIZE &&
  //           (file.type === "image/jpeg" ||
  //             file.type === "image/png" ||
  //             file.type === "image/jpg")
  //         ) {
  //           return {
  //             file,
  //             progress: 0,
  //             filename: BlobService.generateMultipleFileName(file),
  //           };
  //         } else {
  //           isError = true;
  //           setError(
  //             file.size > MAX_FILE_SIZE
  //               ? "File size exceeds the maximum limit of 5 MB."
  //               : "Invalid file format. Allowed formats are JPG/JPEG/PNG."
  //           );
  //           return null;
  //         }
  //       })
  //       .filter(Boolean);

  //     if (!isError) {
  //       setFiles([...files, ...newFiles]);

  //       const uploadPromises = newFiles.map((newFile, index) => {
  //         const { file, filename } = newFile;

  //         // Check if resizing is necessary based on image dimensions
  //         const img = new Image();
  //         img.onload = function () {
  //           const width = this.width;
  //           const height = this.height;
  //           const maxWidth = 275; // maximum width
  //           const maxHeight = 183; // maximum height

  //           if (width > maxWidth || height > maxHeight) {
  //             // Resize the image before uploading
  //             Resizer.imageFileResizer(
  //               file,
  //               maxWidth,
  //               maxHeight,
  //               file.type.split("/")[1], // format
  //               100, // quality
  //               0, // rotation
  //               (resizedFile) => {
  //                 // console.log("resizedFile:");
  //                 const reader = new FileReader();

  //                 // Set up a callback for when the file is loaded
  //                 reader.onload = function (event) {
  //                   // Create a new image element
  //                   const img = new Image();

  //                   // Set the src of the image to the data URL
  //                   img.src = event.target.result;

  //                   // Set up a callback for when the image is loaded
  //                   img.onload = function () {
  //                     // Log the width and height of the image
  //                     // console.log("Width:", this.width);
  //                     // console.log("Height:", this.height);
  //                   };
  //                 };

  //                 // Read the file as a data URL
  //                 reader.readAsDataURL(resizedFile);
  //                 BlobService.uploadImage(filename, resizedFile, (progress) => {
  //                   setFiles((prevFiles) =>
  //                     prevFiles.map((f, i) =>
  //                       i === files.length + index ? { ...f, progress } : f
  //                     )
  //                   );
  //                 })
  //                   .then((response) => {
  //                     const uploadedFile = {
  //                       filename: filename,
  //                       containerName: response.containerName,
  //                     };
  //                     dispatch(setTickImage(filename));
  //                     setUploadedFiles([...uploadedFiles, uploadedFile]);
  //                     const imageUrl = filename;
  //                     setImageUrls((prevImageUrls) => [
  //                       ...prevImageUrls,
  //                       imageUrl,
  //                     ]);
  //                   })
  //                   .catch((error) => {
  //                     console.error(error);
  //                     setError("Error uploading files.");
  //                   });
  //               },
  //               "blob" // output type
  //             );
  //           } else {
  //             // Upload the original image without resizing
  //             BlobService.uploadImage(filename, file, (progress) => {
  //               setFiles((prevFiles) =>
  //                 prevFiles.map((f, i) =>
  //                   i === files.length + index ? { ...f, progress } : f
  //                 )
  //               );
  //             })
  //               .then((response) => {
  //                 const uploadedFile = {
  //                   filename: filename,
  //                   containerName: response.containerName,
  //                 };
  //                 dispatch(setTickImage(filename));
  //                 setUploadedFiles([...uploadedFiles, uploadedFile]);
  //                 const imageUrl = filename;
  //                 setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrl]);
  //               })
  //               .catch((error) => {
  //                 console.error(error);
  //                 setError("Error uploading files.");
  //               });
  //           }
  //         };
  //         img.src = URL.createObjectURL(file);
  //       });

  //       Promise.all(uploadPromises)
  //         .then(() => {
  //           setError(null);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //           setError("Error uploading files.");
  //         });
  //     }
  //   } else {
  //     setError("You can only select a maximum of 5 files.");
  //   }
  // };

  //Resize Message//
  // We will resize and adjust large-sized pictures for better UI. However,
  // we cannot resize small pictures.

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          borderColor: "grey.500",
          height: 330,
          position: "relative",
          m: 5,
        }}
        justifyContent="center"
        textAlign="center"
      >
        {ticks.length === 5 && (
          <Typography sx={{ color: "red" }}>
            You can only select a maximum of 5 files.
          </Typography>
        )}

        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          disabled={ticks.length === 5}
        >
          <input
            hidden
            accept=".jpeg, .png, .jpg"
            type="file"
            onChange={handleFileSelect}
            multiple
            disabled={ticks.length === 5}
          />
          <DriveFolderUploadRoundedIcon
            style={{ fontSize: "4em", color: "#D9D9D9" }}
          />{" "}
        </IconButton>
        {error && <Box color="red">{error}</Box>}
        <Box
          display="flex"
          marginTop="1rem"
          marginBottom="1rem"
          padding="3rem"
          border="dashed 1px #ccc"
          alignItems={"center"}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Typography variant="caption">
            drag and drop the image here or upload manually. <br />
          </Typography>
        </Box>
        <Typography variant="caption">
          Max File Size: 20 MB Allowed formats: JPEG/JPG/PNG <br /> Recommended
          image resolution (360x257)
        </Typography>
      </Box>
      <FileUploader files={files} />
      <TickInfo ticks={ticks} deleteFile={deleteFile} />
    </>
  );
};
export default WithHeader(MultiImageUploader, "Tick Images *");
