import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createResource,
  editResource,
  ListingRecord,
} from "../../store/actions/learningResource";
import { submitFormData } from "../../store/actions/FormService";
import {
  localizedFormEmailSelector,
  localizedFormNameSelector,
  localizedFormReasonSelector,
  localizedFormErrorMessageSelector,
} from "../../store/reducers/selector";

import { validateForm } from "../validations/validateForm";
import "../../App.css";

import { toast } from "react-toastify";
import { URL } from "../../constants/URL";
import { clearFormFields } from "../../store/actions/FormActions";
import PopUp from "../common/PopUp";

const FormSubmitButton = ({ error, addResourceUrl, ListingUrl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [displayErrorToast, setDisplayErrorToast] = useState(false);
  const emailAddress = useSelector(localizedFormEmailSelector);
  const reason = useSelector(localizedFormReasonSelector);
  const fullName = useSelector(localizedFormNameSelector);
  const errorMessage = useSelector(localizedFormErrorMessageSelector);

  useEffect(() => {
    if (displayErrorToast) {
      toast.error(errorMessage);
      setDisplayErrorToast(false);
      setOpen(false);
    }
  }, [displayErrorToast]);

  const handleSaveForm = async () => {
    try {
      await dispatch(
        submitFormData(URL.CONFIRM_DELETE_REQUEST, {
          email: emailAddress,
          reason: reason,
          fullname: fullName,
        })
      ).then(() => {
        dispatch(clearFormFields());
        setOpen(false);
      });
      toast.success("Form submitted successfully please check your email");
    } catch (error) {
      setDisplayErrorToast(true);
      // toast.error("");
    }
  };

  const handleFormSubmit = () => {
    const validationError = validateForm(emailAddress, fullName, reason);
    if (validationError) {
      toast.error(validationError);
      return;
    }
    setOpen(true);
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
    >
      <Button
        size="small"
        variant="contained"
        onClick={handleFormSubmit}
        style={{ margin: 10, marginLeft: "0" }}
      >
        Submit
      </Button>
      <PopUp
        open={open}
        setOpen={setOpen}
        handleSaveForm={handleSaveForm}
      ></PopUp>
    </div>
  );
};

export default FormSubmitButton;
