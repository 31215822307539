import { GET_ADMIN_DATA_SUCCESS } from '../actions/type';

const initialState = {
  admins: [],
  loading: false,
  error: null,
};

function admin(state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_DATA_SUCCESS:
      return {
        ...state,
        admins: [...action.payload],
        loading: false,
      };
    default:
      return state;
  }
}

export default admin;
