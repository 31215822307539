import React from 'react';
import { Box } from '@mui/system';
import { Modal, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  getFilteredAttachments,
  localizedCoverImageSelector,
  localizedProductAboutSelector,
  localizedProductApplicationSelector,
  localizedProductDosageSelector,
  localizedProductTitleSelector,
} from '../../../store/reducers/ProductSelector';
import PreviewTabs from './PreviewTabs';
import ProductPreviewTag from './ProductPreviewTag';
import BlobService from '../../../services/blob-service';
import ProductPreviewCoverImage from './ProductPreviewCoverImage';

const style = {
  width: '375px', // iPhone 6/7/8 width
  height: '630px', // iPhone 6/7/8 height
  backgroundColor: '#f5f5f5', // light gray background
  borderRadius: '40px', // round corners
  boxShadow: '0 10px 20px rgba(0,0,0,0.3)', // subtle drop shadow
  overflow: 'hidden', // hide content outside the div
  overflowY: 'scroll', // enable vertical scrollbar
  position: 'absolute', // use absolute position to center the box
  top: '50%', // position the top edge at the center of the viewport
  left: '50%', // position the left edge at the center of the viewport
  transform: 'translate(-50%, -50%)', // move the box up and left by half its width and height,
};

const ProductPreview = ({ handleClose, open }) => {
  const currentProduct = useSelector((state) => state.currentProduct);
  const productTitle = useSelector(localizedProductTitleSelector);
  const productAbout = useSelector(localizedProductAboutSelector);
  const productDosage = useSelector(localizedProductDosageSelector);
  const productApplication = useSelector(localizedProductApplicationSelector);
  const attachments = useSelector(getFilteredAttachments);

  const localizedCoverImage = useSelector(localizedCoverImageSelector);
  const imageUri = BlobService.getImageUri(localizedCoverImage);

  const eventCategories = useSelector((state) => state.species.species);
  const myArrayFiltered = eventCategories
    .filter((el) => {
      return currentProduct.speciesIds.some((f) => {
        return f.id === el.id;
      });
    })
    .map((el) => el.title.EN || el.title.LU);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <ProductPreviewCoverImage coverImage={imageUri} />
        <Typography
          sx={{
            wordWrap: 'break-word',
            marginLeft: '0.5em',
          }}
          variant="h5"
        >
          {productTitle}
        </Typography>
        <ProductPreviewTag category={myArrayFiltered} />
        <PreviewTabs
          productAbout={productAbout}
          productDosage={productDosage}
          productApplication={productApplication}
          attachments={attachments}
        />
      </Box>
    </Modal>
  );
};

export default ProductPreview;
