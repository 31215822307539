// external import
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import FormValidation from '../common/FormValidations';
import './SecurityCode.css';
import { useDispatch, useSelector } from 'react-redux';
import { secuirtyCode } from '../../store/actions/auth';

function SecuityCode() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state === null) {
      const searchParams = new URLSearchParams(location.search);
      const email = searchParams.get('email');
      location.state = { email: email };
    }
  }, [location]);

  const formik = useFormik({
    initialValues: {
      token: '',
    },
    validationSchema: FormValidation.SecurityCodeSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      var code = values.token.toString();
      dispatch(secuirtyCode(code))
        .then(() => {
          navigate('/set-password', {
            state: { data: location, token: code },
          });
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });
  return (
    <>
      {/* {location.state.showToast ? ( */}
      <Alert severity="info">Check your email for a password reset code!</Alert>
      {/* ) : null} */}
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%',
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 1, mt: 5 }}>
              <Typography color="textPrimary" variant="h4">
                Security Code
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Enter the security code you received on your registered email…
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.token && formik.errors.token)}
              fullWidth
              helperText={formik.touched.token && formik.errors.token}
              label="Security Token"
              margin="normal"
              name="token"
              type="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.token}
              variant="outlined"
              InputProps={{
                inputProps: {
                  inputMode: 'numeric',
                },
                // Optionally, you can add other props to the InputProps object
                // For example, to add a custom style, you can use the 'style' prop
                style: {
                  WebkitAppearance: 'none',
                  MozAppearance: 'textfield',
                },
              }}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loading && (
                  <span>
                    <CircularProgress size={20} />
                  </span>
                )}
                Continue
              </Button>
              {message && (
                <div className="form-group" style={{ paddingTop: '1em' }}>
                  <Alert severity="error">{message}</Alert>
                </div>
              )}
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
}

export default SecuityCode;
