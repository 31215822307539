import { Typography } from '@mui/material';
import React from 'react';

const Title = ({ title }) => {
  const title2 = title.substring(0, 55) + (title.length > 25 ? '...' : '');

  return (
    <Typography sx={{ wordWrap: 'break-word' }} variant="h6">
      {title2}
    </Typography>
  );
};

export default Title;
