import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import KYTHeader from "./KYTHeader";
import {
  clearCurrentRescource,
  retrieveRescourcesbyId,
  setContent,
  setHtmlImage,
} from "../../../store/actions/currentArticle";
import Content from "../../common/Content";
import MultiImageUploader from "./MultiImageUploader";
import { localizedContentSelector } from "../../../store/reducers/selector";
import KYTButton from "./KYTButton";

const Tick = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const localizedContent = useSelector(localizedContentSelector);

  useEffect(() => {
    if (id) {
      dispatch(retrieveRescourcesbyId(id));
    } else {
      dispatch(clearCurrentRescource());
    }
  }, [dispatch, id]);

  return (
    <div>
      <KYTHeader />
      <MultiImageUploader />
      <br />
      <Content
        localizedContent={localizedContent}
        setContent={setContent}
        setHtmlImage={setHtmlImage}
      />
      <br />
      <KYTButton />
    </div>
  );
};

export default Tick;
