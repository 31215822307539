import React from 'react';
import { Box } from '@mui/system';
import { Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  eventEndDateSelector,
  eventStartDateSelector,
  localizedAddressSelector,
  localizedAudienceSelector,
  localizedCoverImageSelector,
  localizedEventDetailsSelector,
  localizedTitleSelector,
} from '../../../store/reducers/selector';
import BlobService from '../../../services/blob-service';
import EventPreviewTitle from './EventPreviewTitle';
import EventPreviewCategory from './EventPreviewCategory';
import EventPreviewCoverImage from './EventPreviewCoverImage';
import EventInfo from './EventInfo';

const style = {
  width: '375px', // iPhone 6/7/8 width
  height: '630px', // iPhone 6/7/8 height
  backgroundColor: '#f5f5f5', // light gray background
  borderRadius: '40px', // round corners
  boxShadow: '0 10px 20px rgba(0,0,0,0.3)', // subtle drop shadow
  overflow: 'hidden', // hide content outside the div
  overflowY: 'scroll', // enable vertical scrollbar
  position: 'absolute', // use absolute position to center the box
  top: '50%', // position the top edge at the center of the viewport
  left: '50%', // position the left edge at the center of the viewport
  transform: 'translate(-50%, -50%)', // move the box up and left by half its width and height,
};

const Preview = ({ handleClose, open }) => {
  const currentEvent = useSelector((state) => state.currentEvent);
  const currentEventLanguage = useSelector(
    (state) => state.currentEvent.eventLanguage
  );
  const eventTitle = useSelector(localizedTitleSelector);
  const eventAddress = useSelector(localizedAddressSelector);
  const eventAud = useSelector(localizedAudienceSelector);
  const eventDetail = useSelector(localizedEventDetailsSelector);
  const eventStartTime = useSelector(eventStartDateSelector);
  const eventEndTime = useSelector(eventEndDateSelector);
  const { organizorData } = useSelector((state) => state.organizorData);

  const eventCategories = useSelector(
    (state) => state.categories.eventCategories
  );
  const myArrayFiltered = eventCategories
    .filter((el) => {
      return currentEvent.categoryIds.some((f) => {
        return f.id === el.id;
      });
    })
    .map((el) => el.title.EN || el.title.LU);

  const localizedContent = useSelector(localizedCoverImageSelector);
  const imageUri = BlobService.getImageUri(localizedContent);

  const organizor =
    organizorData &&
    Array.isArray(organizorData) &&
    organizorData.find((a) => a.id === currentEvent.organizerId.id);

  const newObj = {};
  if (organizor && organizor.fullname && organizor.details) {
    newObj.fullname = organizor.fullname[currentEvent.language];
    newObj.details = organizor.details[currentEvent.language];
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <EventPreviewCoverImage coverImage={imageUri} />
        <EventPreviewCategory category={myArrayFiltered} />
        <EventPreviewTitle title={eventTitle} />
        <EventInfo
          eventAddress={eventAddress}
          eventAud={eventAud}
          eventDetail={eventDetail}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          organizor={newObj}
          currentEventLanguage={currentEventLanguage}
        />
      </Box>
    </Modal>
  );
};

export default Preview;
