import { Button } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createResource,
  editResource,
  ListingRecord,
} from "../../store/actions/learningResource";
import {
  localizedContentSelector,
  localizedCoverImage,
  localizedThumbnailSelector,
  localizedVideoSelector,
} from "../../store/reducers/selector";
import { validateResource } from "../validations/validateResource";
import "../../App.css";
import usePrompt from "../hook/usePrompt";
import Preview from "../preview/Preview";
import { toast } from "react-toastify";
import { Article } from "@mui/icons-material";
import { URL } from "../../constants/URL";

const ResourceButton = ({ error, addResourceUrl, ListingUrl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isPublished, setIsPublished] = useState(true);
  const localizedVideo = useSelector(localizedVideoSelector);
  const localizedThumbail = useSelector(localizedThumbnailSelector);
  const localizedContent = useSelector(localizedContentSelector);
  const [previewOpen, setPreviewOpen] = useState(false);
  const resourceType = useSelector((state) => state.resourceType);
  const article = useSelector((state) => state.article);
  const coverImage = useSelector(localizedCoverImage);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleSaveResource = async (status) => {
    const validationError = validateResource(
      article,
      error,
      localizedVideo,
      localizedThumbail,
      localizedContent
    );
    if (validationError) {
      toast.error(validationError);
      return;
    }

    let filteredArticle = article;

    switch (article.learningResourceType) {
      case "Article":
        filteredArticle = {
          ...article,
          bodyPart: undefined,
        };
        break;
      case "Video":
      case "Training":
        filteredArticle = {
          ...article,
          authorId: undefined,
          coverImage: undefined,
          bodyPart: undefined,
        };
        break;
      case "Gallery":
        filteredArticle = {
          ...article,
          authorId: undefined,
          bodyPart: undefined,
        };
        // case 'NearBy':
        //   filteredArticle =
        //   {
        //     items: [
        //       {
        //         fullname: article.fullname,
        //         addressLine1: article.addressLine1,
        //         phone: article.phone,
        //         fullTimeIncharge: article.fullTimeIncharge,
        //         district: article.district,
        //       }
        //     ]
        //   }
        break;
      default:
        break;
    }

    // if(filteredArticle.language === "EN" && filteredArticle.title.LU ==='')
    // {
    //   if(filteredArticle.title)
    //   filteredArticle.title.LU=  filteredArticle?.title.EN;
    //   if(filteredArticle.content)
    //   filteredArticle.content.LU=  filteredArticle?.content.EN;
    //   if(filteredArticle.video)
    //   filteredArticle.video.LU = filteredArticle?.video.EN;
    //   if(filteredArticle.coverImage)
    //   filteredArticle.coverImage.LU=  filteredArticle?.coverImage.EN;
    // }
    // else if(filteredArticle.language === "LU" && filteredArticle.title.EN ==='')
    // {
    //   if(filteredArticle.title)
    //   filteredArticle.title.EN = filteredArticle?.title.LU
    //   if(filteredArticle.content)
    //   filteredArticle.content.EN = filteredArticle?.content.LU
    //   if(filteredArticle.video)
    //   filteredArticle.video.EN = filteredArticle?.video.LU
    //   if(filteredArticle.coverImage)
    //   filteredArticle.coverImage.EN =filteredArticle?.coverImage.LU
    // }

    filteredArticle.status = status;

    try {
      if (id) {
        await dispatch(editResource(addResourceUrl, id, filteredArticle)).then(
          () => {
            dispatch(ListingRecord(ListingUrl, resourceType));
          }
        );
        toast.success(`${article.learningResourceType} edited successfully!`);
      } else {
        await dispatch(createResource(addResourceUrl, filteredArticle)).then(
          () => {
            dispatch(
              ListingRecord(URL.LEARNING_RESOURCE_LISTING, resourceType)
            );
          }
        );
        toast.success(
          `${
            article.learningResourceType
          } successfully ${filteredArticle?.status?.toLowerCase()}!`
        );
      }
      navigate(`/${article.learningResourceType.toLowerCase()}`);
    } catch (error) {
      toast.error(
        `Failed to ${id ? "edit" : "create"} ${article.learningResourceType}!`
      );
    }
  };

  const handleCreateArticle = () => {
    setIsPublished(false);
    handleSaveResource("Draft");
  };

  const handlePublishArticle = () => {
    setIsPublished(false);
    handleSaveResource("Published");
  };

  const isBlocking = () => {
    return (
      (article?.title?.length > 0 ||
        localizedContent?.length > 0 ||
        localizedVideo ||
        localizedThumbail ||
        coverImage) &&
      isPublished
    );
  };

  usePrompt("Are you sure you want to leave?", isBlocking());

  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
    >
      <Button
        size="small"
        variant="contained"
        onClick={handlePreviewOpen}
        style={{ margin: 10 }}
      >
        Preview
      </Button>
      <Preview open={previewOpen} handleClose={handlePreviewClose} />
      {
        // resourceType !== "NearBy" &&
        <Button
          size="small"
          variant="contained"
          onClick={handleCreateArticle}
          style={{ margin: 10 }}
        >
          Draft
        </Button>
      }
      <Button
        size="small"
        variant="contained"
        onClick={handlePublishArticle}
        style={{ margin: 10 }}
      >
        Publish
      </Button>
    </div>
  );
};

export default ResourceButton;
