import { SET_CURRENT_RESOURCE_TYPE } from '../actions/type';

const initialState = {};

function resourceType(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_RESOURCE_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    default:
      return state;
  }
}

export default resourceType;
