import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { WithHeader } from "../common/WithHeader";
import { setEventLanguage } from "../../store/actions/currentEvent";
import { useDispatch, useSelector } from "react-redux";

const EventLanguage = () => {
  const languageSelector = useSelector(
    (state) => state.currentEvent.eventLanguage
  );

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const language = event.target.value;
    dispatch(setEventLanguage(language));
  };
  return (
    <Box
      sx={{
        minWidth: 120,
        "& .MuiOutlinedInput-root": {
          borderRadius: 0, // set borderRadius to 0
        },
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Language</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={languageSelector}
          label="Select Language"
          onChange={handleChange}
        >
          <MenuItem value={"EN"}>English</MenuItem>
          <MenuItem value={"LU"}>Luganda</MenuItem>
          <MenuItem value={"RY"}>Runyakitara</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default WithHeader(EventLanguage, "Language *");
