import { getFormLanguage } from "../../component/common/GeneralHelper";
import { LANGUAGE_KEY } from "../../constants/GeneralConstant";
import {
  DELETE_ATTACHMENT,
  RETRIEVE_PRODUCT_SUCCESS,
  SET_PRODUCT_ABOUT,
  SET_PRODUCT_APPLICATION,
  SET_PRODUCT_ATTACHMENT,
  SET_PRODUCT_COVER_IMAGE,
  SET_PRODUCT_DOSAGE,
  SET_PRODUCT_HTML_IMAGE,
  SET_PRODUCT_LANGUAGE,
  SET_PRODUCT_TITLE,
  TOGGLE_PRODUCT_SPECIES,
} from "../actions/type";

const initialState = {
  language: "EN",
  metaData: { language: "EN" },
  status: "Draft",
  title: { EN: "", LU: "", RY: "" },
  coverImage: { EN: "" },
  about: { EN: "", LU: "", RY: "" },
  application: { EN: "", LU: "", RY: "" },
  dosage: { EN: "", LU: "", RY: "" },
  speciesIds: [],
  attachments: { EN: [], LU: [], RY: [] },
  htmlImage: { EN: [], LU: [], RY: [] },
};

export const currentProductReducer = (state = initialState, action) => {
  let language_key = state.language;
  switch (action.type) {
    case SET_PRODUCT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        metaData: {
          language: action.payload,
        },
      };
    case SET_PRODUCT_TITLE:
      return {
        ...state,
        title: {
          ...state.title,
          [state.language]: action.payload,
        },
      };
    case SET_PRODUCT_COVER_IMAGE:
      return {
        ...state,
        coverImage: {
          ...state.coverImage,
          [state.language]: action.payload,
        },
      };
    case SET_PRODUCT_ABOUT:
      return {
        ...state,
        about: {
          ...state.about,
          [state.language]: action.payload,
        },
      };
    case SET_PRODUCT_HTML_IMAGE:
      return {
        ...state,
        htmlImage: {
          ...state.htmlImage,
          [language_key]: [...state.htmlImage[language_key], action.payload],
        },
      };
    case SET_PRODUCT_APPLICATION:
      return {
        ...state,
        application: {
          ...state.application,
          [state.language]: action.payload,
        },
      };
    case SET_PRODUCT_DOSAGE:
      return {
        ...state,
        dosage: {
          ...state.dosage,
          [state.language]: action.payload,
        },
      };
    case TOGGLE_PRODUCT_SPECIES:
      let currentSpecieIds = [];
      const selectedSpeciesId = action.payload;
      const currentIds = state.speciesIds.flatMap((c) => c.id);
      if (currentIds.includes(selectedSpeciesId)) {
        const index = currentIds.indexOf(selectedSpeciesId);
        currentSpecieIds = [...state.speciesIds];
        if (index > -1) {
          currentSpecieIds.splice(index, 1);
        }
      } else {
        currentSpecieIds = [...state.speciesIds, { id: selectedSpeciesId }];
      }
      return {
        ...state,
        speciesIds: currentSpecieIds,
      };
    case SET_PRODUCT_ATTACHMENT:
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [state.language]: [
            ...state.attachments[state.language],
            {
              filename: action.payload.filename,
              name: action.payload.name,
              size: action.payload.size,
              type: action.payload.type,
            },
          ],
        },
      };
    case DELETE_ATTACHMENT:
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [state.language]: state.attachments[state.language].filter(
            (attachment, index) => index !== action.payload
          ),
        },
      };
    case RETRIEVE_PRODUCT_SUCCESS:
      if (!action.payload) {
        return { ...initialState };
      } else {
        const newItems2 = action.payload.species.map((specie) => ({
          id: specie.id,
        }));
        // const organizorlang = action.payload.organizer.fullname;
        const lang = getFormLanguage(action.payload?.title);
        // const lang = action.payload.title[LANGUAGE.ENGLISH]
        //   ? LANGUAGE.ENGLISH
        //   : action?.payload?.metaData?.language;

        return {
          ...state,
          title: action.payload.title,
          language: lang || LANGUAGE_KEY.ENGLISH,
          speciesIds: newItems2,
          coverImage: action.payload.coverImage,
          status: action.payload.status,
          about: action.payload.about,
          application: action.payload.application,
          dosage: action.payload.dosage,
          attachments: action.payload.attachments,
          htmlImage: action.payload.htmlImage,
        };
      }
    default:
      return state;
  }
};
