import React, { useState } from "react";
import { FormControl, MenuItem, Select, Box, Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../store/actions/currentArticle";
import "../../../App.css";
import {
  getLocalizedLanguageTicks,
  getLocalizedTicks,
  languageSelector,
  localizedContentSelector,
} from "../../../store/reducers/selector";

const KYTHeader = () => {
  const dispatch = useDispatch();
  const resource = useSelector((state) => state.article);
  const language = useSelector(languageSelector);
  const localizedContent = useSelector(localizedContentSelector);
  // const [lang, setLang] = useState("EN");

  const handleLanguage = (event) => {
    const language = event.target.value;
    // setLang(language);
    dispatch(setLanguage(language));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h5">{resource.vBodyPart[language]}</Typography>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
        }}
      >
        <FormControl
          size="small"
          className="selector"
          sx={{ minWidth: "10rem" }}
        >
          <Select
            onChange={handleLanguage}
            value={language}
            displayEmpty
            name="language"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"EN"}>English</MenuItem>
            <MenuItem value={"LU"}>Luganda</MenuItem>
            <MenuItem value={"RY"}>Runyakitara</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default KYTHeader;
