import React from 'react';
import { Box } from '@mui/system';
import { Chip, Modal, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import Content from '../../preview/Content';
import { localizedContentSelector } from '../../../store/reducers/selector';

const style = {
  width: '375px', // iPhone 6/7/8 width
  height: '600px', // iPhone 6/7/8 height
  backgroundColor: '#f5f5f5', // light gray background
  borderRadius: '40px', // round corners
  boxShadow: '0 10px 20px rgba(0,0,0,0.3)', // subtle drop shadow
  overflow: 'hidden', // hide content outside the div
  overflowY: 'scroll', // enable vertical scrollbar
  position: 'absolute', // use absolute position to center the box
  top: '50%', // position the top edge at the center of the viewport
  left: '50%', // position the left edge at the center of the viewport
  transform: 'translate(-50%, -50%)', // move the box up and left by half its width and height
};

const Preview = ({ handleClose, open }) => {
  const { learningResource } = useSelector((state) => state.learningResource);
  const ticks = learningResource.filter(
    (tick) => tick.learningResourceType === 'Tick'
  );
  const localizedContent = useSelector(localizedContentSelector);
  const selectedBodyPart = useSelector((state) => state.article.bodyPart);
  const imageName = selectedBodyPart; // assuming props.name is either "Lion" or "Round"
  const imageUrl = process.env.PUBLIC_URL + '/img/' + imageName + '.png';
  const bodyParts = [...new Set(ticks.map((tick) => tick.bodyPart))];
  const shuffledBodyParts = bodyParts.sort(() => Math.random() - 0.5);
  const selectedBodyParts = [selectedBodyPart, ...shuffledBodyParts]
    .filter((part, index, arr) => arr.indexOf(part) === index)
    .slice(0, 5);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <div style={{ margin: '15px', display: 'flex' }}>
          <MenuIcon style={{ marginTop: '0.3em', marginRight: '0.3em' }} />
          <Typography variant="h5">Know Your Ticks</Typography>
        </div>
        <div style={{ display: 'flex', margin: '0.9em' }}>
          {selectedBodyParts.map((type, index) => (
            <Chip
              sx={{ m: 0.6 }}
              key={index}
              label={type}
              color={type === selectedBodyPart ? 'primary' : 'default'}
            />
          ))}
        </div>

        <img
          src={imageUrl}
          style={{ width: '100%', height: 280 }}
          alt="Selected file"
        />
        <div style={{ backgroundColor: 'white' }}>
          <Content content={localizedContent} />
        </div>
      </Box>
    </Modal>
  );
};

export default Preview;
