import React from "react";
import { TextField } from "@mui/material";
import { WithHeader } from "../../../common/WithHeader";
// import { localizedAddressSelector } from '../../store/reducers/selector';
import { useDispatch, useSelector } from "react-redux";
import TextFields from "../../../common/TextFields";
import { setIncharge } from "../../../../store/actions/nearByActions";
import { NEAR_BY_FIELDS } from "../../../../constants/GeneralConstant";
import { localizedNearByInchargeSelector } from "../../../../store/reducers/selector";

const InChargeField = () => {
  const dispatch = useDispatch();

  const incharge = useSelector(localizedNearByInchargeSelector);
  // const incharge = useSelector(state=>console.log("state: ",state));

  const HandleIncharge = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setIncharge(trimmedText));
  };
  return (
    <div>
      <TextFields
        placeholder={"Enter Incharge of nearby here"}
        defaultValue={incharge || ""}
        name={"nearby"}
        handleTextField={HandleIncharge}
        title={NEAR_BY_FIELDS.INCHARGE}
      ></TextFields>
    </div>
  );
};

export default InChargeField;
