import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import {
  CREATE_NEARBY_SUCCESS,
  CREATE_NEARBY_FAILURE,
  EDIT_NEARBY_SUCCESS,
  EDIT_NEARBY_FAILURE,
  GET_NEARBY_SUCCESS,
  GET_NEARBY_FAILURE,
  DELETE_NEARBY_SUCCESS,
  DELETE_NEARBY_FAILURE,
  RETRIEVE_NEARBY_SUCCESS,
  RETRIEVE_NEARBY_FAILURE,
  SET_LANGUAGE,
  TOGGLE_GATING_CONTENT_SUCCESS,
  TOGGLE_GATING_CONTENT_FAILURE,
} from "./type";

export const getNearByList = (url) => async (dispatch) => {
  try {
    const res = await trackPromise(api.get(url));

    dispatch({
      type: GET_NEARBY_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_NEARBY_FAILURE, payload: err.message });
  }
};

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: lang,
  });
};

export const toggleContentGatingAll =
  (url, ids, isAuthorized) => async (dispatch) => {
    try {
      const res = await trackPromise(
        api.post(url, {
          ids,
          isAuthRequired: !isAuthorized,
        })
      );

      dispatch({
        type: TOGGLE_GATING_CONTENT_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({ type: TOGGLE_GATING_CONTENT_FAILURE, payload: err.message });
    }
    return Promise.reject();
  };

export const deleteNearBy = (url, id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.delete(`${url}/${id}`));
    dispatch({
      type: DELETE_NEARBY_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: DELETE_NEARBY_FAILURE, payload: err.message });
  }
  return Promise.reject();
};

export const createNearBy = (url, record) => {
  const { language, ...data } = record;
  return async (dispatch) => {
    try {
      const res = await trackPromise(api.post(url, data));
      dispatch({
        type: CREATE_NEARBY_SUCCESS,
        payload: res.data.data,
      });

      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: CREATE_NEARBY_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};

export const retrieveNearbyId = (url, id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.get(`${url}/${id}`));
    dispatch({
      type: RETRIEVE_NEARBY_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: RETRIEVE_NEARBY_SUCCESS, payload: err.message });
  }
};

export const clearCurrentNearBy = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_NEARBY_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: RETRIEVE_NEARBY_FAILURE, payload: err.message });
  }
};

export const toggleContentGating = (url, content) => async (dispatch) => {
  try {
    const res = await trackPromise(
      api.patch(`${url}/${content.id}`, {
        isAuthRequired: !content.isAuthRequired,
      })
    );
    dispatch({
      type: TOGGLE_GATING_CONTENT_SUCCESS,
      payload: res.data.data,
    });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: TOGGLE_GATING_CONTENT_FAILURE, payload: err.message });
  }
  return Promise.reject();
};

export const editNearBy = (url, id, record) => {
  const { language, ...data } = record;
  return async (dispatch) => {
    try {
      const res = await trackPromise(api.patch(`${url}/${id}`, data));
      dispatch({
        type: EDIT_NEARBY_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: EDIT_NEARBY_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};
