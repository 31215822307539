// import { useContext } from 'react';
// import Router from 'next/router';
import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';
// import { AuthContext } from '../contexts/auth-context';
// import { auth, ENABLE_AUTH } from '../lib/auth';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/auth';

export const AccountPopover = (props) => {
  const dispatch = useDispatch();
  const { anchorEl, onClose, open, ...other } = props;
  //   const authContext = useContext(AuthContext);

  const handleSignOut = async () => {
    onClose?.();
    dispatch(logout());
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: { width: '300px' },
      }}
      {...other}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          John Doe
        </Typography>
      </Box>
      <MenuList
        disablePadding
        sx={{
          '& > *': {
            '&:first-of-type': {
              borderTopColor: 'divider',
              borderTopStyle: 'solid',
              borderTopWidth: '1px',
            },
            padding: '12px 16px',
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};
