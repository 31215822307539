import {
  CLEAR_FILTER,
  SET_CREATION_END_DATE,
  SET_CREATION_START_DATE,
  SET_UPDATE_END_DATE,
  SET_UPDATE_START_DATE,
} from '../actions/type';

const initialState = {};

function filter(state = initialState, action) {
  switch (action.type) {
    // case SET_FILTERED_PARAMS:
    //   return {
    //     queryParams: action.payload,
    //   };
    case SET_CREATION_START_DATE:
      return {
        ...state,
        startCreationDate: action.payload,
      };
    case SET_CREATION_END_DATE:
      return {
        ...state,
        endCreationDate: action.payload,
      };
    case SET_UPDATE_START_DATE:
      return {
        ...state,
        startUpdateDate: action.payload,
      };
    case SET_UPDATE_END_DATE:
      return {
        ...state,
        endUpdateDate: action.payload,
      };
    case CLEAR_FILTER:
      return initialState;
    default:
      return state;
  }
}

export default filter;
