import React from "react";
import { TextField, FormControl, MenuItem, Select, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "../common/Header.css";
import "../../App.css";
import { setLanguage, setTitle } from "../../store/actions/currentEvent";
import { localizedTitleSelector } from "../../store/reducers/selector";

const EventHeader = () => {
  const dispatch = useDispatch();
  // const [lang, setLang] = useState('EN');
  const language = useSelector((state) => state.currentEvent.language);
  const eventTitle = useSelector(localizedTitleSelector);

  const handleLanguage = (event) => {
    const language = event.target.value;
    // setLang(language);
    dispatch(setLanguage(language));
  };
  const handleTitleChange = (e) => {
    const text = e.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setTitle(trimmedText));
  };

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            width: "100%",
            marginBottom: 3,
          }}
        >
          <TextField
            id="title"
            size="small"
            className="title"
            required
            value={eventTitle || ""}
            variant="outlined"
            onChange={handleTitleChange}
            fullWidth
            // sx={{ flex: '1 1 50%', mr: '0.01rem' }}
            sx={{ mr: "0.01rem" }}
            placeholder={`Enter title for your event`}
          />
          <FormControl
            size="small"
            className="selector"
            sx={{ minWidth: "10rem" }}
          >
            <Select
              onChange={handleLanguage}
              value={language}
              displayEmpty
              name="language"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"LU"}>Luganda</MenuItem>
              <MenuItem value={"RY"}>Runyakitara</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </>
    </>
  );
};

export default EventHeader;
