import {
  DELETE_ACCOUNT_REQUEST_FAILED,
  DELETE_ACCOUNT_REQUEST_SUCCESS,
} from "../actions/type";

const initialState = {
  language: "EN",
  errorMessage: "",
};

export const DeleteAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ACCOUNT_REQUEST_SUCCESS:
      return { ...initialState };
    case DELETE_ACCOUNT_REQUEST_FAILED:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};
