import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams } from 'react-router-dom';
import { clearCurrentRescource } from '../../../store/actions/currentArticle';
import { ListingRecord } from '../../../store/actions/learningResource';
import FilterBox from '../../Filter/FilterBox';
import { URL } from '../../../constants/URL';
import LearningResourceTable from '../../common/LearningResourceTable';
import { retrieveLearningResource } from '../../../store/actions/learningResource';
import KYTListTable from './KYTListTable';

const KYTList = () => {
  const { learningResource } = useSelector((state) => state.learningResource);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const currentResourceType = useSelector((state) => state.resourceType);

  const handleFilterBoxOpen = () => {
    setFilterBoxOpen(true);
  };

  const handleFilterBoxClose = () => {
    setFilterBoxOpen(false);
  };

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(retrieveLearningResource({ type: 'Tick' }));
    if (id === undefined) {
      dispatch(clearCurrentRescource());
    }
  }, [dispatch, id]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   dispatch(ListingRecord(URL.LEARNING_RESOURCE_LISTING, currentResourceType));
  //   if (id === undefined) {
  //     dispatch(clearCurrentRescource());
  //   }
  // }, [dispatch, id, currentResourceType]);

  const [searchQuery, setSearchQuery] = useState('');
  const rows = learningResource;
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClearSearchQuery = (event) => {
    setSearchQuery('');
  };

  
  const filteredData = rows?.filter((item) => {

    const title = item?.title;
    if (typeof title === 'string') {
      const searchQueryLowerCase = searchQuery?.toLowerCase();
      const lowercaseTitle = title.toLowerCase();
  
      return lowercaseTitle.includes(searchQueryLowerCase);
    }
  
    return false;
  });


  return (
    <>
      <div
        style={{
          display: 'flex',
          lineHeight: '0.01',
          marginBottom: '1em',
          height: '50px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography variant="h5">Know Your Ticks</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: 'auto',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            placeholder="Search by Predilection"
            className="searchBar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
              endAdornment: searchQuery ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearchQuery}>
                    <ClearIcon color="action" fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
          <FilterAltOutlinedIcon
            style={{
              cursor: 'pointer',
              fontSize: '40px',
              border: '1px solid #a6acbac9',
              padding: '3px',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '0.1em',
              color: '#rgb(51 51 51 / 73%)',
            }}
            onClick={handleFilterBoxOpen}
          />
          <FilterBox open={filterBoxOpen} handleClose={handleFilterBoxClose} />
        </div>
      </div>
      <KYTListTable data={filteredData}/>
    </>
  );
};

export default KYTList;
