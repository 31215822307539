import {
  CREATE_AUTHOR_FAILURE,
  CREATE_AUTHOR_REQUEST,
  CREATE_AUTHOR_SUCCESS,
  UPDATE_AUTHOR_FAILURE,
  UPDATE_AUTHOR_REQUEST,
  UPDATE_AUTHOR_SUCCESS,
  GET_AUTHOR_FAILURE,
  GET_AUTHOR_REQUEST,
  GET_AUTHOR_SUCCESS,
} from "./type";
import api from "../../services/api";
import { trackPromise } from "react-promise-tracker";
import { localizedObject } from "../../component/common/GeneralHelper";

export const getAuthors = () => {
  return async (dispatch) => {
    dispatch({ type: GET_AUTHOR_REQUEST });
    try {
      const response = await api.get("/author");
      dispatch({ type: GET_AUTHOR_SUCCESS, payload: response.data.data });
    } catch (error) {
      dispatch({ type: GET_AUTHOR_FAILURE, payload: error.message });
    }
  };
};

export const createAuthor = (fullname, lang) => {
  // const titleData = { EN: "", LU: "" };
  // // Conditionally set the title properties based on the lang value
  // titleData.EN = fullname;
  // titleData.LU = fullname;

  const fullNameData = localizedObject(lang, fullname);

  return async (dispatch) => {
    dispatch({ type: CREATE_AUTHOR_REQUEST });
    try {
      const response = await trackPromise(
        api.post("/author", {
          fullname: fullNameData,
        })
      );
      dispatch({ type: CREATE_AUTHOR_SUCCESS, payload: response.data.data });
    } catch (error) {
      dispatch({ type: CREATE_AUTHOR_FAILURE, payload: error.message });
    }
  };
};

export const updateAuthor = (id, profilePic) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_AUTHOR_REQUEST });
    try {
      const response = await trackPromise(
        api.patch(`/author/${id}`, {
          profilePic,
        })
      );
      dispatch({ type: UPDATE_AUTHOR_SUCCESS, payload: response.data.data });
    } catch (error) {
      dispatch({ type: UPDATE_AUTHOR_FAILURE, payload: error.message });
    }
  };
};
