import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

const FileUploader = ({ files }) => {
  return (
    <>
      {files &&
        files.map((file, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 2,
              // p: 3,
            }}
          >
            {file.progress < 100 ? (
              <>
                <InsertDriveFileOutlinedIcon
                  sx={{ color: '#D9D9D9', fontSize: '3em' }}
                />

                <Box sx={{ width: '100%', paddingRight: 3 }}>
                  <Typography variant="caption" sx={{ wdth: '40%' }}>
                    {' '}
                    {file.file.name}
                  </Typography>
                  <LinearProgress variant="determinate" value={file.progress} />
                  <Typography variant="caption" sx={{ wdth: '40%' }}>
                    {' '}
                    {file.progress} % done
                  </Typography>
                </Box>
              </>
            ) : null}
          </Box>
        ))}
    </>
  );
};

export default FileUploader;
