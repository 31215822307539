import React, { useEffect, useState } from "react";
import { WithHeader } from "../common/WithHeader";
import { useDispatch, useSelector } from "react-redux";
import { createOrganizor, getOrganizor } from "../../store/actions/organizor";
import { getFilteredOrganizor } from "../../store/reducers/selector";
import {
  Button,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { setOrganizorId } from "../../store/actions/currentEvent";
import { LANGUAGE_KEY } from "../../constants/GeneralConstant";

const Organizer = () => {
  const dispatch = useDispatch();
  const [organizorfullName, setOrganizorFullName] = useState("");
  const [organizorDetail, setOrganizorDetail] = useState("");
  const [errorFullname, setErrorFullname] = useState(false);
  const [errorDetails, setErrorDetails] = useState(false);
  const selectedOrganizor = useSelector(
    (state) => state.currentEvent.organizerId.id
  );
  const filteredOrganizor = useSelector(getFilteredOrganizor);
  const languageSelector = useSelector((state) => state.currentEvent.language);
  const authorUpdated = useSelector(
    (state) => state.organizorData.organizorUpdated
  );

  useEffect(() => {
    dispatch(getOrganizor());
  }, [dispatch, authorUpdated]);

  const handleSaveButton = () => {
    if (!organizorfullName.trim()) {
      setErrorFullname(true);
      return;
    }
    if (!organizorDetail.trim()) {
      setErrorDetails(true);
      return;
    }
    const org = {
      fullname: {
        [LANGUAGE_KEY.ENGLISH]: organizorfullName.trim(),
        [LANGUAGE_KEY.LUGANDA]: organizorfullName.trim(),
        [LANGUAGE_KEY.RUNYAKITARA]: organizorfullName.trim(),
      },
      details: {
        [LANGUAGE_KEY.ENGLISH]: organizorDetail.trim(),
        [LANGUAGE_KEY.LUGANDA]: organizorDetail.trim(),
        [LANGUAGE_KEY.RUNYAKITARA]: organizorDetail.trim(),
      },
    };
    dispatch(createOrganizor(org));
    setOrganizorFullName("");
    setOrganizorDetail("");
  };
  const handleOrganizorChange = (event) => {
    const optionId = event.target.value;
    dispatch(setOrganizorId(optionId));
  };

  const getAuthorRow = (item) => {
    const fullname = item.fullname[languageSelector];
    const details = item.details[languageSelector];

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          key={item.id}
          alignItems="center"
          direction="row"
          marginLeft={2}
          sx={{ height: "3em" }}
        >
          <FormControlLabel
            key={`${item.id}-FormControlLabel`}
            value={item.id}
            control={<Radio key={item.id} />}
          />
          <CardHeader title={fullname} key={`${item.id}-CardHeader`} />
          <a href={`${details}`}>{details}</a>
        </Stack>
      </div>
    );
  };
  return (
    <div>
      <RadioGroup value={selectedOrganizor} onChange={handleOrganizorChange}>
        {filteredOrganizor.length > 0 &&
          filteredOrganizor.map((item) => getAuthorRow(item))}
      </RadioGroup>
      <div style={{ display: "flex", margin: "0.5em" }}>
        <TextField
          type="text"
          value={organizorfullName}
          onChange={(e) => {
            setOrganizorFullName(e.target.value);
            setErrorFullname(false);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              // borderRadius: 0, // set borderRadius to 0
              width: "27em",
              marginRight: "0.5em",
              marginLeft: "0.2em",
            },
          }}
          placeholder="Enter the name of the organizer here..."
          error={errorFullname}
          helperText={errorFullname && "Fullname is required"}
        />
        <TextField
          type="text"
          value={organizorDetail}
          onChange={(e) => {
            setOrganizorDetail(e.target.value);
            setErrorDetails(false);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              // borderRadius: 0, // set borderRadius to 0
              width: "27em",
              marginRight: "0.5em",
            },
          }}
          placeholder="Enter the details or link to the organizer here.."
          error={errorDetails}
          helperText={errorDetails && "Details is required"}
        />
        <Button variant="contained" size="large" onClick={handleSaveButton}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default WithHeader(Organizer, "Organizer *");
