/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../common/DateFormatter";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import {
  deleteEvent,
  getEventsData,
  toggleEventActivation,
} from "../../../store/actions/event";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import { useNavigate } from "react-router-dom";

const CustomDataGrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

const EventTable = ({ data }) => {
  const { events } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    { field: "title", headerName: "Title", width: 250 },
    {
      field: "categories",
      headerName: "Category",
      flex: 1,
    },
    { field: "status", headerName: "Status", flex: 0.6 },
    {
      field: "updatedat",
      headerName: "Last Updated",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );
        const HandleActivation = (params) => {
          const res = events.find((obj) => {
            return obj.id === params.id;
          });
          dispatch(toggleEventActivation(params.id, res))
            .then(() => {
              if (res.status === "Published") {
                toast.success("Successfully deactivated!");
              } else {
                toast.success("Successfully activated!");
              }
            })
            .catch(() => {
              toast.error("Failed to gate/ungate resource!");
            });
        };

        const article = events.find((obj) => obj.id === params.id);

        let activation;
        if (article && article.status === "Published") {
          activation = (
            <Tooltip title="deactivate">
              <VisibilityOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        } else {
          activation = (
            <Tooltip title="activate">
              <VisibilityOffOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        }
        const [open, setOpen] = useState(false);

        const handleDelete = (params) => {
          dispatch(deleteEvent(params.id))
            .then(() => {
              // Refresh the article list
              dispatch(getEventsData()).then(() => {
                toast.success("Successfully deleted!");
              });
            })
            .catch((error) => {
              toast.error("Failed to delete resource!");
            });
        };

        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/event/${params.id}/edit`)}
              />
            </Tooltip>
            {activation}
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => setOpen(true)}
              />
            </Tooltip>
            <DeleteConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onDelete={() => handleDelete(params)}
            />
          </>
        );
      },
    },
  ];
  const rows = events.map((item) => {
    const title = item.title.EN || item.title.LU || item.title.RY;
    return {
      id: item.id,
      title: title.substring(0, 25) + (title.length > 25 ? "..." : ""),
      categories: item.categories
        ?.map(
          (category) =>
            category.title.EN || category.title.LU || category.title.RY
        )
        .join(","),
      status: item.status,
      updatedat: item.updatedAt
        ? `${formatDate(item.updatedAt).date} | ${
            formatDate(item.updatedAt).time
          } by ${
            item.updatedBy && item.updatedBy.fullname
              ? item.updatedBy.fullname
              : "Admin"
          }`
        : `${formatDate(item.createdAt).date} | ${
            formatDate(item.createdAt).time
          } by ${
            item.updatedBy && item.updatedBy.fullname
              ? item.updatedBy.fullname
              : "Admin"
          }`,
    };
  });

  const rows2 = data.map((item) => {
    const title = item.title.EN || item.title.LU || item.title.RY;

    return {
      id: item.id,
      title: title.substring(0, 25) + (title.length > 25 ? "..." : ""),
      categories: item.categories
        ?.map(
          (category) =>
            category.title.LU || category.title.EN || category.title.RY
        )
        .join(","),
      status: item.status,
      updatedat: item.updatedAt
        ? `${formatDate(item.updatedAt).date} | ${
            formatDate(item.updatedAt).time
          } by ${
            item.updatedBy && item.updatedBy.fullname
              ? item.updatedBy.fullname
              : "Admin"
          }`
        : `${formatDate(item.createdAt).date} | ${
            formatDate(item.createdAt).time
          } by ${
            item.updatedBy && item.updatedBy.fullname
              ? item.updatedBy.fullname
              : "Admin"
          }`,
    };
  });

  function CustomNoRowsOverlay() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div>
          <p>No results found!</p>
          <p>Try changing the search criteria and try again…</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ height: data.length < 5 ? 600 : 1200, width: "100%" }}>
        {data || data?.length > 1 ? (
          <CustomDataGrid
            rows={rows2}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        ) : (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default EventTable;
