import {
  SET_FULL_NAME,
  SET_ADDRESS,
  SET_DISTRICT,
  SET_PHONE,
  SET_INCHARGE,
  SET_LANGUAGE,
} from "./type";
import api from "../../services/api";
import { trackPromise } from "react-promise-tracker";

export const setAddress = (address) => async (dispatch) => {
  dispatch({
    type: SET_ADDRESS,
    payload: address,
  });
};

export const setFullName = (name) => async (dispatch) => {
  dispatch({
    type: SET_FULL_NAME,
    payload: name,
  });
};

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: lang,
  });
};

export const setDistrict = (district) => async (dispatch) => {
  dispatch({
    type: SET_DISTRICT,
    payload: district,
  });
};

export const setPhone = (phone) => async (dispatch) => {
  dispatch({
    type: SET_PHONE,
    payload: phone,
  });
};

export const setIncharge = (incharge) => async (dispatch) => {
  dispatch({
    type: SET_INCHARGE,
    payload: incharge,
  });
};
