import { TextField, FormControl, MenuItem, Select, Box } from "@mui/material";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setTitleValue } from "../../store/actions/currentArticle";
import "./Header.css";
import "../../App.css";
import {
  languageSelector,
  localizedTitle,
} from "../../store/reducers/selector";

const Title = ({ error, setError, data, setLanguage }) => {
  const dispatch = useDispatch();
  // const [error, setError] = useState(false);
  // const [lang, setLang] = useState('EN');
  const language = useSelector(languageSelector);

  const article = useSelector((state) => state.article);
  const { learningResource } = useSelector((state) => state.learningResource);
  const resource = learningResource.filter(
    (resource) => resource.learningResourceType === article.learningResourceType
  );
  const title = useSelector(localizedTitle);

  const handleTitleChange = (e) => {
    const text = e.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces

    const isTitleDuplicate = data?.some((item) => item.title === text);
    if (isTitleDuplicate) {
      setError(true);
      dispatch(setTitleValue(trimmedText));
    } else {
      setError(false);
      dispatch(setTitleValue(trimmedText));
    }
  };
  const handleLanguage = (event) => {
    const language = event.target.value;
    // setLang(language);
    dispatch(setLanguage(language));
  };

  function getResourceTypeValue(type) {
    if (!type) {
      return "Unknown Resource Type";
    }

    switch (type) {
      case "Training":
        return "Slidecasts";
      case "Video":
        return "Video";
      case "Gallery":
        return "Gallery";
      case "Article":
        return "Article";
      default:
        return "Unknown Resource Type";
    }
  }

  const selectedLinkFromLocalStorage = sessionStorage.getItem("selectedLink");
  const placeholderValue = article.learningResourceType
    ? `Enter title for your ${getResourceTypeValue(
        article.learningResourceType
      )}`
    : `Enter title for your ${selectedLinkFromLocalStorage.toLowerCase()}`;
  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            width: "100%",
            marginBottom: 3,
          }}
        >
          <TextField
            id="title"
            size="small"
            className="title"
            required
            value={title}
            variant="outlined"
            onChange={handleTitleChange}
            fullWidth
            // sx={{ flex: '1 1 50%', mr: '0.01rem' }}
            sx={{ mr: "0.01rem", mb: "5px" }}
            placeholder={placeholderValue}
            error={error}
            helperText={error && "Title already exist!"}
          />
          <FormControl
            size="small"
            className="selector"
            sx={{ minWidth: "10rem" }}
          >
            <Select
              onChange={handleLanguage}
              value={language}
              displayEmpty
              name="language"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"LU"}>Luganda</MenuItem>
              <MenuItem value={"RY"}>Runyakitara</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </>
    </>
  );
};

export default Title;
