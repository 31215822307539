import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextFields from "../../common/TextFields";
import { setReason } from "../../../store/actions/FormActions";
import { localizedFormReasonSelector } from "../../../store/reducers/selector";
import "../form.css";

const EmailAddress = () => {
  const dispatch = useDispatch();
  const reason = useSelector(localizedFormReasonSelector);

  const HandleEmailAddress = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setReason(trimmedText));
  };
  return (
    <div className="form-field-col">
      <TextFields
        name={"form"}
        defaultValue={reason}
        placeholder={"Enter Reason here"}
        handleTextField={HandleEmailAddress}
        title={"Reason *"}
      ></TextFields>
    </div>
  );
};

export default EmailAddress;
