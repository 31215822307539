import React from "react";
import { TextField } from "@mui/material";
import { WithHeader } from "../../../common/WithHeader";
// import { localizedAddressSelector } from '../../store/reducers/selector';
import { useDispatch, useSelector } from "react-redux";
import TextFields from "../../../common/TextFields";
import { setDistrict } from "../../../../store/actions/nearByActions";
import { NEAR_BY_FIELDS } from "../../../../constants/GeneralConstant";
import { localizedNearByDistrictSelector } from "../../../../store/reducers/selector";

const DistrictField = () => {
  const dispatch = useDispatch();

  const district = useSelector(localizedNearByDistrictSelector);

  const HandleDistrict = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setDistrict(trimmedText));
  };
  return (
    <div>
      <TextFields
        placeholder={"Enter District of nearby here"}
        name={"nearby"}
        defaultValue={district || ""}
        handleTextField={HandleDistrict}
        title={NEAR_BY_FIELDS.DISTRICT}
      ></TextFields>
    </div>
  );
};

export default DistrictField;
