// external import
import { React, useEffect } from "react";

//Components
import HelpingPageForm from "./Form";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Box,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { AccountCircle, Settings, Delete } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import { useDispatch } from "react-redux";
import { clearFormFields } from "../../store/actions/FormActions";
import "./form.css";
import { Link } from "react-router-dom";

function HelpDeleteAccount() {
  // useEffect(() => {
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("/help/delete-account")) {
      document.body.classList.add("landing-page-ui");
    }
  }, []);

  return (
    <>
      <main>
        <Grid
          container
          style={{
            backgroundColor: "#F2F2F2",
            padding: "1rem 2.5rem",
            justifyContent: "space-between",
          }}
          className="mainContainer topHeader"
        >
          <Grid item md={6} xs={6}>
            <img src="/img/tick-academy.svg" alt="Tick Academy Logo" />
          </Grid>
          <Grid item md={6} xs={6} style={{ textAlign: "right" }}>
            {" "}
            <img src="/img/elanco-logo.svg" alt="Elanco Logo" />
          </Grid>
        </Grid>
        <div className="middleContent" style={{ backgroundColor: "#F9FBFC" }}>
          <div className="middleContentInner">
            <Grid
              container
              style={{ padding: "1rem 2.5rem" }}
              className="mainContainer"
            >
              <Grid item md={7} xs={12}>
                <Typography
                  className="secondaryTitle"
                  variant="h4"
                  style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
                >
                  Account Deletion Request
                </Typography>
                <Typography variant="p">
                  Availing your right to be forgotten, you can request the
                  system to delete your profile along with all the data
                  originally generated for you. However, after this process, you
                  will lose complete access to the system and the system shall
                  erase your personal data throughout the application. Read more
                  about it in our{" "}
                  <a
                    href="https://www.privacy.elanco.com/"
                    target="_blank"
                    style={{ color: "rgb(17, 129, 151)" }}
                  >
                    Privacy Policy
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ padding: "1rem 2.5rem" }}
              className="mainContainer"
            >
              <Grid item md={7} xs={12}>
                <Typography variant="h6">
                  To delete your account from a mobile device, follow the steps
                  provided. Alternatively, you can delete it directly by
                  submitting the following form.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ padding: "1rem 2.5rem" }}
              className="mainContainer"
            >
              <Grid item md={4} xs={12}>
                <List className="custom-list">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        style={{ backgroundColor: "#118197", fontSize: "1rem" }}
                      >
                        01
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Go to My Profile" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={4} xs={12}>
                <List className="custom-list">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        style={{ backgroundColor: "#118197", fontSize: "1rem" }}
                      >
                        02
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Go to Account Management" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={4} xs={12}>
                <List className="custom-list">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        style={{ backgroundColor: "#118197", fontSize: "1rem" }}
                      >
                        03
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Select Delete account" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={4} xs={12}>
                <List className="custom-list">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        style={{ backgroundColor: "#118197", fontSize: "1rem" }}
                      >
                        04
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="A Popup will appear asking for confirmation for deletion" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={4} xs={12}>
                <List style={{ paddingLeft: "0" }} className="custom-list">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        style={{ backgroundColor: "#118197", fontSize: "1rem" }}
                      >
                        05
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="After Confirming once, click on the second confirmation to permanently delete your account" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={4} xs={12}>
                <List className="custom-list">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        style={{ backgroundColor: "#118197", fontSize: "1rem" }}
                      >
                        06
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="You’ll be redirected back to the Register screen and all your data will be wiped 
  From Tick Academy’s Server"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ padding: "1rem 2.5rem" }}
              className="mainContainer"
            >
              <Grid item md={7} xs={12}>
                <Typography variant="body1">
                  <strong style={{ color: "rgb(17, 129, 151)" }}>Note:</strong>{" "}
                  By submitting this form, you are confirming the deletion of
                  your Tick Academy account. Once confirmed, all associated
                  data, including email, phone, address, and other relevant
                  information, will be permanently removed. This action is
                  irreversible, and you won't be able to recover your data.
                  After submitting the form, please check your email for a
                  confirmation message. Click the provided link to finalize the
                  account deletion process. If you don't receive the email,
                  please check your app's spam folder.
                </Typography>
              </Grid>
              <Grid item md={7} xs={12}>
                <Typography variant="body1" style={{ paddingTop: "1rem" }}>
                  <strong style={{ color: "rgb(17, 129, 151)" }}>
                    Important:
                  </strong>{" "}
                  Tick Academy does not retain any of your personal data, and
                  your information will be permanently removed from our system.
                  We appreciate your time with Tick Academy and hope to serve
                  you again in the future. If you have any questions or
                  concerns, please contact our support team at{" "}
                  <a
                    href="mailto:tickacademy@elancoah.com"
                    target="_blank"
                    style={{ color: "rgb(17, 129, 151)" }}
                  >
                    tickacademy@elancoah.com
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid
          container
          style={{ padding: "1rem 2.5rem" }}
          className="mainContainer"
        >
          <Grid item xs={12}>
            <Typography variant="h4" className="main-title">
              Information
            </Typography>
          </Grid>
          <Grid item xs={12} className="helping-form">
            <HelpingPageForm></HelpingPageForm>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            padding: "0.3rem 2.5rem",
            backgroundColor: "rgb(17, 129, 151)",
            justifyContent: "center",
            color: "#fff",
          }}
          className="mainContainer landing-page-footer"
        >
          <Grid item md={6} xs={12}>
           <div className="footer-col">
           <span className="from-title"> from</span> 
              <a
                href="https://www.elanco.com/en-us"
                target="_blank"
                // style={{ color: "rgb(17, 129, 151)" }}
              >
                   <img src="/img/elanco-logo.svg" alt="Elanco Logo" />
              </a>
            {/* Content of the second grid */}
            <a
              style={{ color: "#fff" }}
              href="https://www.facebook.com/elancoanimalhealth/"
              target="_blank"
              title="Elanco's Facebook"
            >
              <FacebookIcon />
            </a>
            <a
              style={{ color: "#fff" }}
              href="https://twitter.com/Elanco/"
              target="_blank"
              title="Elanco's Twitter"
            >
              <TwitterIcon />
            </a>
            <a
              style={{ color: "#fff" }}
              href="https://www.instagram.com/weareelanco/"
              target="_blank"
              title="Elanco's Instagram"
            >
              {" "}
              <InstagramIcon />
            </a>
           </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <p>
              Elanco and the diagonal bar logo are trademarks of Elanco or its
              affiliates. © 2021 Elanco.
            </p>
          </Grid>
        </Grid>
      </main>
    </>
  );
}

export default HelpDeleteAccount;
