import { Chip } from '@mui/material';
import React from 'react';
import BlobService from '../../services/blob-service';

const ArticleRecommendation = ({ type, recommended }) => {
  return (
    <>
      <div style={{ marginLeft: 7, margin: 7 }}>
        <h2>Related {type}</h2>
        {recommended.map((item) => (
          <div style={{ display: 'flex', margin: 7 }} key={item?.title?.['EN'] || item?.title?.['LU']}>
            <img
              src={BlobService.getImageUri(item?.coverImage?.['EN'] || item?.coverImage?.['LU'])}
              style={{ height: '6em', width: '6em', borderRadius: 6 }}
              alt="Selected file"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  'https://via.placeholder.com/150x150.png?text=Image+not+available';
              }}
            />
            <div
              style={{ display: 'flex', flexDirection: 'column', margin: 7 }}
            >
              <span>
                {item.categories.slice(0, 2).map((category) => (
                  <Chip
                    key={category.id}
                    label={category.title?.['EN'] || category.title?.['LU']}
                    sx={{ m: 0.5 }}
                  />
                ))}
                {item.categories.length > 2 && (
                  <Chip label={`+${item.categories.length - 2}`} />
                )}
              </span>
              <span style={{ margin: 5 }}>
                {((item?.title?.EN || item?.title?.LU) && ((item?.title?.EN || item?.title?.LU)?.length > 25))
                  ? `${(item?.title?.EN || item?.title?.LU)?.substring(0, 25)}...`
                  : (item?.title?.EN || item?.title?.LU)}
              </span>{' '}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ArticleRecommendation;
