import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";

import {
  DELETE_ATTACHMENT,
  RETRIEVE_PRODUCT_FAILURE,
  RETRIEVE_PRODUCT_SUCCESS,
  SET_PRODUCT_ABOUT,
  SET_PRODUCT_APPLICATION,
  SET_PRODUCT_ATTACHMENT,
  SET_PRODUCT_COVER_IMAGE,
  SET_PRODUCT_DOSAGE,
  SET_PRODUCT_HTML_IMAGE,
  SET_PRODUCT_LANGUAGE,
  SET_PRODUCT_TITLE,
  TOGGLE_PRODUCT_SPECIES,
} from "./type";

export const setTitle = (title) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_TITLE,
    payload: title,
  });
};

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_LANGUAGE,
    payload: lang,
  });
};

export const setCoverImage = (image) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_COVER_IMAGE,
    payload: image,
  });
};

export const setAbout = (about) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_ABOUT,
    payload: about,
  });
};

export const setApplication = (application) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_APPLICATION,
    payload: application,
  });
};
export const setDosage = (dosage) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_DOSAGE,
    payload: dosage,
  });
};

export const setSpecies = (selected) => async (dispatch) => {
  dispatch({
    type: TOGGLE_PRODUCT_SPECIES,
    payload: selected,
  });
};

export const retrieveProductbyId = (id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.get(`/product-management/${id}`));
    dispatch({
      type: RETRIEVE_PRODUCT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: RETRIEVE_PRODUCT_FAILURE, payload: err.message });
  }
};

export const clearCurrentProduct = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_PRODUCT_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: RETRIEVE_PRODUCT_FAILURE, payload: err.message });
  }
};

export const setAttachment = (filename, file) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_ATTACHMENT,
    payload: {
      filename: filename,
      name: file.file.name,
      size: file.file.size,
      type: file.file.type,
    },
  });
};
export const deleteAttachment = (index) => async (dispatch) => {
  dispatch({
    type: DELETE_ATTACHMENT,
    payload: index,
  });
};

export const setHtmlImage = (image) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_HTML_IMAGE,
    payload: image,
  });
};
