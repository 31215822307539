import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { Stack } from '@mui/system';
import SelectionLimitDialog from '../../common/SelectionLimitDialog';
import { WithHeader } from '../../common/WithHeader';
import { getFilteredSpecies } from '../../../store/reducers/ProductSelector';
import { createSpecies, getSpecies } from '../../../store/actions/species';
import { setSpecies } from '../../../store/actions/currentProduct';

const Species = () => {
  const dispatch = useDispatch();
  const [titleValue, setTitleValue] = useState('');
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState([]);
  const filteredSpecies = useSelector(getFilteredSpecies);
  const languageSelector = useSelector(
    (state) => state.currentProduct.language
  );
  const selectedSpecies = useSelector(
    (state) => state.currentProduct.speciesIds
  );
  let selectedCategoriesId = selectedSpecies.flatMap((c) => c.id);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAddClick = () => {
    const existingCategory = filteredSpecies.find(
      (c) =>
        c.title[languageSelector].toLowerCase() === titleValue.toLowerCase()
    );

    if (existingCategory) {
      // Category already exists, do something
      setError(true);
      return;
    } else {
      // Category doesn't exist, create a new one
      dispatch(createSpecies(titleValue, languageSelector))
        .then(() => {
          dispatch(getSpecies());
        })
        .then(() => {
          setTitleValue('');
        });
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleToggleCategory = (id, categoryTitle) => () => {
    const currentIndex = selected.indexOf(categoryTitle);
    const selectedCount = selectedSpecies.length;
    const isCategorySelected = selectedCategoriesId.includes(id);

    if (selectedCount === 5 && !isCategorySelected) {
      setDialogOpen(true);
      return;
    }

    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(categoryTitle);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    dispatch(setSpecies(id));
  };

  const getCategoryRow = (item) => {
    const categoryTitle = item.title[languageSelector];
    return (
      <Stack
        key={item.id}
        alignItems="center"
        direction="row"
        justifyContent="flex-start"
        marginLeft={2}
        sx={{ height: '3em' }}
      >
        <FormControlLabel
          value={item.id}
          control={
            <Checkbox
              checked={selectedCategoriesId.includes(item.id)}
              onChange={handleToggleCategory(item.id, item.title)}
            />
          }
        />
        <CardHeader key={item.id} title={categoryTitle} sx={{ ml: -3 }} />
      </Stack>
    );
  };

  return (
    <>
      {filteredSpecies.length > 0 &&
        filteredSpecies.map((item) => getCategoryRow(item))}
      <TextField
        fullWidth
        type="text"
        value={titleValue}
        onChange={(e) => {
          setTitleValue(e.target.value);
          setError(false);
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 0, // set borderRadius to 0
          },
        }}
        placeholder="Start typing to create a new specie"
        InputProps={{
          endAdornment: titleValue && (
            <Button
              sx={{ marginRight: '-12px' }}
              variant="contained"
              onClick={handleAddClick}
            >
              Add Category
            </Button>
          ),
        }}
        error={error}
        helperText={error && 'Category already exists!'}
      />
      <SelectionLimitDialog
        data="Categories"
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
    </>
  );
};
export default WithHeader(Species, 'Species *');
