import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setCoverImage,
  setHtmlImage,
} from "../../../../store/actions/currentArticle";
import CategorySelector from "../../../common/CategorySelector";
import Content from "../../../common/Content";
import CoverImage from "../../../common/CoverImage";
import ReadingTime from "../../../common/ReadingTime";
// import Score from '../../common/Score';
import Header from "../../../common/Header";
import ResourceButton from "../../../common/ResourceButton";
import AuthorDetail from "../../../articles/create-article/AuthorDetail";
import RadioButtonList from "../../../common/RadioButtonList";
import Address from "./Address";
import District from "./District";
import Phone from "./Phone";
import {
  retrieveRescourcesbyId,
  clearCurrentRescource,
  setLearningResource,
  setContent,
} from "../../../../store/actions/currentArticle";
import Recommendation from "../../../common/Recommendation";
import InChargeField from "./inCharge";
import { URL } from "../../../../constants/URL";
import FullName from "./FullName";
import NearByButton from "./NearByButton";
import {
  retrieveNearbyId,
  clearCurrentNearBy,
} from "../../../../store/actions/nearBy";
import { nameSelector } from "../../../../store/reducers/selector";
import { setLanguage } from "../../../../store/actions/nearBy";
import NearByHeader from "../nearbyHeader";

const NewNearBy = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.article.language);
  const [error, setError] = useState(false);

  const selectedCoverImage = useSelector(
    (state) => state.article.coverImage[lang]
  );

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(retrieveNearbyId(URL.NEARBYHELP, id));
    } else {
      dispatch(clearCurrentNearBy());
    }
  }, [dispatch, id]);

  return (
    <div>
      <NearByHeader></NearByHeader>
      {/* <RadioButtonList title={"Role"} list={list} selectedValue={selectedValue} onRadioChange={handleRadioChange}></RadioButtonList> */}
      {/* <CategorySelector /> */}
      <FullName></FullName>
      <Address></Address>
      <District></District>
      <InChargeField></InChargeField>
      <Phone></Phone>
      <NearByButton
        addResourceUrl={URL.NEARBYHELP}
        ListingUrl={URL.NEARBYHELP}
        error={error}
      ></NearByButton>
    </div>
  );
};

export default NewNearBy;
