import { LANGUAGE_KEY } from "../../constants/GeneralConstant";

//Takes values as input and returns localized object//
export function localizedObject(lang, value) {
  const data = { EN: "", LU: "", RY: "" };
  // Conditionally set the title properties based on the lang value

  switch (lang) {
    case LANGUAGE_KEY.ENGLISH:
      return { ...data, EN: value };
    case LANGUAGE_KEY.LUGANDA:
      return { ...data, LU: value };
    case LANGUAGE_KEY.RUNYAKITARA:
      return { ...data, RY: value };
    default:
      return;
  }
}

export function filterTableRows(rows, searchQuery) {
  return rows.filter((item) => {
    const titleKeys = [
      LANGUAGE_KEY.ENGLISH,
      LANGUAGE_KEY.LUGANDA,
      LANGUAGE_KEY.RUNYAKITARA,
    ]; // Add other language keys if needed
    const searchQueryLowerCase = searchQuery?.toLowerCase();

    const matchedTitle = titleKeys.some((key) => {
      const title = item?.title?.[key]
        ? item?.title?.[key]?.toLowerCase()
        : null;
      return title && title.includes(searchQueryLowerCase);
    });

    const matchedCategory = item?.categories?.some((category) =>
      titleKeys.some((key) => {
        const categoryTitle = category?.title?.[key]
          ? category?.title?.[key]?.toLowerCase()
          : null;
        return categoryTitle && categoryTitle.includes(searchQueryLowerCase);
      })
    );

    return matchedTitle || matchedCategory;
  });
}

export function getFormLanguage(data) {
  // Check if all keys have data
  if (
    Object.values(data).every(
      (value) => value !== "" && value !== null && value !== undefined
    )
  ) {
    return LANGUAGE_KEY.ENGLISH; // Return English if all keys have data
  } else {
    // Check which key has data and return its language
    for (let key in data) {
      if (data[key] !== "" && data[key] !== null && data[key] !== undefined) {
        return key;
      }
    }
  }
}
