import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import "../../App.css";
import {
  Button,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import FilterBox from "../Filter/FilterBox";
import HandleGateCheckBox from "../NearBy/NearByList/GateUnGateCheckBox";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import { Tooltip } from "@mui/material";

const ContentHeader = (props) => {
  const navigate = useNavigate();
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);

  const handleFilterBoxOpen = () => {
    setFilterBoxOpen(true);
  };

  const handleFilterBoxClose = () => {
    setFilterBoxOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        lineHeight: "0.01",
        marginBottom: "1em",
        height: "50px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h5">{props.header}</Typography>
      </div>

      <div
        style={{
          display: "flex",
          marginLeft: "auto",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {/* <HandleGateCheckBox></HandleGateCheckBox> */}
        </div>
        <TextField
          placeholder={props.searchPlaceholder}
          className="searchBar"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon color="action" fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: props.searchQuery ? (
              <InputAdornment position="end">
                <IconButton onClick={props.onClearSearchQuery}>
                  <ClearIcon color="action" fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          variant="outlined"
          size="small"
          value={props.searchQuery}
          onChange={props.onSearchQueryChange}
        />
        {!props.isNearBy && (
          <FilterAltOutlinedIcon
            style={{
              cursor: "pointer",
              fontSize: "40px",
              border: "1px solid #a6acbac9",
              padding: "3px",
              marginLeft: "0.2em",
              marginRight: "0.2em",
              borderRadius: "0.1em",
              color: "#rgb(51 51 51 / 73%)",
            }}
            onClick={handleFilterBoxOpen}
          />
        )}
        <FilterBox
          type={props.type}
          open={filterBoxOpen}
          handleClose={handleFilterBoxClose}
        />
        {props.isNearBy && (
          <div style={{ marginLeft: "20px", marginTop: "10px" }}>
            <Tooltip title="Gate All">
              <LockPersonOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => props.HandleGateToggleAll(false)}
              />
            </Tooltip>

            <Tooltip title="Ungate All">
              <LockOpenOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => props.HandleGateToggleAll(true)}
              />
            </Tooltip>
          </div>
        )}
        <div>
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate(props.route)}
            style={{ marginRight: "1em", marginLeft: "1em" }}
          >
            + Add New
          </Button>
          {props.isNearBy && (
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate(props.bulkImportRoute)}
              style={{ marginRight: "1em" }}
            >
              Bulk Import
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentHeader;
