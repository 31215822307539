import {
  CLEAR_FILTER,
  SET_CREATION_END_DATE,
  SET_CREATION_START_DATE,
  SET_FILTERED_PARAMS,
  SET_UPDATE_END_DATE,
  SET_UPDATE_START_DATE,
} from './type';

export const setFilteredQueryParams = (params) => async (dispatch) => {
  dispatch({
    type: SET_FILTERED_PARAMS,
    payload: params,
  });
};

export const setFilteredStartCreationDate = (sdate) => async (dispatch) => {
  dispatch({
    type: SET_CREATION_START_DATE,
    payload: sdate,
  });
};
export const setFilteredEndCreationDate = (edate) => async (dispatch) => {
  dispatch({
    type: SET_CREATION_END_DATE,
    payload: edate,
  });
};

export const setFilteredStartUpdateDate = (sdate) => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_START_DATE,
    payload: sdate,
  });
};
export const setFilteredEndUpdateDate = (edate) => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_END_DATE,
    payload: edate,
  });
};
export const clearFilter = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FILTER,
  });
};
