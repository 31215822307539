import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    // Authorization: sessionStorage.getItem('user')
    //   ? `Bearer ${JSON.parse(sessionStorage.getItem('user')).token}`
    //   : 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGVsYW5jby5jb20iLCJzdWIiOiI2NDA5NjZlOTVjOTE0ZmFjOWI2Y2Y4YjAiLCJpYXQiOjE2NzgzNTIwNjV9.3i1Ee-mZUUif2uxS3vuwJZ2GEPagMTMm6mBz9uUeW7o',
  },
});

instance.interceptors.request.use(
  (config) => {
    const user = sessionStorage.getItem("user");
    if (user) {
      const token = JSON.parse(user).token;
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
