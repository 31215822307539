import React from "react";
import TextFields from "../../common/TextFields";
import { setFullName } from "../../../store/actions/FormActions";
import { useDispatch, useSelector } from "react-redux";
import { localizedFormNameSelector } from "../../../store/reducers/selector";

const FullName = () => {
  const dispatch = useDispatch();
  const name = useSelector(localizedFormNameSelector);

  const HandleFullName = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setFullName(trimmedText));
  };
  return (
    <div className="form-field-col">
      <TextFields
        defaultValue={name}
        placeholder={"Enter Full Name here"}
        name={"form"}
        handleTextField={HandleFullName}
        title={"Full Name *"}
      ></TextFields>
    </div>
  );
};

export default FullName;
