import { BlobServiceClient } from "@azure/storage-blob";
import { AZURE_ACCOUNT_NAME, AZURE_TOKEN_URL } from "../config/environment";

const account = AZURE_ACCOUNT_NAME;
const ImageContainerName = "images";
const VideoContainerName = "videos";
const PDFContainerName = "pdfs";

const sasToken = AZURE_TOKEN_URL;
const getContainerClient = (containerName) => {
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net${sasToken}`
  );
  return blobServiceClient.getContainerClient(containerName);
};

const createContainer = async (containerName) => {
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net${sasToken}`
  );
  const containerClient = blobServiceClient.getContainerClient(containerName);

  try {
    const createContainerResponse = await containerClient.create();
    console.log(
      `Container "${containerName}" created successfully`,
      createContainerResponse
    );
  } catch (error) {
    console.error(`Error creating container "${containerName}"`, error);
  }
};
const generateMultipleFileName = (file: File) => {
  const time = new Date().getTime();
  const extension = file.type ? file.type.split("/")[1] : null;
  const randomNumber = Math.floor(Math.random() * 1000);
  return `${time}-${randomNumber}.${extension}`;
};

const generateFileName = (file: File) => {
  const time = new Date().getTime();
  const extension = file.type ? file.type.split("/")[1] : null;
  return `${time}.${extension}`;
};

const getFileUri = (containerName, filename) => {
  if (filename) {
    return `https://${account}.blob.core.windows.net/${containerName}/${filename}`;
  } else {
    return filename;
  }
};

const uploadFile = async (containerName, filename, file: File, setProgress) => {
  const containerClient = getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(filename);
  return blockBlobClient.uploadData(file, {
    blobHTTPHeaders: {
      blobContentType: file.type,
    },
    onProgress: (ev) => {
      if (setProgress) {
        setProgress(Math.round((ev.loadedBytes / file.size) * 100));
      }
    },
  });
};

const getImageUri = (filename) => {
  return getFileUri(ImageContainerName, filename);
};
const getPdfUri = (filename) => {
  return getFileUri(PDFContainerName, filename);
};
const getVideoUri = (filename) => {
  return getFileUri(VideoContainerName, filename);
};
const uploadImage = (filename, file: File, setProgress) => {
  return uploadFile(ImageContainerName, filename, file, setProgress);
};
const uploadPdf = (filename, file: File, setProgress) => {
  return uploadFile(PDFContainerName, filename, file, setProgress);
};

const uploadVideo = (filename, file: File, setProgress) => {
  return uploadFile(VideoContainerName, filename, file, setProgress);
};

const deleteImage = (filename) => {
  return deleteFile(ImageContainerName, filename);
};

const deleteVideo = (filename) => {
  return deleteFile(VideoContainerName, filename);
};

const deleteFile = async (containerName, filename) => {
  const options = {
    deleteSnapshots: "include",
  };
  const containerClient = getContainerClient(containerName);
  const blobClient = containerClient.getBlobClient(filename);
  return blobClient.delete(options);
};

const BlobService = {
  createContainer,
  deleteImage,
  deleteVideo,
  getContainerClient,
  generateFileName,
  getImageUri,
  getPdfUri,
  getVideoUri,
  uploadImage,
  uploadVideo,
  uploadPdf,
  VideoContainerName,
  generateMultipleFileName,
};

export default BlobService;
