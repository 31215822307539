import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { BeatLoader } from 'react-spinners';
import './Spinner.css';

const Spinner = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div className="overlay">
        <BeatLoader color="#118197" height={100} width={100} />
      </div>
    )
  );
};

export default Spinner;
