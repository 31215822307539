export const validateEvent = (
  event,
  eventTitle,
  eventAddress,
  eventCategory,
  eventAud,
  eventDetail,
  eventStart,
  eventEnd,
  dateError,
  eventLang
) => {
  if (!event.title[event.language]) {
    return "Title can't be blank";
  }
  if (!eventAddress) {
    return "Address can't be blank";
  }
  if (event.categoryIds.length < 1) {
    return "Category is a required field";
  }
  if (!eventAud) {
    return "Audience can't be blank";
  }
  if (!eventLang) {
    return "Select Event Language";
  }
  if (!eventDetail) {
    return "Event Details can't be blank";
  }
  if (!eventStart) {
    return "Select start Date & Time";
  }
  if (!eventEnd) {
    return "Select end Date & Time";
  }
  if (dateError === "Invalid") {
    return "Invalid End Date & Time";
  }
  if (!event.organizerId.id) {
    return "Choose Organizer";
  }
  return null;
};
