import {
  CREATE_ORGANZIOR_SUCCESS,
  GET_ORGANZIOR_REQUEST,
  GET_ORGANZIOR_SUCCESS,
} from '../actions/type';

const initialState = {
  organizorData: [],
  organizorUpdated: false,
};

export const organizorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANZIOR_REQUEST:
      return { ...state };
    case GET_ORGANZIOR_SUCCESS:
      return {
        ...state,
        organizorData: [...action.payload],
        organizorUpdated: false,
      };
    case CREATE_ORGANZIOR_SUCCESS:
      return {
        ...state,
        organizorData: [...state.organizorData, action.payload],
        organizorUpdated: true,
      };
    default:
      return state;
  }
};
