import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

import LazyLoader from "./component/layout/loader/LazyLoader";
import AppRouter from "./router/index";
import { theme } from "./theme";
import Spinner from "./component/layout/Spinner";

function App() {
  const oldURL = process.env.REACT_APP_OLD_URL;
  const newUrl = process.env.REACT_APP_URL;

  // Check for redirect condition on each render
  if (window.location.href.includes(oldURL)) {
    // Redirect to the new URL
    window.location.href = newUrl;
  }

  return (
    <React.Suspense fallback={<LazyLoader />}>
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <ThemeProvider theme={theme}>
        <Router>
          <AppRouter />
        </Router>
        <ToastContainer hideProgressBar={true} />
        <Spinner />
      </ThemeProvider>
    </React.Suspense>
  );
}

export default App;
