import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import usePrompt from "../../hook/usePrompt";
import {
  localizedCoverImageSelector,
  localizedProductTitleSelector,
} from "../../../store/reducers/ProductSelector";
import ProductPreview from "../preview/ProductPreview";
import { toast } from "react-toastify";
import {
  createProduct,
  editProduct,
  getProducts,
} from "../../../store/actions/product";
import { validateProduct } from "../../validations/validateProduct";
const ProductButton = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const product = useSelector((state) => state.currentProduct);
  const productTitle = useSelector(localizedProductTitleSelector);
  const productCoverImage = useSelector(localizedCoverImageSelector);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isPublished, setIsPublished] = useState(true);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };
  const handleSaveResource = async (status) => {
    const validationError = validateProduct(product, productTitle);
    if (validationError) {
      toast.error(validationError);
      return;
    }
    product.status = status;

    //     // function assignENtoLU(obj) {
    //       for (const key in product) {
    //         if (typeof product[key] === 'object' && product[key].hasOwnProperty('EN') && product[key].hasOwnProperty('LU')) {
    //           // console.log("Runs Cond:")
    //           if (product[key].LU === "") {
    //             product[key].LU = product[key].EN;
    //           }
    //           else
    //           {
    //             product[key].EN = product[key].LU;
    //           }
    //         }
    //        if (Array.isArray(product[key].EN) && Array.isArray(product[key].LU)) {
    //         console.log("Condtion Runs:",product[key])
    //         if(product[key].EN.length && !product[key].LU.length)
    //         {
    //         product[key].LU[0] = product[key].EN[0]
    //         }
    //         else
    //         {
    //           product[key].EN[0] = product[key].LU[0]
    //         }
    //       }
    //       // return obj;
    //     }
    //  // }

    //     // const updatedObject = assignENtoLU(product)

    //     console.log("Updated OBject:",product)

    try {
      if (id) {
        await dispatch(editProduct(id, product)).then(() => {
          dispatch(getProducts());
        });
        toast.success(`Product edited successfully!`);
      } else {
        await dispatch(createProduct(product)).then(() => {
          dispatch(getProducts());
        });
        toast.success(`Product successfully ${status.toLowerCase()}!`);
      }
      navigate(`/products`);
    } catch {
      toast.error(`Failed to ${id ? "edit" : "create"} Product!`);
    }
  };

  const handleCreateArticle = () => {
    setIsPublished(false);
    handleSaveResource("Draft");
  };

  const handlePublishArticle = () => {
    setIsPublished(false);
    handleSaveResource("Published");
  };

  const isBlocking = () => {
    return (productTitle || productCoverImage) && isPublished;
  };

  usePrompt("Are you sure you want to leave?", isBlocking());

  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
    >
      <Button
        size="small"
        variant="contained"
        onClick={handlePreviewOpen}
        style={{ margin: 10 }}
      >
        Preview
      </Button>
      <ProductPreview open={previewOpen} handleClose={handlePreviewClose} />
      <Button
        size="small"
        variant="contained"
        onClick={handleCreateArticle}
        style={{ margin: 10 }}
      >
        Draft
      </Button>
      <Button
        size="small"
        variant="contained"
        onClick={handlePublishArticle}
        style={{ margin: 10 }}
      >
        Publish
      </Button>
    </div>
  );
};

export default ProductButton;
