import {
  SET_EMAIL_ADDRESS,
  SET_FULLNAME,
  SET_REASON,
  FORM_SUBMIT_SUCCESS,
  FORM_SUBMIT_FAILED,
} from "../actions/type";

const initialState = {
  language: "EN",
  emailAddress: "",
  fullName: "",
  reason: "",
  errorMessage: "",
};

export const FormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL_ADDRESS:
      return {
        ...state,
        emailAddress: action.payload,
      };
    case SET_FULLNAME:
      return {
        ...state,
        fullName: action.payload,
      };
    case SET_REASON:
      return {
        ...state,
        reason: action.payload,
      };
    case FORM_SUBMIT_SUCCESS:
      return { ...initialState };
    case FORM_SUBMIT_FAILED:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};
