import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';

const FilteredStatus = ({
  values,
  activation,
  handleChipClick,
  filteredData,
  handleActivationChipClick,
}) => {
  const selectedStatus = filteredData.status || [];
  const selectedActivation = filteredData.isGated || [];
  const resourceType = useSelector((state) => state.resourceType);

  return (
    <div style={{ margin: '1em' }}>
      <Typography variant="h6">Status</Typography>
      <div style={{ marginTop: '0.5em' }}>
        {values.map((value) => (
          <Chip
            key={value}
            label={value.charAt(0).toUpperCase() + value.slice(1)}
            clickable
            color={selectedStatus.includes(value) ? 'primary' : 'default'}
            onClick={() => handleChipClick(value)}
            style={{
              marginRight: '10px',
              border: '1px solid #808080',
              // display: 'flex',
              alignItems: 'center',
            }}
          />
        ))}
        {!['Tick', 'Events', 'Products'].includes(resourceType.type) &&
          activation.map((value) => (
            <Chip
              key={value}
              label={value.charAt(0).toUpperCase() + value.slice(1)}
              clickable
              color={selectedActivation.includes(value) ? 'primary' : 'default'}
              onClick={() => handleActivationChipClick(value)}
              style={{
                marginRight: '10px',
                border: '1px solid #808080',
                position: 'relative',
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default FilteredStatus;
