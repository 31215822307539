import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EmailAddress from "./FormFields/EmailAddress";
import FullName from "./FormFields/FullName";
import Reason from "./FormFields/Reason";
import { URL } from "../../constants/URL";
import SubmitButton from "./Submitbutton";
import "./form.css";

const HelpingPageForm = () => {
  const dispatch = useDispatch();

  return (
    <div class="form-container">
      <div class="form-fields">
        <FullName></FullName>
        <EmailAddress></EmailAddress>
        <Reason></Reason>
      </div>
      <div className="button-container">
        <SubmitButton error={""}></SubmitButton>
      </div>
    </div>
  );
};

export default HelpingPageForm;
