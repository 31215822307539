import {
  CREATE_POLICY_FAIL,
  CREATE_POLICY_SUCCESS,
  GET_POLICY_FAIL,
  GET_POLICY_SUCCESS,
  RESET_CONTENT_AND_LANGUAGE,
  SET_CONTENT_LANGUAGE,
  SET_TINYMCE_CONTENT,
} from "../actions/type";

const initialState = {
  language: "EN",
  policy: { EN: "", LU: "", RY: "" },
  error: null,
  status: "Draft",
};

export const content = (state = initialState, action) => {
  switch (action.type) {
    case SET_TINYMCE_CONTENT:
      return {
        ...state,
        policy: {
          ...state.policy,
          [state.language]: action.payload,
        },
      };
    case SET_CONTENT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case RESET_CONTENT_AND_LANGUAGE:
      return initialState;
    case GET_POLICY_SUCCESS:
      return {
        ...state,
        policy: action.payload.policy,
        status: action.payload.status,
      };
    case GET_POLICY_FAIL:
      return { ...state, error: action.payload };
    case CREATE_POLICY_SUCCESS:
      return {
        ...state,
        policy: action.payload.policy,
        status: action.payload.status,
      };
    case CREATE_POLICY_FAIL:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
