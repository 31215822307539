import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import { URL } from "../../constants/URL";
import {
  DELETE_ACCOUNT_REQUEST_SUCCESS,
  DELETE_ACCOUNT_REQUEST_FAILED,
} from "./type";

export const deleteAccountRequest = (modifiedURL) => {
  return async (dispatch) => {
    try {
      const res = await trackPromise(api.post(modifiedURL));
      dispatch({
        type: DELETE_ACCOUNT_REQUEST_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: DELETE_ACCOUNT_REQUEST_FAILED,
        payload: err.response,
      });
      return Promise.reject();
    }
  };
};
