import React from 'react';
import BlobService from '../../services/blob-service';

const CoverImage = ({ coverImage }) => {
  const imageUri =
    coverImage && BlobService.getImageUri(coverImage);

  return (
    <>
      {imageUri ? (
        <img
          src={imageUri}
          style={{ width: '100%', height: '280px', objectFit: 'cover' }}
          alt="Selected file"
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: '280px',
            backgroundColor: '#e0e0e0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
            No Image Available
          </span>
        </div>
      )}
    </>
  );
};

export default CoverImage;
