import {
  CREATE_PRODUCT_SPECIES_FAILURE,
  CREATE_PRODUCT_SPECIES_SUCCESS,
  GET_PRODUCT_SPECIES_FAILURE,
  GET_PRODUCT_SPECIES_SUCCESS,
} from '../actions/type';

const initialState = {
  species: [],
  loading: false,
  error: null,
};

export const speciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_SPECIES_SUCCESS:
      return { ...state, species: action.payload, loading: false };
    case GET_PRODUCT_SPECIES_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case CREATE_PRODUCT_SPECIES_SUCCESS:
      const { id, title } = action.payload;
      const newSpecie = {
        id,
        title,
      };
      return {
        ...state,
        species: [...state.species, newSpecie],
        loading: false,
      };
    case CREATE_PRODUCT_SPECIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
