import { trackPromise } from "react-promise-tracker";
import {
  RETRIEVE_EVENT_FAILURE,
  RETRIEVE_EVENT_SUCCESS,
  SET_ADDRESS,
  SET_EVENT_COVER_IMAGE,
  SET_EVENT_DETAIL,
  SET_EVENT_DETAIL_LANGUAGE,
  SET_EVENT_END_DATE,
  SET_EVENT_LANGUAGE,
  SET_EVENT_START_DATE,
  SET_EVENT_TITLE,
  SET_ORGANIZOR,
  SET_TARGET_AUDIENCE,
  TOGGLE_EVENT_CATEGORY,
} from "./type";
import api from "../../services/api";

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_LANGUAGE,
    payload: lang,
  });
};

export const setCategories = (selected) => async (dispatch) => {
  dispatch({
    type: TOGGLE_EVENT_CATEGORY,
    payload: selected,
  });
};

export const setTitle = (title) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_TITLE,
    payload: title,
  });
};
export const setAddress = (address) => async (dispatch) => {
  dispatch({
    type: SET_ADDRESS,
    payload: address,
  });
};

export const setEventDetail = (details) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_DETAIL,
    payload: details,
  });
};

export const setTargetAudience = (audience) => async (dispatch) => {
  dispatch({
    type: SET_TARGET_AUDIENCE,
    payload: audience,
  });
};

export const setCoverImage = (image) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_COVER_IMAGE,
    payload: image,
  });
};
export const setEventStartDate = (date) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_START_DATE,
    payload: date,
  });
};
export const setEventEndDate = (date) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_END_DATE,
    payload: date,
  });
};

export const setOrganizorId = (organizor) => async (dispatch) => {
  dispatch({
    type: SET_ORGANIZOR,
    payload: organizor,
  });
};

export const retrieveEventbyId = (id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.get(`/event-management/${id}`));
    dispatch({
      type: RETRIEVE_EVENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: RETRIEVE_EVENT_FAILURE, payload: err.message });
  }
};

export const clearCurrentEvent = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_EVENT_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: RETRIEVE_EVENT_FAILURE, payload: err.message });
  }
};
export const setEventLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_DETAIL_LANGUAGE,
    payload: lang,
  });
};
