/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { toggleTickActivation } from "../../../store/actions/learningResource";
import styled from "@emotion/styled";
import { formatDate } from "../../common/DateFormatter";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

const CustomDataGrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

const ArticleListTable = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { learningResource } = useSelector((state) => state.learningResource);
  const articles = learningResource.filter(
    (article) => article.learningResourceType === "Tick"
  );

  const columns = [
    { field: "bodyPart", headerName: "Predilection", width: 400 },
    { field: "status", headerName: "Status", flex: 0.6 },
    {
      field: "updatedat",
      headerName: "Last Updated",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.row.bodyPart === "Full Body") {
          return (
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/tick/${params.id}/edit`)}
              />
            </Tooltip>
          ); // return only the "Edit" button
        } else {
          params.sortDirection && (
            <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
          );

          const HandleActivation = (params) => {
            const res = articles.find((obj) => {
              return obj.id === params.id;
            });
            dispatch(toggleTickActivation(params.id, res))
              .then(() => {
                if (res.status === "Published") {
                  toast.success("Successfully deactivated!");
                } else {
                  toast.success("Successfully activated!");
                }
              })
              .catch(() => {
                toast.error("Failed to gate/ungate resource!");
              });
          };
          const article = articles.find((obj) => obj.id === params.id);

          let activation;
          if (article && article.status === "Published") {
            activation = (
              <Tooltip title="deactivate">
                <VisibilityOutlinedIcon
                  className="action-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => HandleActivation(params)}
                />
              </Tooltip>
            );
          } else {
            activation = (
              <Tooltip title="activate">
                <VisibilityOffOutlinedIcon
                  className="action-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => HandleActivation(params)}
                />
              </Tooltip>
            );
          }

          return (
            <>
              <Tooltip title="Edit">
                <FileOpenOutlinedIcon
                  className="action-icon"
                  style={{ cursor: "pointer", marginRight: "3px" }}
                  onClick={() => navigate(`/tick/${params.id}/edit`)}
                />
              </Tooltip>
              {activation}
            </>
          );
        }
      },
    },
  ];
  function CustomNoRowsOverlay() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div>
          <p>No results found!</p>
          <p>Try changing the search criteria and try again…</p>
        </div>
      </div>
    );
  }

  const rows = articles.map((item) => ({
    id: item.id,
    bodyPart: item.vBodyPart.EN || item.vBodyPart.LU || item.vBodyPart.RY,
    status: item.status,
    updatedat:
      formatDate(item.updatedAt).date +
      " | " +
      formatDate(item.updatedAt).time +
      " by " +
      (item.updatedBy && item.updatedBy.fullname
        ? item.updatedBy.fullname
        : "Admin"),
  }));

  const rows2 = data.map((item) => ({
    id: item.id,
    bodyPart: item.vBodyPart.EN || item.vBodyPart.LU || item.vBodyPart.RY,
    status: item.status,
    updatedat:
      formatDate(item.updatedAt).date +
      " | " +
      formatDate(item.updatedAt).time +
      " by " +
      (item.updatedBy && item.updatedBy.fullname
        ? item.updatedBy.fullname
        : "Admin"),
  }));

  return (
    <>
      <div style={{ height: data.length < 5 ? 600 : 1200, width: "100%" }}>
        {data || data?.length > 1 ? (
          <CustomDataGrid
            rows={rows2}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        ) : (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default ArticleListTable;
