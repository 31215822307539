import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AddPolicy,
  getPolicy,
  resetContentAndLanguage,
  setContent,
  setLanguage,
} from "../../store/actions/policy";
import { useDispatch, useSelector } from "react-redux";
import { localizedContentSelector } from "../../store/reducers/content-selector";
import Content from "../common/Content";
import ContentPreview from "../preview/ContentPreview";
import { LEGALSTYPE, STATUS } from "../../constants/GeneralConstant";
import { toast } from "react-toastify";

const DataPrivacy = () => {
  const [lang, setLang] = useState("EN");
  const dispatch = useDispatch();
  const localizedContent = useSelector(localizedContentSelector);
  const [previewOpen, setPreviewOpen] = useState(false);
  const content = useSelector((state) => state.content);

  const handleLanguage = (event) => {
    const language = event.target.value;
    setLang(language);
    dispatch(setLanguage(language));
  };

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleContent = (status) => {
    const data = {
      policy: content?.policy,
      status,
      legalsType: LEGALSTYPE.PRIVACYPOLICY,
    };

    dispatch(AddPolicy(data))
      .then(() => {
        toast.success("Updated successfully");
      })
      .catch((error) => {
        toast.error("Failed to update data.");
      });
  };

  const handleDraftContent = () => {
    handleContent(STATUS.DRAFT);
  };

  const handlePublishContent = () => {
    handleContent(STATUS.PUBLISHED);
  };

  useEffect(() => {
    dispatch(resetContentAndLanguage());
    dispatch(getPolicy(LEGALSTYPE.PRIVACYPOLICY));
  }, [dispatch]);

  return (
    <>
      <div
        style={{
          display: "flex",
          lineHeight: "0.01",
          marginBottom: "1em",
          height: "50px",
          justifyContent: "space-between", // To move the elements to either end
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{"Privacy Policy"}</Typography>
        <FormControl
          size="small"
          className="selector"
          sx={{ minWidth: "10rem", marginLeft: 4 }}
        >
          <Select
            onChange={handleLanguage}
            value={lang}
            displayEmpty
            name="language"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"EN"}>English</MenuItem>
            <MenuItem value={"LU"}>Luganda</MenuItem>
            <MenuItem value={"RY"}>Runyakitara</MenuItem>
          </Select>
        </FormControl>
        <span
          style={{
            marginLeft: "auto", // Pushes the status text to the right end
            padding: "15px 20px", // Add padding around the text
            background: "#118197", // Background color
            color: "white", // Text color
            border: "1px solid #2980b9", // Border style
            borderRadius: "4px", // Rounded corners
          }}
        >
          Status: {content?.status}
        </span>
      </div>
      <Content localizedContent={localizedContent} setContent={setContent} />
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={handlePreviewOpen}
          style={{ margin: 10 }}
        >
          Preview
        </Button>
        <ContentPreview open={previewOpen} handleClose={handlePreviewClose} />
        <Button
          size="small"
          variant="contained"
          onClick={handleDraftContent}
          style={{ margin: 10 }}
        >
          Draft
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handlePublishContent}
          style={{ margin: 10 }}
        >
          Publish
        </Button>
      </div>
    </>
  );
};

export default DataPrivacy;
