import React from 'react';
import { setApplication } from '../../../store/actions/currentProduct';
import { localizedProductApplicationSelector } from '../../../store/reducers/ProductSelector';
import { WithHeader } from '../../common/WithHeader';
import Content from '../../common/Content';
import { useSelector } from 'react-redux';

const Application = ({ setHtmlImage }) => {
  const productApp = useSelector(localizedProductApplicationSelector);
  return (
    <Content
      localizedContent={productApp}
      setContent={setApplication}
      setHtmlImage={setHtmlImage}
    />
  );
};

export default WithHeader(Application, 'Application');
