import React from "react";
import { useSelector } from "react-redux";
import { setAbout } from "../../../store/actions/currentProduct";
import Content from "../../common/Content";
import { localizedProductAboutSelector } from "../../../store/reducers/ProductSelector";
import { WithHeader } from "../../common/WithHeader";

const About = ({ setHtmlImage }) => {
  const productAbout = useSelector(localizedProductAboutSelector);
  return (
    <Content
      localizedContent={productAbout}
      setContent={setAbout}
      setHtmlImage={setHtmlImage}
    />
  );
};

export default WithHeader(About, "About");
