import React from 'react';

const EventPreviewCoverImage = ({ coverImage }) => {
  return (
    <div>
      {coverImage ? (
        <img
          src={coverImage}
          style={{ width: '100%', height: '230px', objectFit: 'cover' }}
          alt="Selected file"
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: '260px',
            backgroundColor: '#e0e0e0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
            No Image Available
          </span>
        </div>
      )}
    </div>
  );
};

export default EventPreviewCoverImage;
