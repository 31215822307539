import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import LazyLoader from "../component/layout/loader/LazyLoader";
import Dashboard from "../component/dashboard/dashboard";

import AppRoutes from "../config/routes";

function AppRouter() {
  const { isLoggedIn } = useSelector((state) => state.auth);
  console.log("🚀 ~ file: index.js:9 ~ AppRouter ~ isLoggedIn", isLoggedIn);

  console.log("appRouteS:", AppRoutes);
  return (
    <Suspense fallback={<LazyLoader />}>
      <Routes>
        {isLoggedIn
          ? // ONLY AUTHENTICATED ROUTES AVAILABLE IF LOGGED IN
            AppRoutes.filter((route) => route.requireAuthentication).map(
              (route) => (
                <Route path="/" key={1} element={<Dashboard />}>
                  <Route
                    key={route.name}
                    path={route.path}
                    element={route.component}
                  />
                </Route>
              )
            )
          : // ONLY NON-AUTHENTICATED ROUTES AVAILABLE IF LOGGED OUT
            AppRoutes.filter((route) => !route.requireAuthentication).map(
              (route) => (
                <Route
                  key={route.name}
                  path={route.path}
                  element={route.component}
                />
              )
            )}
        <Route
          path="*"
          element={
            !isLoggedIn ? <Navigate to="/login" /> : <Navigate to="/article" />
          }
        />
      </Routes>
    </Suspense>
  );
}

export default AppRouter;
