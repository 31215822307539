import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";

import {
  SET_FULLNAME,
  SET_EMAIL_ADDRESS,
  SET_REASON,
  CLEAR_FORM_FIELDS_SUCCESS,
  CLEAR_FORM_FIELDS_FAILED,
} from "./type";

export const clearFormFields = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_FORM_FIELDS_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: CLEAR_FORM_FIELDS_FAILED, payload: err.message });
  }
};

export const setEmailAddress = (emailAddress) => async (dispatch) => {
  dispatch({
    type: SET_EMAIL_ADDRESS,
    payload: emailAddress,
  });
};

export const setFullName = (fullName) => async (dispatch) => {
  dispatch({
    type: SET_FULLNAME,
    payload: fullName,
  });
};

export const setReason = (reason) => async (dispatch) => {
  dispatch({
    type: SET_REASON,
    payload: reason,
  });
};
