import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BlobService from '../../../services/blob-service';
import '../../../App.css';
import DeleteConfirmationDialog from '../../common/DeleteConfirmationDialog';

const Attachments = ({ attachments, deleteFile }) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const imageUrl = process.env.PUBLIC_URL + '/img/pdf.png';

  const handleDeleteConfirmationOpen = (index, attachment) => {
    setDeleteConfirmationOpen({ index, attachment });
  };

  const bytesToSize = (bytes) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const sizeInKB = bytes / kilobyte;
    const sizeInMB = bytes / megabyte;
    if (sizeInMB >= 1) {
      return sizeInMB.toFixed(2) + ' MB';
    } else {
      return sizeInKB.toFixed(2) + ' KB';
    }
  };

  const getAttachmentRow = (attachment, index) => {
    return (
      <Box
        key={attachment.filename}
        sx={{
          backgroundColor: 'white',
          height: '100%',
          p: 2.5,
          border: 1,
          borderRadius: '0.2em 0.2em 0 0',
          borderColor: 'lightgrey',
        }}
      >
        <Stack
          key={attachment.filename}
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{ m: 1 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{ height: '4em', width: '4em', marginRight: '15px' }}
              src={
                attachment.filename.endsWith('.pdf')
                  ? imageUrl
                  : BlobService.getImageUri(attachment.filename)
              }
              alt="Uploaded file"
            />
            <Stack direction="column" spacing={1}>
              <Typography>{attachment.name}</Typography>
              <Typography>{bytesToSize(attachment.size)}</Typography>
            </Stack>
          </div>
          <Tooltip title="Delete">
            <DeleteOutlineOutlinedIcon
              className="action-icon"
              style={{ cursor: 'pointer', marginRight: '3px' }}
              onClick={() => handleDeleteConfirmationOpen(index, attachment)}
            />
          </Tooltip>
        </Stack>
      </Box>
    );
  };

  return (
    <>
      {attachments &&
        attachments?.map((attachment, index) =>
          getAttachmentRow(attachment, index)
        )}
      <DeleteConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onDelete={() => {
          deleteFile(
            deleteConfirmationOpen.attachment.filename,
            deleteConfirmationOpen.index
          );
        }}
      />
    </>
  );
};

export default Attachments;
