import {
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  EDIT_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  TOGGLE_PRODUCT_ACTIVATION_FAILURE,
  TOGGLE_PRODUCT_ACTIVATION_SUCCESS,
} from '../actions/type';

const initialState = {
  products: [],
  loading: false,
  error: null,
};

function productReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...action.payload],
        loading: false,
      };
    case GET_PRODUCT_FAIL:
      return { ...state, error: action.payload, loading: false };
    case TOGGLE_PRODUCT_ACTIVATION_SUCCESS:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case TOGGLE_PRODUCT_ACTIVATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_PRODUCT_SUCCESS:
      const updatedProduct = state.products.filter(
        (product) => product.id !== action.payload
      );
      return {
        ...state,
        products: updatedProduct,
        loading: false,
        error: null,
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_PRODUCT_SUCCESS:
      return { products: [...state.products, action.payload] };
    case CREATE_PRODUCT_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case EDIT_PRODUCT_SUCCESS:
      return { products: [...state.products, action.payload] };
    case EDIT_PRODUCT_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}

export default productReducer;
