import { trackPromise } from 'react-promise-tracker';
import api from '../../services/api';
import {
  GET_SCORE_CARD_DATA_FAIL,
  GET_SCORE_CARD_DATA_SUCCESS,
  SET_SCORE_CARD_DATA_FAIL,
  SET_SCORE_CARD_DATA_SUCCESS,
} from './type';

export const getScoreData = () => async (dispatch) => {
  try {
    const response = await trackPromise(api.get('/scorecard/list'));
    dispatch({
      type: GET_SCORE_CARD_DATA_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_SCORE_CARD_DATA_FAIL, payload: error.message });
  }
};

export const setScoreData = (updatedScore) => async (dispatch) => {
  try {
    const response = await trackPromise(api.post('/scorecard', updatedScore));
    dispatch({
      type: SET_SCORE_CARD_DATA_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: SET_SCORE_CARD_DATA_FAIL, payload: error.message });
  }
};
