import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getScoreData, setScoreData } from '../../store/actions/scoreCard';
import { toast } from 'react-toastify';

const CustomDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-cell': {
    border: '1px solid grey',
  },
  '& .MuiDataGrid-cell:first-of-type': {
    borderLeft: 'none',
  },
  '& .MuiDataGrid-cell:last-child': {
    borderRight: 'none',
  },
  '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function ScoreCard() {
  const dispatch = useDispatch();
  const { score } = useSelector((state) => state.score);

  useEffect(() => {
    dispatch(getScoreData());
  }, [dispatch]);

  const [rows, setRows] = useState(null);

  useEffect(() => {
    if (score) {
      const updatedRows = [
        {
          id: 1,
          pointsType: 'Articles',
          type: 'Article',
          points: getScoreByType('Article', 20),
          action: 25,
          error: '',
        },
        {
          id: 2,
          pointsType: 'Videos',
          type: 'Video',
          points: getScoreByType('Video', 30),
          action: 32,
          error: '',
        },
        {
          id: 3,
          pointsType: 'Trainings',
          type: 'Training',
          points: getScoreByType('Training', 40),
          action: 45,
          error: '',
        },
        {
          id: 4,
          pointsType: 'Referrals',
          type: 'Referal',
          points: getScoreByType('Referal', 50),
          action: 45,
          error: '',
        },
      ];

      setRows(updatedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score]);

  function getScoreByType(pointsType, val) {
    const scoreData = score.find((item) => {
      return item.pointsType === pointsType;
    });
    return scoreData ? scoreData.points : val;
  }

  const handleInputChange = (event, rowId) => {
    const updatedValue = parseInt(event.target.value);
    let errorMessage = '';
    if (updatedValue < 1 || updatedValue > 999) {
      errorMessage = 'Value must be between 1 and 999.';
    }
    if (!updatedValue) {
      errorMessage = 'Please enter a valid score.';
    }
    const updatedRows = rows.map((row) =>
      row.id === rowId
        ? { ...row, points: updatedValue, error: errorMessage }
        : row
    );
    setRows(updatedRows);
  };

  const handleButtonClick = (rowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row.error) {
      toast.error('Please correct the error before updating the score.');
      return;
    }

    dispatch(setScoreData({ pointsType: row.type, points: row.points }))
      .then(() => {
        toast.success('Updated successfully');
        dispatch(getScoreData());
      })
      .catch((error) => {
        toast.error('Failed to update score data.');
      });
  };

  const columns = [
    { field: 'pointsType', headerName: 'Content Type', width: 300 },
    {
      field: 'points',
      headerName: 'Current Score',
      width: 300,
      renderCell: (params) => (
        <div style={{ position: 'relative', width: '100%', height: '30px' }}>
          <input
            type="number"
            maxLength="3" // Add this attribute to restrict input to 3 digits
            style={{
              width: '100%',
              height: '100%',
              border: params.row.error ? '1px solid red' : 'none',
              outline: 'none',
              background: '#8080802b',
              textAlign: 'center',
            }}
            // Block the input of a full stop character
            onKeyDown={(event) => {
              if (event.key === '.') {
                event.preventDefault();
              }
            }}
            value={params.row.points}
            onChange={(event) => handleInputChange(event, params.row.id)}
          />
          {params.row.error && (
            <p
              style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                width: '100%',
                margin: 0,
                color: 'red',
                fontSize: '10px',
                textAlign: 'center',
              }}
            >
              {params.row.error}
            </p>
          )}
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.sortDirection && (
            <span>{params.sortDirection === 'asc' ? '▲' : '▼'}</span>
          )}
          <Button onClick={() => handleButtonClick(params.row.id)}>
            Update
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      {rows ? (
        <CustomDataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          hideFooter={true}
          className="score-card"
          rowHeight={80} // Set the desired row height here (in pixels)
        />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
