import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import BlobService from '../../../services/blob-service';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PreviewTabs({
  productAbout = '',
  productDosage = '',
  productApplication = '',
  attachments,
}) {
  const [value, setValue] = React.useState(0);
  const imageUrl = process.env.PUBLIC_URL + '/img/pdf.png';

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const contentStyle = {
    maxWidth: '100%',
    padding: '1em',
  };

  const replaceImageTags = (content) => {
    return content.replace(
      /<img/g,
      '<img style="max-width: 100%; height: auto;"'
    );
  };

  const modifiedAbout = replaceImageTags(productAbout);
  const modifiedDosage = replaceImageTags(productDosage);
  const modifiedApp = replaceImageTags(productApplication);

  return (
    <Box sx={{ width: '340px', flex: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="About" {...a11yProps(0)} sx={{ fontSize: '9px' }} />
          <Tab label="Application" {...a11yProps(1)} sx={{ fontSize: '9px' }} />
          <Tab label="Dosage" {...a11yProps(2)} sx={{ fontSize: '9px' }} />
          <Tab label="Attachments" {...a11yProps(3)} sx={{ fontSize: '9px' }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {productAbout ? (
          <div
            style={contentStyle}
            dangerouslySetInnerHTML={{ __html: modifiedAbout }}
          ></div>
        ) : (
          'N/A'
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {productApplication ? (
          <div
            style={contentStyle}
            dangerouslySetInnerHTML={{ __html: modifiedApp }}
          ></div>
        ) : (
          'N/A'
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {productDosage ? (
          <div
            style={contentStyle}
            dangerouslySetInnerHTML={{ __html: modifiedDosage }}
          ></div>
        ) : (
          'N/A'
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {attachments && attachments.length > 0 ? (
          attachments.map((attachment, index) => (
            <Stack
              key={attachment.filename}
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={1}
              sx={{ m: 1 }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {attachment.filename.endsWith('.pdf') ? (
                  <img
                    style={{ height: '4em', width: '4em', marginRight: '15px' }}
                    src={imageUrl}
                    alt="PDF file"
                  />
                ) : (
                  <img
                    style={{ height: '4em', width: '4em', marginRight: '15px' }}
                    src={BlobService.getImageUri(attachment.filename)}
                    alt="Uploaded file"
                  />
                )}
                {/* <Stack direction="column" spacing={1}> */}
                <Typography>{attachment.name}</Typography>
                {/* </Stack> */}
              </div>
            </Stack>
          ))
        ) : (
          <Typography>N/A</Typography>
        )}
      </TabPanel>
    </Box>
  );
}
