import React, { useRef } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addReadingTime } from '../../store/actions/currentArticle';
import { WithHeader } from './WithHeader';
import { readTimeSelector } from '../../store/reducers/selector';

const ReadingTime = () => {
  const readingTime = useSelector(readTimeSelector);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const rtime = e.target.value;
    dispatch(addReadingTime(parseInt(rtime)));
  };
  const handleScroll = (e) => {
    // Focus the input element to capture the scroll event
    inputRef.current.focus();

    // Manually update the input value based on the wheel delta
    if (inputRef.current) {
      const step = 1; // You can adjust this step value as needed
      const newValue =
        parseInt(inputRef.current.value) + (e.deltaY > 0 ? step : -step);
      dispatch(addReadingTime(newValue)); // Update Redux state
    }
  };
  return (
    <TextField
      required
      fullWidth
      type="number"
      id="outlined-basic"
      label=""
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: 0,
        },
      }}
      value={readingTime}
      onChange={handleChange}
      onWheel={handleScroll} // Prevent scrolling behavior
      placeholder="Enter Reading Time here."
      inputRef={inputRef} // Set the ref to the input element
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <span
              style={{
                backgroundColor: 'grey',
                color: 'white',
                height: '2em',
                width: '3em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              min
            </span>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default WithHeader(ReadingTime, 'Reading Time *');
