import { Chip, Icon } from '@mui/material';
import React from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const VideoTags = ({ category, score }) => {
  const maxVisibleTags = 1;
  const remainingCount = category.length - maxVisibleTags;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingRight: '16px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {category.slice(0, maxVisibleTags).map((title) => (
          <Chip
            style={{ color: 'purple' }}
            size="small"
            label={title}
            key={title}
          />
        ))}
        {remainingCount > 0 && (
          <Chip
            size="small"
            style={{ color: 'red' }}
            label={`+${remainingCount}`}
          />
        )}
      </div>
      |
      <Icon component={MonetizationOnIcon} style={{ color: 'yellow' }} />
      <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
        {score ? score : 0} points
      </span>
    </div>
  );
};

export default VideoTags;
